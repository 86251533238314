import axios from './config';

export const alerts = async () => await axios.get('/b/p/alerts', {
  headers: {
    service: "CORE"
  }
});

export const alert = async (id) => await axios.get(`/b/p/alerts?causeId=${id}`, {
  headers: {
    service: "CORE"
  }
});

export const setAlert = async (payload) => {
  return await axios.post('/b/p/alert', payload, {
    headers: {
      service: "CORE"
    }
  });
};
