import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Modal, Container, Form, Button } from 'react-bootstrap';
import { GET_USERS, ASSOCIATE_USER } from "../../sagas/user/types";
import { GET_CLIENTS_SOCIETIES } from "../../sagas/clientSociety/types";
import { USER_TYPES } from '../../config/constants/user';
import { withTranslation } from "react-i18next";

class AddUser extends Component {
  componentWillMount(){
    this.props.getClientsSocieties();
  }
  selectFilter(){
    const representatives = this.props.representatives || [];
    const users = this.props.users || [] ;
    const newUsers = [];
    users.forEach(el => {
      const finded = representatives.find(obj => obj.user_client_id === el.id);
      if (!finded && el.type.trim() === USER_TYPES.CLIENT){
        newUsers.push(el);
      }
    });
    return newUsers;
  }
  handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);
    const obj = Object.fromEntries(data);
    const { client_id, society_id } = this.props;
    if(client_id && society_id){
      const client_society = this.props.clientsSocieties.find(obj =>
        ( obj.client.id === client_id && obj.society !== null && obj.society.id === society_id ));
      obj.client_society_id = client_society.id;
      obj.client_id = client_id;
    }
    else if(client_id){
      obj.client_id = client_id;
    }
    else if(society_id){
      obj.society_id = society_id
    }
    this.props.associateUser(obj);
    this.props.modalClose();
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal.Header closeButton>
          <h5>{`${t("translation:modal>users>addRepresentative")}`}</h5>
        </Modal.Header>
        <Card className="right-content">
          <Container>
            <Row className="pt-2 pb-4">
              <Col xl={12}>
                <form onSubmit={e => this.handleSubmit(e)}>
                  <Form.Group>
                    <Form.Label>{t("translation:form>selectUser")}</Form.Label>
                    <Form.Control
                      as="select"
                      className="text-capitalize"
                      name="user_client_id"
                      required>
                      <option value= "" >{t("translation:form>selectUser")}</option>
                      {this.selectFilter().map((el, i) =>
                        <option key={i} value={el.id}>{`${el.first_name} ${el.last_name}`}</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>add")}</Button>
                </form>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  }
}

const data = state => ({
  users: state.user.users,
  clientsSocieties: state.clientSociety.clientsSocieties,
});


const actions = dispatch => ({
  associateUser: (user) => dispatch({type: ASSOCIATE_USER, payload: user}),
  getUsers: () => dispatch({type: GET_USERS}),
  getClientsSocieties: () => dispatch({type: GET_CLIENTS_SOCIETIES}),
});

export default connect(data, actions)(withTranslation()(AddUser));
