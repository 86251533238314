import {
  FETCH_SOCIETIES,
  FETCH_SOCIETY,
  PUSH_SOCIETY,
} from "./types";

const initial = {
  societies: [],
  society: {},
};

export default (state = initial, action) => {
  const {type, payload} = action;
  switch (type) {
    case FETCH_SOCIETIES:
      return {...state, societies: payload};
    case FETCH_SOCIETY:
      return {...state, society: payload};
    case PUSH_SOCIETY:
      return {...state, societies: [...state.societies, payload ] };
    default:
      return state;
  }
}
