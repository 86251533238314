import React, { Component } from 'react'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {Container, Row, Col, Button, Nav, Modal, ButtonToolbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import AddCause from '../../components/projects/addCause.project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_CLIENTS } from "../../sagas/client/types";
import { USER_TYPES } from '../../config/constants/user';

function NavLink(props) {
  return (
    <Nav.Item as="li">
      <Link {...props}>{props.name}</Link>
    </Nav.Item>
  )
}

class navBar extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalShow: false,
    }
    this.modalClose = this.modalClose.bind(this);
  }
  componentWillMount(){
    this.props.getClients();
  }
  modalClose(){
    this.setState({ modalShow: false });
  }
  showAddAction(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN && !this.state.editState ){
      const { t } = this.props;
      return(
        <ButtonToolbar className="button float-right p-2">
          <Button variant="primary float-right" onClick={() => this.setState({ modalShow: true })}>
            {t("translation:general>add")} {t("translation:general>cause").toLowerCase()} <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
          </Button>
        </ButtonToolbar>
    )}
  }
  render() {
    const { client_name, society_name, name, causes } = this.props.project;
    const owner = society_name || client_name;
    const { params } = this.props.match;
    const informationTypes = this.props.project.informationTypes || [];
    const { t } = this.props;
    return (
      <div className="header text-white d-flex align-items-center">
        <Container>
          <Row className="pt-5">
            <Col className="align-self-center" md={12} lg={3} xl={4}>
              <h3 className="d-inline-block title">
                <span className="d-inline-block font-weight-light">{owner}</span>{` / ${name}`}
              </h3>
            </Col>
            <Col className="align-self-center header__users mt-2" sm={12} md={12} lg={8} xl={8}>
              <Row>
                <Col className="col-6 offset-6">
                {this.showAddAction(this.props.user)}

                <Modal show={this.state.modalShow} onHide={this.modalClose}>
                  <AddCause
                    clients={this.props.clients}
                    projectId={params.id}
                    clientName={client_name}
                    modalClose={this.modalClose}
                    causesAdded={causes}/>
                </Modal>
                </Col>
              </Row>
            </Col>
            <Col className="col-12 mt-3 pb-4">
              <Nav as="ul" className="header__subnav">

                <NavLink className={`nav-link`}
                         to={`/project/${params.id}/causes/`} id={`nav_causes`}
                         name={t("translation:general>causes")} />
              {informationTypes.map(it => {
                return <NavLink className={`nav-link`}
                         to={`/project/${params.id}/${it.key_name}/`} id={`nav_${it.key_name}`}
                         name={t(it.name)} />
              })}
              </Nav>
            </Col>
          </Row>
        </Container>
        </div>
    )
  }
}

const actions = dispatch => ({
  getClients: () => dispatch({type: GET_CLIENTS}),
});


const data = state => ({
  clients: state.client.clients,
  project: state.project.project,
  user: state.user.user,
});

export default connect(data, actions)(withTranslation()(navBar));
