import React, {Component} from 'react';
import {Col, Table, Badge} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DASHBOARD from '../../config/constants/dashboard';
import { withTranslation } from "react-i18next";
import '../../css/app.css';

const { ALERT_TYPES} = DASHBOARD;

function generateTable(content, t) {
  const element = content.map(item => {
    if (item.priority === ALERT_TYPES.HIGH) {
      return (
        <tr>
          <td>
            <p>
              {t(item.name)}
            </p>
            <span>{item.client}</span>
          </td>
          <td>
            <p>
              <Link to={`/cause/${item.cause_id}/stages`}>
                {item.cause_name}
              </Link>
            </p>
            <span>{item.rol}</span>
          </td>
          <td className="align-middle">{`${item.days_left} ${t("translation:general>daysLeft")}`}</td>
          <td className="align-middle">{`${t("translation:general>stage")} ${t(item.stage_name)}`}</td>
          <td className="align-middle">
            <Badge className="danger-custom rounded-0 mb-3">
              {t(item.priority)}
            </Badge>
          </td>
        </tr>
      )
    }
  })
  return element;
}

class alertTableComp extends Component {

  render() {
    const { t } = this.props;
    return (
      <>
        <Col bsPrefix="col-12">
          <div className="alerts card mt-3 table-responsive">
            <Table className="alerts__table" hover>
              <thead>
              <tr>
                <th className="th-custom" scope="col">{t("translation:general>alert")}</th>
                <th className="th-custom" scope="col">{t("translation:general>cause")}</th>
                <th className="th-custom" scope="col">{t("translation:general>time")}</th>
                <th className="th-custom" scope="col">{t("translation:general>stage")}</th>
                <th className="th-custom" scope="col">{t("translation:general>priority")}</th>
              </tr>
              </thead>
              <tbody>
              {generateTable(this.props.content, t)}
              </tbody>
            </Table>
          </div>
        </Col>
      </>
    )
  }
}
const alertTable = withTranslation()(alertTableComp);
export default alertTable;
