import {takeLatest, call, put} from 'redux-saga/effects'
import { CREATE_STAGE_BY_NUMBER } from "./types";
import { FETCH_TITLE } from "../../stores/stage/types";
import { FETCH_ERROR_STATUS } from "../../stores/errors/types";
import { create_stage_by_number } from "../../network/stages";
import { STAGE, STATES } from '../../config/constants/states';
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'
import { GENERAL } from '../../config/constants/resources';

const cookies = new Cookies();

function* createTitle(action) {
  const { payload } = action;
  const { causeId } = payload;
  try {
    const stage = yield call(create_stage_by_number, causeId, STAGE.TITLE);
    yield put({type: FETCH_TITLE, payload: stage.data})
  } catch (e) {
    if (e.response.status === STATES.STATUS.UNAUTHORIZE) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    yield put({ type: FETCH_ERROR_STATUS, payload: { hasError: true, status: e.response.status, resource: GENERAL.DEFAULT } });
  }
}

function* watchCreateTitle() {
  yield takeLatest(CREATE_STAGE_BY_NUMBER, createTitle)
}

export default [
  watchCreateTitle(),
]
