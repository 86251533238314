import React, { Component } from 'react'
import Upload from './upload'
import { withTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup, ButtonToolbar} from "react-bootstrap";
import UploadByUrl from './uploadByUrl'

class toogleUpload extends Component {

  state = {
    uploadMode: 1,
  }

  handleChange(e) {
    this.setState({uploadMode: Number(e.target.value)});
  }

  render() {
    const UploadOption = () => {
      if (this.state.uploadMode === 1) return (
      <Upload
        onChange={this.props.handleUpload}
        handleDropUpload={this.props.handleDropUpload}
        state={this.props.catastroState}
        closeModal={this.props.closeModal}/>)
      else return (
      <UploadByUrl
        onChange={this.props.handleUploadUrl}
        state={this.props.catastroState}
        closeModal={this.props.closeModal}/>)
    }
    const { t } = this.props;
    return (
      <div>
        <div className="d-flex flex-column">
          <ButtonToolbar>
            <ToggleButtonGroup type="radio" name="uploadMode" defaultValue={1}>
              <ToggleButton
                onChange={(e) => this.handleChange(e)}
                variant="light"
                value={1}>
                  {t("translation:modal>documents>uploadLocally")}
              </ToggleButton>
              <ToggleButton
                onChange={(e) => this.handleChange(e)}
                variant="light"
                value={2}>
                  {t("translation:modal>documents>uploadUrl")}
              </ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
        </div>
        <UploadOption/>
      </div>
    )
  }
}

export default (withTranslation()(toogleUpload));
