export const GET_CAUSES = 'sagas/cause/GET_CAUSES';
export const GET_CAUSE = 'sagas/cause/GET_CAUSE';
export const GET_CAUSE_INFO = 'sagas/cause/GET_CAUSE_INFO';
export const GET_CAUSE_FULL_INFO = 'sagas/cause/GET_CAUSE_FULL_INFO';
export const CREATE_CAUSE = 'sagas/cause/CREATE_CAUSE';
export const SET_CAUSE = 'sagas/cause/SET_CAUSE';
export const SET_CAUSE_INFO = 'sagas/cause/SET_CAUSE_INFO';
export const GET_CAUSE_FILES = 'sagas/cause/GET_CAUSE_FILES';
export const GET_CAUSE_NORMAL_FILES = 'sagas/cause/GET_CAUSE_NORMAL_FILES';
export const GET_CAUSE_CATASTRO_FILES = 'sagas/cause/GET_CAUSE_CATASTRO_FILES';
export const GET_CATASTROS = 'sagas/cause/GET_CATASTROS';
export const UPDATE_CATASTROS = 'sagas/cause/UPDATE_CATASTROS';
export const UPDATE_CAUSE = 'sagas/cause/UPDATE_CAUSE';
export const DELETE_CAUSES_FROM_PROJECT = 'sagas/cause/DELETE_CAUSES_FROM_PROJECT';
export const DELETE_CAUSE = 'sagas/cause/DELETE_CAUSES';
