import React, { Component } from 'react'
import { Col, Form } from 'react-bootstrap';
import { SIZE_COLUMN } from '../config/constants/various';
import { withTranslation } from "react-i18next";

class Input extends Component {
  render() {
    const { item, t } = this.props;
    const errors = this.props.errors || [];
    const isValid = errors.find(e => e.id === item.id)
      ? false : true;
    const errorMsj = (!isValid)
      ? errors.find(e => e.id === item.id).message
      : '';
    const sizeColumn = this.props.sizeColumn || SIZE_COLUMN;
    return (
      <Col className="check mb-4" sm={sizeColumn}>
        <Form.Group>
          <Form.Label htmlFor={item.id}>{t(`translation:form>${item.label}`)}</Form.Label>
          <Form.Control
            onChange={this.props.handleChange}
            className="rounded-0"
            {...item}
            placeholder={`${t("translation:form>enter")} ${t(`translation:form>${item.label}`).toLowerCase()}`}
            isInvalid={!isValid}
            required/>
          {(isValid)
            ? (<React.Fragment/>)
            : (<Form.Control.Feedback type="invalid">
                {errorMsj}
              </Form.Control.Feedback>)
          }
        </Form.Group>
      </Col>
    )
  }
}
export default (withTranslation()(Input));
