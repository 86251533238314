import React, { Component } from 'react'
import { Container, Row, Col, Image, Card, Button, Form, Badge } from 'react-bootstrap';
import Input from '../../../components/input'
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import {
  USER_FIRST_NAME,
  EMAIL,
  NEW_PASSWORD,
  OLD_PASSWORD,
  USER_LAST_NAME,
  NEW_PASSWORD_CONFIRMATION,
} from "../../../config/constants/formAttributes"
import { PASSWORD } from "../../../config/constants/error"
import { USER } from "../../../config/constants/resources"
import { UPDATE_PROFILE, UPDATE_PASSWORD } from '../../../sagas/user/types';
import Loading from '../../../components/loading'
import { STATES } from '../../../config/constants/states';
import CustomModal from '../../../components/modal';
import AvatarModal from '../../../components/modal.avatar';
import EMPTY_AVATAR from '../../../assets/images/avatar.svg'

const editInput = [
  USER_FIRST_NAME,
  USER_LAST_NAME,
  EMAIL,
];

function Table(props) {
  return editInput.map(item => {
    const newItem = { ...item, value: props.user[item.name] }
    return (<Input handleChange={props.handleChange} item={newItem} errors={props.errors}/>)
  })
}

class UserProfile extends Component {
  state = {
    userConfig: {
      first_name: '',
      last_name: '',
      email: '',
    },
    password: {
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    errors: [],
    error: {},
    modalShow: false,
  }

  showModal = () => {
    this.setState({ modalShow: true });
  }

  closeModal = () => {
    this.setState({ modalShow: false });
  }

  componentDidMount() {
    this.setState({
      userConfig: {
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        email: this.props.user.email,
      },
    })
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.user) !== JSON.stringify(prevProps.user)) {
      this.setState({
        userConfig: {
          first_name: this.props.user.first_name,
          last_name: this.props.user.last_name,
          email: this.props.user.email,
        },
      })
    }
    if (JSON.stringify(this.props.error) !== JSON.stringify(prevProps.error)) {
      const { hasError, message } = this.props.error;
      if (hasError) {
        if (message.includes(EMAIL.name)) this.setState({ errors:
          [{ id: EMAIL.id, message: EMAIL.errorMessage }]})
        if (message.includes(PASSWORD.OLD_PASSWORD)) this.setState({ errors:
          [{ id: OLD_PASSWORD.id, message: OLD_PASSWORD.errorMessage }]})
      } else this.setState({ errors: []})
    }
  }

  handleConfigChange = (e) => {
    const { userConfig } = this.state;
    const {name, value} = e.target;

    userConfig[name] = value;
    this.setState({
      ...userConfig,
    })
  }

  handlePassChange = (e) => {
    const { password } = this.state;
    const { name, value } = e.target;

    password[name] = value;
    this.setState({
      ...password,
    })
  }

  handleProfileSubmit = (e) => {
    e.preventDefault();
    if (this.state.userConfig.email === this.props.user.email) {
      const { first_name, last_name } = this.state.userConfig;
      this.props.updateProfile({ first_name, last_name });
    } else {
      this.props.updateProfile(this.state.userConfig);
    }
  }

  handlePasswordSubmit = (e) => {
    e.preventDefault();
    const { new_password, old_password, new_password_confirmation } = this.state.password;
    if (new_password !== new_password_confirmation) {
      this.setState({ errors:
        [{
          id: NEW_PASSWORD_CONFIRMATION.id,
          message: NEW_PASSWORD_CONFIRMATION.errorMessage,
        }]});
    } else {
      this.props.updatePassword({ old_password, new_password });
    }
  }

  avatarModal = () => {
    const { t } = this.props;
    return(
        <CustomModal show={this.state.modalShow} onHide={this.closeModal}>
          <AvatarModal
            title={t("modal.documents.title")}
            subtitle={t("modal.documents.subtitle")}
            closeModal={this.closeModal}
            {...this.props}
            file={this.state.currentFile}/>
        </CustomModal>
      );
  }

  render() {
    const { user, error, t } = this.props;
    const { userConfig, errors } = this.state;
    const AvatarUploadModal = this.avatarModal;
    return (
      <>
      <div className="dasheader text-white d-flex align-items-center">
        <Container>
          <Row>
            <Col bsPrefix="col-6">
              <h3><span>{t("translation:general>myConfiguration")}</span></h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mtn-3 mb-5">
        <AvatarUploadModal/>
        <Row>
          <Col className="col-12">
            <Card className="right-content">
              <Container>
                <Form onSubmit={this.handleProfileSubmit}>
                  <Row className="mt-5">
                    <Col className="mb-3" xl={{span:11, offset:1}}>
                      <Image className="ml-2" key={`user-${user.id}`} src={user.avatar || EMPTY_AVATAR} roundedCircle height={60} width={60}/>
                      <Button variant="link" onClick={this.showModal}>{t("translation:configuration>editPersonalInformation>avatar")}</Button>
                    </Col>
                    <Col className="col-12" xl={{span:2, offset:1}}>
                      <div className="title p-2">
                        <h4>{t("translation:configuration>editPersonalInformation>title")}</h4>
                        <p>{t("translation:configuration>editPersonalInformation>subtitle")}</p>
                      </div>
                    </Col>
                    <Col className="col-12" xl={8}>
                      <Row className="mb-4 mt-4">
                        <Table
                          user={userConfig}
                          handleChange={this.handleConfigChange}
                          errors={errors}/>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="submit" className="float-right rounded-0 mb-3">
                        <Loading resource='profile' title={t("translation:general>save")}/>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container className="mb-4">
        <Row>
          <Col className="col-12">
            <Card className="right-content">
              <Container>
                <Form onSubmit={this.handlePasswordSubmit}>
                  <Row className="mt-5">
                    <Col className="col-12" xl={{span:2, offset:1}}>
                      <div className="title p-2">
                        <h4>{t("translation:configuration>changePassword>title")}</h4>
                        <p>{t("translation:configuration>changePassword>subtitle")}</p>
                      </div>
                    </Col>

                    <Col className="col-12" xl={8}>
                      <Row>
                        <Col xl={{ span: 6, offset: 6 }}>
                          {
                            (!error.hasError && error.resource === USER.PASSWORD && error.status === STATES.STATUS.SUCCESS)
                              ? <Badge className="rounded-0 success-custom">{t("translation:configuration>changePassword>successMessage")}</Badge>
                              : <React.Fragment/>
                          }
                        </Col>
                      </Row>
                      <Row className="mb-4 mt-4">
                        <Input
                          handleChange={this.handlePassChange}
                          item={OLD_PASSWORD}
                          errors={errors}/>
                      </Row>
                      <Row>
                        <Input
                          handleChange={this.handlePassChange}
                          item={NEW_PASSWORD}
                          errors={errors}/>
                        <Input
                          handleChange={this.handlePassChange}
                          item={NEW_PASSWORD_CONFIRMATION}
                          errors={errors}/>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="submit" className="float-right rounded-0 mb-3">
                        <Loading resource='password' title={t("translation:general>save")}/>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
      </>
    )
  }
}

const data = state => ({
  user: state.user.user,
  error: state.error,
});

const actions = dispatch => ({
  updateProfile: (user) => dispatch({ type: UPDATE_PROFILE, payload: user }),
  updatePassword: (passwords) => dispatch({type: UPDATE_PASSWORD, payload: passwords}),
});

export default connect(data, actions)(withTranslation()(UserProfile));
