import React, {Component} from 'react'
import {Col, Form} from 'react-bootstrap'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {SET_RESOURCE} from "../../sagas/region/types";
import { SIZE_COLUMN } from '../../config/constants/various';

class selectResource extends Component {

  state = {
    currentResource: '',
    parentResource: '',
    currentValue: '',
  }

  componentDidMount() {
    const resources = this.props.resource_name.split('/');
    this.setState({
      currentResource: (resources.length > 1) ? resources[1] : resources[0],
      parentResource: (resources.length > 1) ? resources[0] : '',
    });
    if (resources.length === 1) {
      const current = this.props.current || {};
      current[resources[0]] = {name: this.props.value};
      this.props.setResource(current)
    }
  }

  generateOptions() {
    const elements = [];

    try {
      if (this.state.parentResource !== '') {
        const parentResource = this.props.current[this.state.parentResource];
        const {id} = this.props[this.state.parentResource].find(r => r.name === parentResource.name)
        this.props[this.state.currentResource].map(r => {
           if(r[`${this.state.parentResource}_id`] === id)
            elements.push(r);
        });
      } else {
        this.props[this.state.currentResource].map(r => elements.push(r))
      }
    } catch (e) {
      console.log(e)
    }
    const element = elements.map(item => {
      return (
        <option
          key={item.id}
          value={item.name}
          >{item.name}</option>
      )
    })
    return element;
  }

  render() {
    const isValid = this.props.errors.find(e => e.id === this.props.id)
      ? false : true;
    const { t } = this.props;
    const sizeColumn = this.props.sizeColumn || SIZE_COLUMN;
    return (
      <Col className="col-12 check mb-4" sm={sizeColumn}>
        <Form.Group>
          <Form.Label htmlFor="select1">{t(this.props.label)}</Form.Label>
          <Form.Control as="select"
                        name={this.props.id}
                        onChange={(e) => {
                          this.props.handleChange(e);
                          const current = this.props.current || {};
                          current[this.state.currentResource] = {name: e.target.value};
                          this.props.setResource(current)
                        }}
                        {...(isValid) ? { isValid: true } : { isInvalid: true }}
                        value={this.props.value}>
            <option disabled={(this.props.value !== "") ? true : false} selected>{t(this.props.placeholder) || `${t("translation:form>enter")} ${t(this.props.label)}`}</option>
            {this.generateOptions()}
          </Form.Control>
          {isValid ? "" : <Form.Control.Feedback type="invalid">{t(this.props.placeholder) || `${t("translation:form>enter")} ${t(this.props.label)}`}</Form.Control.Feedback>}
        </Form.Group>
      </Col>
    )
  }
}

const data = state => ({
  region: state.region.regions,
  province: state.region.provinces,
  commune: state.region.communes,
  court: state.region.courts,
  current: state.region.current
});

const actions = dispatch => ({
  setResource: (item) => dispatch({type: SET_RESOURCE, payload: item}),
})

export default connect(data, actions)(withTranslation()(selectResource))
