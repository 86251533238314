import React, {Component} from 'react'
import { Spinner } from 'react-bootstrap'
import {connect} from "react-redux";
import { STATES } from "../config/constants/states";
import { withTranslation } from "react-i18next";

class Loading extends Component {

  render() {
    const { status, resource } = this.props.error;
    const componentResource = this.props.resource || '';
    if (status === STATES.STATUS.WAITING && componentResource === resource) {
      const { t } = this.props;
      return (<><Spinner animation='border' size='sm' variant='light'/>{t("translation:general>loading")}</>)
    } else {
      return this.props.title;
    }
  }
}



const data = state => ({
  error: state.error,
});

export default connect(data, null)(withTranslation()(Loading));
