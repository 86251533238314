import React, {Component} from 'react'
import {Col, Row, Card, Container, Form, Table, Button} from 'react-bootstrap';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { ATTRIBUTE_TYPE } from '../../config/constants/components';
import { GET_FILES_CAUSE  } from "../../sagas/file/types";
import attributeValueManager from '../../helpers/attributeValueManager';

class InfoTitleTaskCard extends Component {

  state = {
    attributeValues: [],
    errors: [],
  };

  checkTaskValue(props, task){
    const { nextTask, next_stage_id } = task;
    const { value, placeholder} = props;
    const response = placeholder.split(' ');
    const nt = nextTask.find(obj => obj.id === value);
    if(nt && !next_stage_id)
    {
      return ( nt.is_default ? response[0] : response[1]);
    }
    else if (nt && next_stage_id){
      return response[1];
    } else {
      return response[0];
    }
  }

  infoCol(props, errors, task, t) {
    let value = props.value || '-';
    if(props.type === ATTRIBUTE_TYPE.SELECT_TASK && props.value) value = task.nextTask.find(obj => obj.id === value).name;
    else if(props.type === ATTRIBUTE_TYPE.CHECK_TASK && props.value) value = this.checkTaskValue(props,task);
    else value = attributeValueManager(props);
    return (
      <Col key={props.id} className="col-12 mb-2 title" md={6}>
        <span className="mb-2">{t(props.label)}</span>
        <p>{value}</p>
      </Col>
    )
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.attributeValues) !== JSON.stringify(this.props.attributeValues)) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      })
    }
  }

  generateAttributes(attributes, task, errors) {
    const { t } = this.props;
    return attributes.map((item) => {
      return this.infoCol(item,errors, task, t);
    })
  }

  handleClick(file_id) {
    const {id} = this.props.cause;
    this.props.getFile(id, file_id);
  }

  generateTable(files) {
    const { t } = this.props;
    return files.map((item) => {
      return(
        <tr>
          <td className="align-middle text-left">
            <p>{t(item.label)}</p>
            <span>{(item.name === "") ? t("translation:general>notEnteredDocument") : item.name}</span>
          </td>
          <td className="align-middle float-right actions">
            <Button
              variant="link"
              onClick={() => this.handleClick(item.file_id)}
              className="rounded-0 sm">
                {t("translation:general>show")}
            </Button>
          </td>
        </tr>
      )
    })
  }


  render() {
    const { attributeValues, errors} = this.props;
    const { task, t } = this.props;
    return (
      <Col className="col-12">
        <Card className="right-content">
          <Container>
            <Row className="mb-4">
              <Col className="col-12">
                <Form.Row>
                  {this.generateAttributes(attributeValues, task, errors)}
                </Form.Row>
              </Col>

              {(this.props.files.length > 0)
                  ? (
                    <Col className="hr-custom">
                      <Card className="info__customers mt-4">
                        <Table className="mb-3" hover>
                          <thead>
                            <tr>
                              <th className="th-custom" width="80%" scope="col">{t("translation:general>name")}</th>
                              <th className="th-custom" scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.generateTable(this.props.files)}
                          </tbody>
                        </Table>
                      </Card>
                    </Col>
                    )
                  : null
              }
            </Row>
          </Container>
        </Card>
      </Col>
    )
  }
}


const actions = dispatch => ({
  getFile: (cause_id, file_id) => dispatch({type: GET_FILES_CAUSE, payload: {cause_id, file_id}})
});


const data = state => ({
  task: state.task.task,
  files: state.task.taskTitle.hasOwnProperty('files')?
    state.task.taskTitle.files
    : [],
  cause: state.cause.cause,
  causeInfo: state.cause.causeInfo,
  alertByCause: state.alert.alertByCause,
});

export default connect(data, actions)(withTranslation()(InfoTitleTaskCard));
