import { FETCH_TITLE, FETCH_TASKS, ADD_TASK, FETCH_PAYMENT, FETCH_NEGOTIATION, FETCH_CONCESSION } from "./types";

const initial = {
  title: {},
  payment: {},
  tasks: [],
  negotiation: {},
  concession: {},
}

export default (state = initial, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TITLE:
      return {...state, title: payload}
    case FETCH_PAYMENT:
      return {...state, payment: payload}
    case FETCH_NEGOTIATION:
      return {...state, negotiation: payload}
    case FETCH_CONCESSION:
      return {...state, concession: payload}
    case FETCH_TASKS:
      return {...state, tasks: payload}
    case ADD_TASK:
      const { title } = state;
      title.tasks.push(payload);
      return {...state, title}
    default:
      return state;
  }
}
