import {combineReducers} from 'redux'
import user from './user'
import cause from './cause'
import alert from './alert'
import diagram from './diagram'
import file from './file'
import task from './task'
import region from './region'
import client from './client'
import search from './search'
import society from './society'
import clientSociety from './clientSociety'
import project from './project'
import stage from './stage'
import error from './errors'

export default combineReducers({
  alert,
  cause,
  client,
  clientSociety,
  diagram,
  error,
  file,
  project,
  region,
  search,
  society,
  stage,
  task,
  user,
});
