import {takeLatest, call, put} from 'redux-saga/effects'
import {GET_CLIENTS, GET_CLIENT, CREATE_CLIENT} from "./types";
import {FETCH_CLIENTS, FETCH_CLIENT, PUSH_CLIENT} from "../../stores/client/types";
import {clients, client, create_client} from "../../network/clients";
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

function* getClients() {
  try {
    const {data} = yield call(clients);
    yield put({type: FETCH_CLIENTS, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* getClient(action) {
  try {
    const {payload} = action;
    const {data} = yield call(client, payload);
    yield put({type: FETCH_CLIENT, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* createClient(action) {
  const {payload} = action;
  try {
    const {data} = yield call(create_client, payload);
    yield put({type: PUSH_CLIENT, payload: data.data})
  } catch (e) {
    console.log(e);
  }
}


function* watchGetClients() {
  yield takeLatest(GET_CLIENTS, getClients)
}

function* watchGetClient() {
  yield takeLatest(GET_CLIENT, getClient)
}
function* watchCreateClient() {
  yield takeLatest(CREATE_CLIENT, createClient)
}

export default [
  watchGetClients(),
  watchGetClient(),
  watchCreateClient()
]
