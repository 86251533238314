import { ATTRIBUTE_TYPE } from "../../../config/constants/components";
const { SELECT_TASK, CHECK_TASK} = ATTRIBUTE_TYPE;

export default (task) => {
  task.attributeValues.forEach(att => {
    if (att.type === SELECT_TASK || att.type === CHECK_TASK) {
      att.value = '';
      att.state = 0;
    };
  });
  return task;
}
