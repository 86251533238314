import { FETCH_DIAGRAMS, FETCH_PROJECT_DIAGRAMS } from "./types";

const initial = {
  diagrams: [],
  projectDiagrams: [],
};

export default (state = initial, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_DIAGRAMS:
      return {...state, diagrams: payload}
    case FETCH_PROJECT_DIAGRAMS:
      return {...state, projectDiagrams: payload}
    default:
      return state;
  }
}

export const filteredCause = ({diagram}, id) => diagram.diagrams.find(el => el.id === id);
