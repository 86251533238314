function getDate(obj) {
  const day = obj.getDate();
  const month = obj.getMonth() + 1;
  const year = obj.getFullYear();
  return {
    day,
    month,
    year,
  };
}

function parseDate(obj, connector = '/') {
  const day = obj.getDate();
  const month = obj.getMonth() + 1;
  const year = obj.getFullYear();
  if (day) return `${day}${connector}${month}${connector}${year}`;
  else return null;
}

export default { getDate, parseDate };
