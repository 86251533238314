import React, { Component } from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { Nav, Card, Container, Table, TabContent, TabPane, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SmallEmpty from '../../components/smallEmptyCard';

function Alert(props) {
  const { t } = props;
  return(
    <tr>
      <th className={`align-middle ${(props.priority === 'alta') ? 'horizontal error': (props.priority === 'media') ? 'warning' :'success' } text-center`}>
        <FontAwesomeIcon className="fa-lg" icon={['fas', 'engine-warning']} />
      </th>
      <td className="align-middle">
        <p>{t(props.name)}</p>
        <p className="small">{props.date}</p>
      </td>
      <td className="align-middle text-right" width="28%"><span>{`${props.days_left} ${t("translation:general>days")}`}</span></td>
    </tr>
  )
}

function EmptyCard(props) {
  const { t } = props;
  return (
    <SmallEmpty type="small-empty-table" size="3.5" icon="exclamation-triangle">
      <Col xs="7">
        <h5>
          {t("translation:emptyTable>cause>sideBar>alerts>title")}
        </h5>
        <p className="text-muted">{t("translation:emptyTable>cause>sideBar>alerts>subtitle")}</p>
      </Col>
    </SmallEmpty>
  )
}

function SwitchAlertType(props) {
  const { t } = props;
  return props.alerts.map(item => {
    switch (props.id){ // TODO: PARAMETRIZE
      case 'altas':
        if(item.priority === "alta")
          return <Alert key={item.id} t={t} {...item}/>
        break;

      case 'medias':
        if(item.priority === "media")
          return <Alert key={item.id} t={t} {...item}/>
        break;

      case 'bajas':
        if(item.priority === "baja")
          return <Alert key={item.id} t={t} {...item}/>
        break;

      default:
        return <Alert key={item.id} t={t} {...item}/>
      }
    })
}

function ToogleEmptyUsers(props) {
  if (props.alerts.length > 0) {
    return (
      <SwitchAlertType {...props}/>
    )
  }
  return (
    <EmptyCard {...props}/>
  )
}

function AlertPane(props) {
  return(
    <TabPane active={props.active} className={(props.fade) ? 'fade': '' } id={props.id} key={props.id}>
      <Table hover>
        <tbody>
          <ToogleEmptyUsers {...props}/>
        </tbody>
      </Table>
    </TabPane>
  )
}

class SideBar extends Component {

  render() {
    const alerts = this.props.alertByCause;
    const { t } = this.props;
    return (
      <Card.Body bsPrefix="left-content__tabstage">
        <Nav className="headernav bg-light" fill >
          <Nav.Item>
            <Nav.Link disabled className="nav-link text-left ml-2">{t("translation:alerts>stage")}</Nav.Link>
          </Nav.Item>
        </Nav>

        <Container id="rounded-tabs">
          <Nav className="nav-pills rounded-0 flex-column flex-sm-row mt-4">
            <Nav.Link active className="flex-sm-fill text-sm-center br" data-toggle="tab" href="#all">{t("translation:alerts>allAlerts")}</Nav.Link>
            <Nav.Link className="flex-sm-fill text-sm-center br" data-toggle="tab" href="#bajas">{t("translation:alerts>low")}</Nav.Link>
            <Nav.Link className="flex-sm-fill text-sm-center br" data-toggle="tab" href="#medias">{t("translation:alerts>medium")}</Nav.Link>
            <Nav.Link className="flex-sm-fill text-sm-center" data-toggle="tab" href="#altas">{t("translation:alerts>high")}</Nav.Link>
          </Nav>
          <TabContent className="mt-4">
            <AlertPane alerts={ alerts } t={t} active fade={false} id="all"/>
            <AlertPane alerts={ alerts } t={t} active={false} fade id="bajas"/>
            <AlertPane alerts={ alerts } t={t} active={false} fade id="medias"/>
            <AlertPane alerts={ alerts } t={t} active={false} fade id="altas"/>
          </TabContent>
        </Container>
      </Card.Body>
    )
  }
}

const data = state => ({
  task: state.task.task,
  alertByCause: state.alert.alertByCause,
});

export default connect(data, null)(withTranslation()(SideBar));
