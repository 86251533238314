import React from 'react';
import {useDropzone} from 'react-dropzone';

function Basic(props) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({noClick: true});
  
  props.onChange(acceptedFiles);
  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()}/>
          {props.children}
      </div>
    </section>
  );
}

export default Basic;