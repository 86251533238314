import {ATTRIBUTE_TYPE} from "../../../config/constants/components";

export default (task) => {
  const realName = task.name;
  let dynamicName = '';
  task.attributeValues.sort(
    (a, b) => ((a.order_number > b.order_number) ? 1 : -1),
  );
  task.attributeValues.forEach((el, index) => {
    if (el.type === ATTRIBUTE_TYPE.TEXTFILED_TASK_NAME) {
      dynamicName += el.value;
      if (index < task.attributeValues.length && dynamicName) dynamicName +=' ';
    }
  });
  task.name = dynamicName || realName;
  return task;
}
