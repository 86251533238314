import React, {Component} from 'react'
import Input from '../input.causes';
import {Col, Card, Button, Form, Row} from 'react-bootstrap'
import '../../../css/app.css'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {SIDE_BAR} from '../../../config/constants/cause'
import { GET_TASKS_BY_STAGE } from "../../../sagas/tasks/types";
import { CREATE_AND_FILL_NEGOTIATION } from "../../../sagas/negotiation/types";
import { TASK } from '../../../config/constants/states'
import Loading from '../../loading'

function Task(props) {
  if(props.attributeValues) {
    return props.attributeValues.map(attributes => (
      <Input
        handleChange={props.handleChange}
        handleDateChange={props.handleDateChange}
        {...attributes}
        errors={props.errors}/>
    ));
  }
  return (<React.Fragment/>)
}

class createCard extends Component {
  state = {
    attributeValues: [],
    errors: [],
    hasAlert: false,
  };

  componentDidMount() {
    this.props.getTasks(this.props.negotiation.id);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.negotiation.id && !nextProps.createdTask.id) {
      this.props.getTasks(this.props.negotiation.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.attributeValues) !== JSON.stringify(this.state.attributeValues)) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      })
    }
  }

  getStage() {
    var currentStage = this.props.stages.find(stage => {
      return stage.tasks.find(task => {
        return task.id === this.props.task.id;
      });
    });
    return currentStage || { name: SIDE_BAR.STAGE.NOT_SELECTED };
  };

  async validateAttributes() {
    const errors = this.state.attributeValues.filter(a => {
      try {
        return (a.value === "" && (a.type !== "upload"
          && !(a.type === "select" && a.options.length === 0)))
      } catch (e) {
        console.log(e);
      }
    });
    this.setState({ errors });
  }

  handleChange = (e) => {
    const attributeValues = this.state.attributeValues;
    const {name, value} = e.target;
    const idx = attributeValues.findIndex(e => e.id === name);

    attributeValues[idx].value = value;
    this.setState({
      attributeValues,
    })
  }

  handleDateChange = (date, id) => {
    try {
      const attributeValues = this.state.attributeValues;
      const cause = this.props.cause;
      const idx = attributeValues.findIndex(e => e.id === id);

      attributeValues[idx].value = date;
      this.setState({
        attributeValues,
        cause
      })
    } catch (e) {
      console.log(e)
    }
  }

  onClick = async () => {
    await this.validateAttributes();
    if (this.state.errors.length === 0) {
      const task = {
        ...this.props.createdTask,
        attributeValues: this.state.attributeValues,
        state: TASK.COMPLETE,
        hasAlert: this.state.hasAlert,
      };
      this.props.createTask(task);
      this.setState({ attributeValues: this.props.attributeValues });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Card className="right-content mb-5">

        <Card.Body className="left-content__title p-4">
          <Card.Title>
            <Row>
              <Col xs={10} lg={10} xl={10}>
              <h4>{t("translation:task>negotiation>title")}</h4>
              </Col>
              <Col xs={2} lg={2} xl={2}>
                <Button onClick={this.onClick}  className="rounded-0">
                  <Loading title={t("translation:general>save")}/>
                </Button>
              </Col>
            </Row>
          </Card.Title>

          <Form.Row>
            <Col xl={12}>
              <div className="custom-control custom-switch mr-2 mb-3">
                  <input
                    onChange={() => this.setState({ hasAlert: !this.state.hasAlert })}
                    className="custom-control-input"
                    type="checkbox"
                    id="hasAlert"
                    name="hasAlert"/>
                  <label className="custom-control-label" for="hasAlert">{t("translation:task>negotiation>alertActivate")}</label>
              </div>
            </Col>

            <Task
              attributeValues={this.state.attributeValues}
              handleChange={this.handleChange}
              handleDateChange={this.handleDateChange}
              errors={this.state.errors}/>

          </Form.Row>

        </Card.Body>
      </Card>
    )
  }
}

const data = state => ({
  task: state.task.task,
  stages: state.cause.cause.hasOwnProperty('stages')
    ? state.cause.cause.stages
    : [],
  negotiation: state.stage.negotiation,
  createdTask: state.stage.tasks[0] || {},
  attributeValues: (state.stage.tasks[0]) ? state.stage.tasks[0].hasOwnProperty('attributeValues')
  ? state.stage.tasks[0].attributeValues
  : [] : [],
});

const actions = dispatch => ({
  createTask: (createTask) => dispatch({type: CREATE_AND_FILL_NEGOTIATION, payload: {createTask}}),
  getTasks: (stageId) => dispatch({ type: GET_TASKS_BY_STAGE, payload: stageId }),
})

export default connect(data, actions)(withTranslation()(createCard))
