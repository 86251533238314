export const DROPDOWN = {
  NAME: 'Nombre',
  PROFILE: 'Perfil',
  LOGOUT: 'Cerrar Sesión',
  CONFIGURATION: 'Configuración',
};

export const SEARCH_BAR = {
  CLIENT: 'Buscar cliente',
  CAUSE: 'Buscar caso',
  SOCIETY: 'Buscar sociedad',
};

export const NAVIGATION_BAR = {
  TITLE: 'Montt & Cia',
  DASHBOARD: 'Inicio',
  CLIENTS: 'Clientes',
  SOCIETIES: 'Sociedades',
  PROJECTS: 'Proyectos',
  USERS: 'Usuarios',
  CAUSES: 'Casos',
};
