import React, {Component} from 'react';
import {connect} from "react-redux";
import {Col, Row, Form, Button} from "react-bootstrap";
import {filteredCause} from "../../../stores/diagram";
import { withTranslation } from "react-i18next";
import {UPDATE_CAUSE} from "../../../sagas/cause/types";
import {GET_TASKS_BY_STAGE, CREATE_AND_FILL_TASK} from "../../../sagas/tasks/types";
import { GET_CLIENTS, GET_CLIENT } from "../../../sagas/client/types";
import { STATES, TASK } from '../../../config/constants/states';
import Loading from '../../loading';

class CreateCauses extends Component {

  state = {
    filteredCause: [],
    changeClient: false,
    task: {},
  };

  componentWillMount() {
    this.props.getClients();
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleTaskChange(e) {
    const item  = this.props.tasks.find(t => t.id === e.target.value)
    this.setState({task: item});
  }

  handleChangeClient(e) {
    this.handleChange(e);
    this.props.getClient(e.target.value);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.createTask(this.state.task,
      {
        stage_check_id: this.props.title.id,
        task_id: this.state.task.id,
        state: TASK.EMPTY,
      });
    if (this.state.changeClient) {
      const data = new FormData(e.target);
      const { society_client_id } = Object.fromEntries(data)
      this.props.updateCause(this.props.cause.id, { society_client_id });
    }
    if (this.props.status === STATES.STATUS.SUCCESS) {
      this.props.onHide();
    }
  }

  clientAsigment() {
    const { t } = this.props;
    const societies = this.props.client.societies || [];
    return(
      <>
        <Form.Group>
          <Form.Label>{t("translation:task>titles>assignClient")}</Form.Label>
          <Form.Control
            as="select"
            className="text-capitalize"
            name="client"
            required
            onChange={e => this.handleChangeClient(e)}>
            <option disabled selected>{t("translation:form>selectClient")}</option>
            {this.props.clients.map((el, i) =>
              <option key={i} value={el.id}>{el.name}</option>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label> {t("translation:form>selectSociety")}</Form.Label>
          <Form.Control
            as="select"
            className="text-capitalize"
            name="society_client_id"
            onChange={e => this.handleChange(e)}>
            <option disabled selected>{t("translation:form>selectSociety")}</option>
            <option key={0} value={this.props.client.client_society_id}>{t("translation:general>noSociety")}</option>
            {societies.map((el, i) =>
              <option key={i + 1} value={el.client_society_id}>{el.name}</option>
            )}
          </Form.Control>
        </Form.Group>
      </>
    )
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSubmit(e)}>
          <Row>
            <Col className="col-12" xl={5}>
              <div className="title p-2">
                <h4>{t("translation:task>titles>title")}</h4>
                <p>{t("translation:task>titles>subtitle")}</p>
              </div>
            </Col>
            <Col className="col-12 mb-3" xl={7}>

              <Form.Group>
                <Form.Label>{t("translation:task>titles>question")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="task_id"
                  onChange={e => this.handleTaskChange(e)}>
                  <option disabled selected>{t("translation:form>selectTitleType")}</option>
                  {this.props.tasks.map((item, i) =>
                    <option key={i} value={item.id}>{t(item.name)}</option>
                  )}
                </Form.Control>
              </Form.Group>

              <div className="custom-control custom-switch mr-2 mb-3 mt-3">
                  <input
                    onChange={() => this.setState({ changeClient: !this.state.changeClient })}
                    className="custom-control-input"
                    type="checkbox"
                    id="changeClient"
                    name="changeClient"/>
                  <label className="custom-control-label" for="changeClient">{t("translation:form>ChangeHolder")}</label>
              </div>
              {(this.state.changeClient)
                ? this.clientAsigment()
                : null
              }
              <Button variant="primary rounded-0 float-right" type="submit">
                <Loading title={t("translation:general>create")}/>
              </Button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
  }
}


const data = state => ({
  cause: state.cause.cause,
  filterCause: id => filteredCause(state, id),
  clients: state.client.clients,
  client: state.client.client,
  tasks: state.stage.tasks,
  status: state.error.status,
  title: state.stage.title,
});

const actions = dispatch => ({
  createTask: (task, createTask) => dispatch({type: CREATE_AND_FILL_TASK, payload: {task, createTask}}),
  getTasksByStage: (stageId) => dispatch({ type: GET_TASKS_BY_STAGE, payload: stageId }),
  updateCause: (causeId, cause) => dispatch({type: UPDATE_CAUSE, payload: { causeId, cause }}),
  getClients: () => dispatch({type: GET_CLIENTS}),
  getClient: (clientId) => dispatch({type: GET_CLIENT, payload: clientId}),
});

export default connect(data, actions)(withTranslation()(CreateCauses));
