import React, {Component} from 'react'
import {Col, Card, Button, Table} from 'react-bootstrap'
import CustomModal from './modal'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import '../../../css/app.css'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {SIDE_BAR} from '../../../config/constants/cause'
import dateHelper from  '../../../helpers/date.helper'
import { ATTRIBUTE_TYPE } from '../../../config/constants/components'

const LIMIT = 5;
const FIRST_PAGE = 1;

function HistoricTable(props) {
  const { tasks, t } = props;
  if (tasks) {
    return tasks.map(item => {
      const { id } = item;
      if (item.attributeValues) {
        const subject = item.attributeValues.find(a => a.type === ATTRIBUTE_TYPE.TEXTFIELD)
        const date = item.attributeValues.find(a => a.type === ATTRIBUTE_TYPE.ALARM_DATE);
        return (
          <tr>
            <td className="w-100">
              <p className="mb-0">{subject.value}</p>
              <span>{dateHelper.parseDate(new Date(date.value))}</span>
            </td>
            <td className='float-right'>
              <Button
                onClick={() => {
                  props.onShow(item)
                }}
                className="nav-link text-center actions"
                variant="link">
                {t("translation:general>show")}
              </Button>
            </td>
          </tr>
        )
      }
    });
  }
}

class sideBar extends Component {
  state = {
    currentTask: {},
    showModal: false,
    currentPage: 1,
    currentNotifications: [],
  }

  getStage() {
    var currentStage = this.props.stages.find(stage => {
      return stage.tasks.find(task => {
        return task.id === this.props.task.id;
      });
    });
    return currentStage || { name: SIDE_BAR.STAGE.NOT_SELECTED };
  };

  modalClose = () => {
    this.setState({ showModal: false });
  }

  modalShow = (item) => {
    this.setState({ showModal: true, currentTask: item })
  }

  addPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 })
  }

  restPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 })
  }

  render() {
    const totalPages = this.props.negotiations.length ? Math.ceil(this.props.negotiations.length / LIMIT) : 1;
    const offset = LIMIT * (this.state.currentPage - 1);
    const pageTasks = this.props.negotiations
      ? this.props.negotiations.slice(offset, offset + LIMIT)
      : [];
    const { t } = this.props;
    const cronologicalRecords = t("translation:task>negotiation>cronologicalRecords").split("translation: ");
    return (
      <Col xs={12} lg={5} xl={4} className="mb-4">
        <Card className="left-content">
          <CustomModal show={this.state.showModal} handleClose={this.modalClose} task={this.state.currentTask}/>
          <Card.Body className="left-content__title p-4">
            <Card.Title>
              <p className="small mt-4">{cronologicalRecords[0]}</p>
              <h4>{cronologicalRecords[0] || ''}</h4>
            </Card.Title>

            <Card.Subtitle className="mb-2 pt-4">
              <Col className="d-flex align-items-center bd-highlight np">
                <div className="flex-grow-1 bd-highlight">
                  <p className=" text-uppercase c-primary mb-0">{t("translation:general>historical")}
                  </p>
                  <p className="small mb-0 tt-i">
                    {`${t("translation:general>showing")} ${this.state.currentPage} ${t("translation:general>of")} ${totalPages}`}
                  </p>
                </div>

                <div className="bd-highlight float-right">
                  <Button
                    variant="link"
                    onClick={this.addPage}
                    disabled={this.state.currentPage === totalPages}
                    size="sm">
                    <FontAwesomeIcon className="fa-sm"
                      icon='chevron-up'/>
                  </Button>

                  <Button
                    variant="link"
                    onClick={this.restPage}
                    disabled={this.state.currentPage === FIRST_PAGE}
                    size="sm">
                    <FontAwesomeIcon className="fa-sm"
                      icon='chevron-down'/>
                  </Button>
                </div>


              </Col>

            </Card.Subtitle>

          </Card.Body>
          <Card.Body className="left-content__title p-0">
            <Table hover titles={[]} className="info__customers mb-0">
              <tbody>
                <HistoricTable tasks={pageTasks} t={t} onShow={this.modalShow}/>
              </tbody>
              </Table>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}

const data = state => ({
  task: state.task.task,
  stages: state.cause.cause.hasOwnProperty('stages')
    ? state.cause.cause.stages
    : [],
  negotiations: state.stage.negotiation.hasOwnProperty('tasks')
    ? state.stage.negotiation.tasks.reverse()
    : [],
});

const actions = dispatch => ({
})

export default connect(data, actions)(withTranslation()(sideBar))
