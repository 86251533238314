import React, { Component } from 'react'
import { Modal, Card, Container } from 'react-bootstrap'

export default class modal extends Component {

  render() {
    return (
      <Modal {...this.props}
        size="lg"
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton/>
        <Card className="right-content">
          <Container>
            {this.props.children}
          </Container>
        </Card>
      </Modal>
    )
  }
}
