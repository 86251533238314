import React, {Component} from 'react'
import {Col, Card, Row} from 'react-bootstrap'
import '../../../css/app.css'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import CreateCard from './createCard';
import dateHelper from '../../../helpers/date.helper'
import { USER_TYPES } from '../../../config/constants/user'
import { ATTRIBUTE_TYPE } from '../../../config/constants/components'

function NegotationCard(props) {
  if (props.task.attributeValues) {
    const { attributeValues } = props.task;
    if (attributeValues.length === 3) {
      const { t } = props;
      const subject = attributeValues.find(a => a.type === ATTRIBUTE_TYPE.TEXTFIELD);
      const date = attributeValues.find(a => a.type === ATTRIBUTE_TYPE.ALARM_DATE);
      const content = attributeValues.find(a => a.type === ATTRIBUTE_TYPE.TEXTFIELD_AREA);
      return (
        <Card>
          <Card.Header>
            <h5>{t("translation:general>Última_actualización")}</h5>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xl="4">
                <p className="mb-0 font-weight-bold">{t(subject.label)}</p>
                <span className="text-muted">{subject.value}</span>
                <p className="mt-2 mb-0 font-weight-bold">{t(date.label)}</p>
                <span className="text-muted">{dateHelper.parseDate(new Date(date.value))}</span>
              </Col>
              <Col xl="8">
                <p className="mb-0 font-weight-bold">{t(content.label)}</p>
                <span className="font-weight-light">{content.value}</span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )
    }
  }
  return (<React.Fragment/>)
}

class card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastTask: {},
    }
  }
  componentDidMount() {
    try {
      const size = this.props.negotiations.length;
      this.setState({ lastTask: this.props.negotiations[size - 1] || {} })
    } catch(e) {
      console.log(e);
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.negotiations) !== JSON.stringify(this.props.negotiations)) {
      const size = this.props.negotiations.length;
      this.setState({ lastTask: this.props.negotiations[size - 1] })
    }
  }

  render() {
    const type = this.props.user.type || '';
    const { t } = this.props;
    return (
      <Col xs={12} lg={5} xl={8}>
        {(type.includes(USER_TYPES.SUPER_ADMIN) || type.includes(USER_TYPES.ADMIN))
          ? <CreateCard/>
          : <React.Fragment/>}
        <NegotationCard t={t} task={this.state.lastTask}/>
      </Col>
    )
  }
}

const data = state => ({
  task: state.task.task,
  stages: state.cause.cause.hasOwnProperty('stages')
    ? state.cause.cause.stages
    : [],
  negotiations: state.stage.negotiation.hasOwnProperty('tasks')
    ? state.stage.negotiation.tasks
    : [],
  user: state.user.user,
});

export default connect(data, null)(withTranslation()(card))
