import React, {Component} from 'react'
import {Col, Row, Card, Container, Button, Form, Table } from 'react-bootstrap'
import Input from './input.causes';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { USER_TYPES } from '../../config/constants/user';
import UploadModal from '../modal.upload';
import CustomModal from '../modal';
import { BadgeStates } from '../../resolver/cause/surveillance';

class TitleTaskCard extends Component {
  constructor(props) {
    super(props);
    this.showCreateModal = this.showCreateModal.bind(this);
    this.handleUploadClose = this.handleUploadClose.bind(this);
  }

  state = {
    attributeValues: [],
    errors: [],
    show: false,
    uploadModalShow: false,
    currentFile: {},
  };

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.attributeValues) !== JSON.stringify(this.props.attributeValues)) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      })
    }
  }

  generateAttributes(attributes, nextTask, errors) {
    return attributes.map((item) => {
      return (<Input
        handleChange={e => this.handleChange(e)}
        handleDateChange={(date, id) => this.handleDateChange(date, id)}
        {...item}
        nextTask={nextTask}
        errors={errors}/>)
    })
  }

  handleDateChange(date, id) {
    try {
      const attributeValues = this.props.attributeValues;
      const cause = this.props.cause;
      const idx = attributeValues.findIndex(e => e.id === id);

      if (attributeValues[idx].type === 'alarm_date') {
        this.props.setAlert(cause.id, date, id);
      }

      attributeValues[idx].value = date;
      this.setState({
        attributeValues,
        cause
      })
    } catch (e) {
      console.log(e)
    }
  }

  handleChange(e) {
    const attributeValues = this.props.attributeValues;
    const cause = this.props.cause;
    const { name, value } = e.target;
    const idx = attributeValues.findIndex(e => e.id === name);

    attributeValues[idx].value = value;
    this.setState({
      attributeValues,
      cause
    })
  }

  async validateAttributes() {
    const errors = this.props.attributeValues.filter(a => {
      try {
        return (a.value === "" && (a.type !== "upload"
          && !(a.type === "select" && a.options.length === 0)))
      } catch (e) {
        console.log(e);
      }
    });
    this.setState({ errors });
  }

  async handleCompletion(state) {
    const task = {
      ...this.props.task,
      state : state,
      attributeValues: this.props.attributeValues,
    };

    this.props.postTask(
      task,
      this.props.cause,
      this.props.alertByCause,
      this.props.causeInfo,
      );
  }

  handleClose() {
    this.setState({show: false});
  }

  handleShow() {
    this.setState({show: true});
  }

  // ACTION BUTTONS
  generateTable(files) {
    const { t } = this.props;
    return files.map((item) => {
      return(
        <tr>
          <td className="align-middle text-left">
            <p>{(item.name === "") ? t(item.label) : item.name}</p>
            <span>{(item.name === "") ? "" : t(item.label)}</span>
          </td>
          <td className="align-middle text-left">
            <BadgeStates state={item.state}/>
          </td>
          <td className="align-middle float-right actions">
            <Button variant="link" onClick={() => this.handleUploadClick(item)} className="rounded-0 sm">{t("translation:general>load")}</Button>
          </td>
        </tr>
      )
    })
  }

  showCreateModal() {
    const type = this.props.user.type ? this.props.user.type.trim() : '';
    if (type === USER_TYPES.SUPER_ADMIN || type === USER_TYPES.ADMIN) {
      const { t } = this.props;
      return (
        <>
          <CustomModal show={this.state.uploadModalShow} onHide={this.handleUploadClose}>
            <UploadModal
              title={t("translation:modal>documents>title")}
              subtitle={t("translation:modal>documents>subtitle")}
              closeModal={this.handleUploadClose}
              {...this.props}
              file={this.state.currentFile}/>
          </CustomModal>
        </>
      )
    }
    return (<React.Fragment></React.Fragment>)
  }

  handleUploadClose() {
    this.setState({ uploadModalShow: false });
  }

  handleUploadShow() {
    this.setState({ uploadModalShow: true });
  }

  handleUploadClick(props) {
    this.handleUploadShow();
    this.setState({ currentFile: props })
  }

  render() {
    const UploadModal = this.showCreateModal;
    const { t } = this.props;
    return (

        <Card className="right-content">
          <Container>
            <UploadModal />

            <Row className="mb-4">
              <Col className="col-12" >
                <Form.Row className="mt-5">
                  {this.generateAttributes(this.props.attributeValues, this.props.task.nextTask, this.state.errors)}
                </Form.Row>
              </Col>
              {(this.props.files.length > 0)
                  ? (
                    <Col className="hr-custom">
                      <Card className="info__customers mt-4">
                        <Table className="mb-3" hover>
                          <thead>
                            <tr>
                              <th className="th-custom" width="80%" scope="col">{t("translation:general>name")}</th>
                              <th className="th-custom" scope="col">{t("translation:general>state")}</th>
                              <th className="th-custom" scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.generateTable(this.props.task.files)}
                          </tbody>
                        </Table>
                      </Card>
                    </Col>
                    )
                  : null
              }
            </Row>
            <Row className="mb-3 mt-4 line">
              <Col className="col-12">

              </Col>
            </Row>
          </Container>
        </Card>
    )
  }
}

const data = state => ({
  task: state.task.taskTitle,
  user: state.user.user,
});

export default connect(data, null)(withTranslation()(TitleTaskCard))
