import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Button, Row, Col, Image, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FORGOTTEN_PASSWORD } from "../../../../sagas/password/types";
import MonttImage from "../../../../assets/images/logo.svg";
import { STATES } from "../../../../config/constants/states";
import { USER } from "../../../../config/constants/resources";
import LanguageButton from "../../../../components/language.button";
import { withTranslation } from "react-i18next";

class ForgotPassword extends Component {
  state = {
    email: ""
  };

  handleChange = e => {
    const { value } = e.target;
    this.setState({ email: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.sendForgotRequest(this.state.email);
  };

  render() {
    const { t } = this.props;
    const { error } = this.props;
    return (
      <Form className="login__form" onSubmit={this.handleSubmit}>
        <Row className="text-left mb-5">
          <LanguageButton />
        </Row>
        <Row className="text-left mb-3 mt-5">
          <Col>
            <Image className="img-logo" src={MonttImage}/>
          </Col>
        </Row>
        <div className="text-left mt-5 mb-4">
          <h1 className="h3 mb-2font-weight-normal">
            {t("translation:login>recoverPassword")}
          </h1>
          <h5 className="mb-3 font-weight-normal">
          {t("translation:login>cantYouLogIn")}
          </h5>
        </div>
        <Form.Group>
          <Form.Label bsPrefix="label" htmlFor="email">
          {t("translation:login>weWillSendARecoveryLinkTo")}
          </Form.Label>
          <Form.Control
            as="input"
            className="rounded-0"
            id="email"
            type="email"
            name="username"
            placeholder="Introducir correo"
            value={this.state.email}
            onChange={this.handleChange}
            required=""
          />
        </Form.Group>
        <Button
          variant="primary"
          className="rounded-0 text-center"
          block
          type="submit"
        >
          {t("translation:login>sendRecoveryLink")}
        </Button>
        {error.status === STATES.STATUS.SUCCESS &&
        error.resource === USER.PASSWORD ? (
          <Badge className="success-custom rounded-0">{t("translation:login>linkSentSuccessfully")}</Badge>
        ) : error.hasError && error.resource === USER.PASSWORD ? (
          <Badge className="danger-custom rounded-0">
            {t("translation:login>error>emailDoesntExist")}
          </Badge>
        ) : (
          <React.Fragment />
        )}
        <Link className="mt-3" to="/login">
          <FontAwesomeIcon
            className="fa-sm mr-2"
            icon={["fas", "arrow-left"]}
          />
          {t("translation:general>return")}
        </Link>
        <h4 className="mt-5">{t("translation:general>contactInformation")}</h4>
        <h6>info@fast-min.com</h6>
      </Form>
    );
  }
}

const actions = dispatch => ({
  sendForgotRequest: email =>
    dispatch({ type: FORGOTTEN_PASSWORD, payload: { email } })
});

const data = state => ({
  error: state.error
});

export default connect(data, actions)(withTranslation()(ForgotPassword));
