import React, { Component } from 'react'
import { Col, Form, FormCheck } from 'react-bootstrap'
import { SIZE_COLUMN } from '../config/constants/various';
import { withTranslation } from "react-i18next";

class desissionCheck extends Component {
  render() {
    const { t } = this.props;
    const placeholder = this.props.placeholder.split(' ');
    const placeholderTranslated = t(this.props.placeholder).split(' ');
    const isValid = this.props.errors.find(e => e.id === this.props.id)
      ? false : true;
    const sizeColumn = this.props.sizeColumn || SIZE_COLUMN;
    return(
      <Col className="col-12 check mb-4" sm={sizeColumn}>
        <p className="mb-2">{t(this.props.label)}</p>
        <Form.Check>
          <FormCheck.Input
            onChange={(e) => this.props.handleChange(e)}
            id={`${this.props.id}`}
            type="radio"
            name={`${this.props.id}`}
            {...(isValid) ? {} : { isInvalid: true }}
            {...(this.props.value === placeholder[0] ) ? { checked: true} : {}}
            value={placeholder[0]}/>
          <FormCheck.Label htmlFor={`check1-${this.props.id}`}>{placeholderTranslated[0] || this.props.content.shift()}</FormCheck.Label>
        </Form.Check>
        <Form.Check className="ml-3">
          <FormCheck.Input
            onChange={(e) => this.props.handleChange(e)}
            id={`${this.props.id}`}
            type="radio"
            name={`${this.props.id}`}
            {...(isValid) ? {} : { isInvalid: true }}
            {...(this.props.value === placeholder[1] ) ? { checked: true} : {}}
            value={placeholder[1]}/>
          <FormCheck.Label htmlFor={`check2-${this.props.id}`}>{placeholderTranslated[1] || this.props.content.shift()}</FormCheck.Label>
        </Form.Check>
      </Col>
    )
  }
}

export default (withTranslation()(desissionCheck));
