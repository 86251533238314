import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Card, Button, Table } from 'react-bootstrap';
import { GET_FILES_CAUSE } from '../../sagas/file/types';
import { BadgeStates } from '../../resolver/cause/surveillance';
import { withTranslation } from "react-i18next";

class CatastroCard extends Component {

  generateHeader() {
    const { t } = this.props;
    const owner = this.props.catastro.cause.society || this.props.catastro.cause.client;
    return (
      <Card.Header>
        <BadgeStates state={this.props.catastro.state} mb="3"/>
        <Card.Subtitle className="text-muted" mt="2" mb="1">{owner}</Card.Subtitle>
        <Card.Title>
          <h5 className="d-inline-block">
            <Link to={`/cause/${this.props.catastro.cause_id}/stages`}>{this.props.catastro.cause.name}</Link>
          </h5>
          <p className="d-inline-block ml-2">{`· ${this.props.catastro.cause.rol.value || "-"}`}</p>
        </Card.Title>
        <Card.Subtitle>{this.props.catastro.cause.court.value || t(this.props.catastro.cause.description)}</Card.Subtitle>
        <Card.Subtitle className="mt-">{(this.props.catastro.cause.court.value) ? t(this.props.catastro.cause.description) : ''}</Card.Subtitle>
      </Card.Header>
    )
  }

  handleClick() {
    const id = this.props.catastro.cause_id;
    this.props.getFile(id, this.props.catastro.file_id);
  }

  render () {
    const Header = (props) => this.generateHeader(props);
    const { t } = this.props;
    return (
      <Col className="causes mb-4" sm="12" md="4">
        <Card>
          <Header/>
          <Card.Body>
            <Table className="mb-2" hover>
              <thead>
                <tr>
                  <th className="th-custom">{t("translation:surveillance>state")}</th>
                  <th className="th-custom">{t("translation:surveillance>update")}</th>
                </tr>
              </thead>
              <tbody>
                <td style={{ maxWidth:"140px"}}>
                  <p>{t(this.props.catastro.description)}</p>
                </td>
                <td>
                  <p>{`${this.props.catastro.last_update} ${t("translation:general>days")}`}</p>
                </td>
              </tbody>
            </Table>
            <Button variant="outline-primary rounded-0 float-right" onClick={() => this.handleClick()}>{`${t("translation:general>show")} ${t("translation:general>cadastre")}`}</Button>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}


const actions = dispatch => ({
  getFile: (cause_id, file_id) => dispatch({type: GET_FILES_CAUSE, payload: {cause_id, file_id}})
});

const data = state => ({
});

export default connect(data, actions)(withTranslation()(CatastroCard));
