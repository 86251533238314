import { takeLatest, call, put } from 'redux-saga/effects'
import { GET_CLIENTS_SOCIETIES, CREATE_CLIENT_SOCIETY } from "./types";
import { FETCH_CLIENTS_SOCIETIES, PUT_CLIENT_SOCIETY } from "../../stores/clientSociety/types";
import { FETCH_CLIENT } from "../../stores/client/types";
import { clients_societies, create_client_society } from "../../network/clientSociety";
import { client } from "../../network/clients";
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

function* getClientsSocieties() {
  try {
    const {data} = yield call(clients_societies);
    yield put({type: FETCH_CLIENTS_SOCIETIES, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* createClientSociety(action){
  const { payload } = action;
  try {
    const { data } = yield call(create_client_society, payload);
    yield put({type: PUT_CLIENT_SOCIETY, payload: data.data});
    const client_response = yield call(client, payload.client_id);
    yield put({type: FETCH_CLIENT, payload: client_response.data.data});
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}


function* watchGetClientsSocieties() {
  yield takeLatest(GET_CLIENTS_SOCIETIES, getClientsSocieties)
}

function* watchCreateClientSociety() {
  yield takeLatest(CREATE_CLIENT_SOCIETY, createClientSociety)
}

export default [
  watchGetClientsSocieties(),
  watchCreateClientSociety(),
]
