import React, { Component } from 'react'
import { Container, Table, TabContent, TabPane } from 'react-bootstrap'
import TabPaneStage from './tabpane.stage'


export default class ButtonToolBar extends Component {
  render() {
    return (
      <TabContent>
        <TabPaneStage {...this.props}/>
        <TabPane className="fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
          <Container>
            <Table hover className="mt-3">
              <tbody>
                {/*
                <tr>
                  <td className="align-middle">Lizzie Rose</td>
                  <th className="align-middle" scope="row">11 ENE 2019</th>
                </tr>
                <tr>
                  <td className="align-middle">Martijn Dragonjer</td>
                  <th className="align-middle" scope="row">26 DIC 2018</th>
                </tr>
                <tr>
                  <td className="align-middle">Oscar de la Farra</td>
                  <th className="align-middle" scope="row">20 DIC 2018</th>
                </tr>
                <tr>
                  <td className="align-middle">Martijn Dragonjer</td>
                  <th className="align-middle" scope="row">10 DIC 2018</th>
                </tr>
                <tr>
                  <td className="align-middle">Lizzie Rose</td>
                  <th className="align-middle" scope="row">09 DIC 2018</th>
                </tr>
                <tr>
                  <td className="align-middle">Lizzie Rose</td>
                  <th className="align-middle" scope="row">01 DIC 2018</th>
                </tr>
                */}
              </tbody>
            </Table>
          </Container>
        </TabPane>
      </TabContent>
    )
  }
}