import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { GET_USERS } from "../../sagas/user/types";
import { GET_SOCIETIES } from "../../sagas/society/types";
import { Col, Button, Container, Row, ButtonToolbar } from 'react-bootstrap'
import { GreatingBar, Tables, Modal, Avatar } from '../../components/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreateSociety from "../../components/societies/create.society";
import Empty from '../../components/emptyCard';
import { PATH } from '../../config/constants/paths'
import { GENERAL } from '../../config/constants/tableTitles'
import { USER_TYPES } from '../../config/constants/user';

class Societies extends Component {
  constructor(props){
    super(props);
    this.state = {modalShow: false};
    this.modalClose = this.modalClose.bind(this);
  }
  componentWillMount() {
    this.props.getSocieties();
    this.props.getUsers();
  }
  modalClose(){
    this.setState({ modalShow: false });
  }
  generateTable = (props) => {
    const { t } = this.props;
    const element = props.content.map(item => {
      const representatives = item.representatives || [];
      return (
        <tr key={item.id}>
          <td></td>
          <td>
            <p>{item.name}</p>
          </td>
          <td/>
          <td className="align-middle">{item.causes_number}</td>
          <td />
          <td className="align-middle">{item.created_at}</td>
          <td />
          <td>
            {this.generateAvatars(representatives)}
          </td>
          <td className="align-middle actions">
            <Link className="nav-link text-center" to={{pathname: `${PATH.SOCIETIES}${PATH.PROFILE}${item.id}`, state: {clientId: item.id}}}>{t("translation:general>show")}</Link>
            {/* TODO; Implement edit.
            this.showEditAction(this.props.user)*/}
          </td>
        </tr>
      )
    })
    return element;
  }
  generateAvatars(users) {
    const avatars = users.map(user =>{
      const usersAux = this.props.users ? this.props.users : [];
      const item = usersAux.length > 0 ? usersAux.find(obj => obj.id === user.user_client_id) : [];
      if (item){
      return(
        <Avatar key={item.id} height={40} {...item}/>
      )}
    })
    return avatars;
  }
  showEditAction(user){
    const { t } = this.props;
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN || type === USER_TYPES.ADMIN ){
      return (
        <>
          <Button variant="link">{t("translation:general>edit")}</Button>
        </>
      )
    }
  }
  showCreateModal(user){
    const type = user.type ? user.type.trim() : '';
    const { t } = this.props;
    if( type === USER_TYPES.SUPER_ADMIN ){
      return (
        <>
          <ButtonToolbar className="button float-right">
            <Button variant="primary" onClick={() => this.setState({ modalShow: true })}>
              {t("translation:general>create")} {t("translation:general>society").toLowerCase()} <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
            </Button>
          </ButtonToolbar>

          <Modal show={this.state.modalShow} onHide={this.modalClose} >
            <CreateSociety closeModal={this.modalClose}/>
          </Modal>
        </>
      );
    }
  }

  emptySocieties = () => {
    const { user, t } = this.props;
    const type = user.type ? user.type.trim() : '';
    let message = t("translation:emptyTable>societies>message");
    let button = <></>;
    if( type === USER_TYPES.SUPER_ADMIN ){
      message = t("translation:emptyTable>societies>messageAdmin");
      button = <Button onClick={() => this.setState({ modalShow: true })} className="rounded-0">{`${t("translation:general>create")} ${t("translation:general>society")}`}</Button>;
    }
    return (
      <Col className="pl-2" xs="3">
        <h5>
          {t("translation:emptyTable>societies>title")}
        </h5>
        <p className="text-muted">
          {message}
        </p>
        {button}
      </Col>
    )
  }

  toogleSociety = () => {
    const { t } = this.props;
    const Table = this.generateTable;
    const EmptySocieties = this.emptySocieties;
    if (this.props.societies.length > 0) {
      const titles = [
        GENERAL.EMPTY,
        t("translation:general>name"),
        GENERAL.EMPTY,
        t("translation:general>causesNumber"),
        GENERAL.EMPTY,
        t("translation:general>created"),
        GENERAL.EMPTY,
        t("translation:general>representatives"),
      ];
      return (
        <Container className="pb-5 mtn-5">
          <Row>
            <Col className="col-12">
            <Tables titles={titles}>
              <Table content={this.props.societies}/>
            </Tables>
            </Col>
          </Row>
        </Container>
      )
    }
    return (
      <Container className="pb-5 mtn-3">
          <Row>
            <Col className="col-12">
              <Empty type="empty-table">
                <EmptySocieties/>
              </Empty>
            </Col>
          </Row>
      </Container>
    )
  }
  render() {
    const { t } = this.props;
    const ToogleSociety = this.toogleSociety;
    return (
      <>
        <GreatingBar title={t("translation:general>societies")}>
          {this.showCreateModal(this.props.user)}
        </GreatingBar>
        <ToogleSociety />
      </>
    )
  }
}

const data = state => ({
  societies: state.society.societies,
  users: state.user.users,
  user: state.user.user,
});


const actions = dispatch => ({
  getSocieties: () => dispatch({type: GET_SOCIETIES}),
  getUsers: () => dispatch({type: GET_USERS}),
});

export default connect(data, actions)(withTranslation()(Societies));
