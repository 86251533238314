import {takeLatest, call, put, select } from 'redux-saga/effects'
import {
  tasks,
  fill_task,
  create_task,
} from "../../network/tasks";
import { cause } from "../../network/causes";
import { FILL_CONCESSION, GET_CONCESSION, CREATE_CONCESSION } from "./types";
import { FETCH_CONCESSION } from "../../stores/stage/types";
import { FETCH_CONCESSION_TASK } from "../../stores/task/types";
import { FETCH_ERROR_STATUS } from '../../stores/errors/types';
import { STATES, STAGE } from '../../config/constants/states';
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'
import { GENERAL } from '../../config/constants/resources';

const cookies = new Cookies();

function* getTasksById(action) {
  const {payload} = action;
  try {
    const {data} = yield call(tasks, payload);
    return data.data;
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getConcession(action) {
  const task = yield* getTasksById(action);
  yield put({type: FETCH_CONCESSION_TASK, payload: task});
}

function* getConcessionStage() {
  try {
    const getCause = (state) => state.cause.cause;
    const causeStore = yield select(getCause);
    const causeAxios = yield call(cause,  causeStore.id);
    const concession = causeAxios.data.data.stages.flatMap(st => {
      if (st.number === STAGE.CONCESSION) return [st];
      return []
    });
    if (concession[0]) {
      yield put({ type: FETCH_CONCESSION, payload: concession[0] });
    }
  } catch(e) {
    console.log(e);
  }
}

function* fillConcessionTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const task = yield call(fill_task, payload);
    const { data } = yield call(tasks, task.data.data.id);
    yield put({type: FETCH_CONCESSION_TASK, payload: data.data});
    yield* getConcessionStage();
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING }})
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    console.log(e);
  }
}

function* createTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const { createTask } = payload;
    const {data} = yield call(create_task, createTask);
    return data.data;
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    if (e.request.status === STATES.STATUS.UNAUTHORIZE) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* createAndShow(action){
  try {
    const createdTask = yield* createTask(action);
    const showTask = yield* getTasksById({payload: createdTask.id});
    yield put({type: FETCH_CONCESSION_TASK, payload: showTask});
  } catch(e) {
    console.log(e);
  }
}

function* watchCreateAndShow() {
  yield takeLatest(CREATE_CONCESSION, createAndShow);
}

function* watchFillTitleTask() {
  yield takeLatest(FILL_CONCESSION, fillConcessionTask);
}

function* watchGetConcession() {
  yield takeLatest(GET_CONCESSION, getConcession);
}

export default [
  watchFillTitleTask(),
  watchCreateAndShow(),
  watchGetConcession(),
];