import React, { Component } from 'react'
import { Col, Form, FormCheck } from 'react-bootstrap'
import { CHECK_TASK_CAUSES } from '../../config/constants/components'
import { SIZE_COLUMN } from '../../config/constants/various';
import { withTranslation } from "react-i18next";

class desissionCheck extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isValid = this.props.errors.find(e => e.id === this.props.id)
      ? false : true;
    const { t } = this.props;
    const placeholder = t(this.props.placeholder).split(' ');
    const sizeColumn = this.props.sizeColumn || SIZE_COLUMN;
    if (this.props.nextTask.length === CHECK_TASK_CAUSES.NEXT_TASKS_AMOUNT.TWO) {
      return(
        <Col className="col-12 check mb-4" sm={sizeColumn}>
          <p className="mb-2">{t(this.props.label)}</p>
          <Form.Check
            key={this.props.id}
            {...(isValid) ? { isValid: true } : { isInvalid: true }}>
            <FormCheck.Input
              id={this.props.id}
              type="radio"
              name={this.props.id}
              value={"null"}
              {...(isValid) ? {} : { isInvalid: true }}
              {...(this.props.value === "null") ? { checked: true} : {}}
              {...(this.props.value === this.props.nextTask[0].id && this.props.state === 1) ? {disabled: true} : {}}
              onChange={this.props.handleChange}/>
            <FormCheck.Label htmlFor={this.props.id}>{placeholder[0]}</FormCheck.Label>
          </Form.Check>
          <Form.Check className="ml-3"
            value={this.props.value}>
            <FormCheck.Input
              id={this.props.id}
              type="radio"
              name={this.props.id}
              value={this.props.nextTask[0].id}
              {...(isValid) ? {} : { isInvalid: true }}
              {...(this.props.value === this.props.nextTask[0].id) ? { checked: true} : {}}
              {...(this.props.value === "null" && this.props.state === 1) ? {disabled: true} : {}}
              onChange={this.props.handleChange}/>
            <FormCheck.Label htmlFor={this.props.id}>{placeholder[1]}</FormCheck.Label>
          </Form.Check>
        </Col>
      )
    } else if (this.props.nextTask.length === CHECK_TASK_CAUSES.NEXT_TASKS_AMOUNT.THREE) {
      const value1 = (this.props.nextTask[0].is_default) ? this.props.nextTask[0].id : this.props.nextTask[1].id;
      const value2 = (this.props.nextTask[0].is_default) ? this.props.nextTask[1].id : this.props.nextTask[0].id;
      return(
        <Col className="col-12 check mb-4" sm={sizeColumn}>
          <p className="mb-2">{t(this.props.label)}</p>
          <Form.Check
            key={this.props.id}>
            <FormCheck.Input
              id={this.props.id}
              type="radio"
              name={this.props.id}
              {...(isValid) ? {} : { isInvalid: true }}
              {...(this.props.value === value1) ? { checked: true} : {}}
              {...(this.props.value === value2 && this.props.state === 1) ? {disabled: true} : {}}
              value={value1}
              onChange={this.props.handleChange}/>
            <FormCheck.Label htmlFor={this.props.id}>{placeholder[0]}</FormCheck.Label>
          </Form.Check>
          <Form.Check className="ml-3">
            <FormCheck.Input
              id={this.props.id}
              type="radio"
              name={this.props.id}
              {...(isValid) ? {} : { isInvalid: true }}
              {...(this.props.value === value2) ? { checked: true} : {}}
              {...(this.props.value === value1 && this.props.state === 1) ? {disabled: true} : {}}
              value={value2}
              onChange={this.props.handleChange}/>
            <FormCheck.Label htmlFor={this.props.id}>{placeholder[1]}</FormCheck.Label>
          </Form.Check>
        </Col>
      )
    }
    return(
      <Col className="col-12 check mb-4" sm={sizeColumn}>
        <p className="mb-2">{t(this.props.label)}</p>
        <Form.Check>
          <FormCheck.Input id={this.props.id} type="radio" name="exampleRadios" value="option1"/>
          <FormCheck.Label htmlFor={this.props.id}>{this.props.content.shift()}</FormCheck.Label>
        </Form.Check>
        <Form.Check className="ml-3">
          <FormCheck.Input id="check2" type="radio" name="exampleRadios" value="option2"/>
          <FormCheck.Label htmlFor="check2">{this.props.content.shift()}</FormCheck.Label>
        </Form.Check>
      </Col>
    )
  }
}

export default (withTranslation()(desissionCheck));
