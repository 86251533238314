import {FETCH_FILE, FETCH_FILES_CAUSE, UPLOAD_FILE_INFO, UPDATE_FILE_INFO} from "./types";

const initial = {
  files: {},
  file: [],
  uploadFile: {},
  updateFile: {},
};

export default (state = initial, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_FILE:
      return {...state, files: payload}
    case FETCH_FILES_CAUSE:
      return {...state, file: payload}
    case UPLOAD_FILE_INFO:
      return {...state, uploadFile: payload}
    case UPDATE_FILE_INFO:
      return {...state, updateFile: payload}
    default:
      return state;
  }
}
