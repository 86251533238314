import axios from './config'

export const create_stage_by_number = async (causeId, stageNumber) =>  await axios
  .post(`/b/p/cause/${causeId}/stage/number/${stageNumber}`, {} , {
    headers: {
      service: "CORE"
    }
  });

export const get_stage_by_number = async (causeId, stageNumber) => await axios
  .get(`/b/p/cause/${causeId}/stage/number/${stageNumber}`, {
    headers : {
      service :"CORE"
    }
  });
