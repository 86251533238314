import {takeLatest, call, put, all, select} from 'redux-saga/effects'
import {
  CREATE_CAUSE,
  GET_CAUSES,
  GET_CAUSE,
  GET_CAUSE_INFO,
  GET_CAUSE_FULL_INFO,
  SET_CAUSE,
  SET_CAUSE_INFO,
  GET_CAUSE_FILES,
  GET_CAUSE_NORMAL_FILES,
  GET_CAUSE_CATASTRO_FILES,
  GET_CATASTROS,
  UPDATE_CAUSE,
  DELETE_CAUSES_FROM_PROJECT,
  DELETE_CAUSE,
} from "./types";
import {
  FETCH_CAUSE,
  FETCH_CAUSE_INFO,
  FETCH_CAUSE_FULL_INFO,
  FETCH_CAUSES,
  FETCH_SET_CAUSE,
  FETCH_SET_CAUSE_INFO,
  FETCH_CAUSE_FILES,
  FETCH_CAUSE_NORMAL_FILES,
  FETCH_CAUSE_CATASTRO_FILES,
  FETCH_CATASTROS,
} from "../../stores/cause/types";
import { FETCH_TITLE, FETCH_PAYMENT, FETCH_NEGOTIATION, FETCH_CONCESSION } from "../../stores/stage/types";
import { FETCH_PROJECT } from "../../stores/project/types";
import {
  causes,
  cause,
  delete_cause,
  cause_info,
  cause_full_info,
  cause_files,
  cause_normal_files,
  cause_catastro_files,
  create_cause,
  cause_update,
  document_list_all,
} from "../../network/causes";
import {
  create_stage_by_number,
} from "../../network/stages";
import { project } from "../../network/projects";
import { CORE } from '../../config/constants/network';
import { STAGE, STATES } from '../../config/constants/states';
import { CLIENT_ID, CAUSES, ORDER } from '../../config/constants/filter';
import { FETCH_ERROR_STATUS } from '../../stores/errors/types';
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'
import filterQueryMaker from '../../helpers/filterQueryMaker.helper';
import { CAUSE } from '../../config/constants/query';
import { GENERAL } from '../../config/constants/resources';

const cookies = new Cookies();

function* getCauses(action) {
  const {payload} = action;
  try {
    const page = payload.pagination ? payload.pagination.page : "";
    const limit = payload.pagination ? payload.pagination.limit : "";
    let query = payload.filter ? filterQueryMaker(payload.filter) : '';
    query += payload.clientId ? CLIENT_ID+payload.clientId : '';
    if(query === ''){
      query = `?page=${page}&limit=${limit}`;
    }
    const {data} = yield call(causes, query);
    yield put({type: FETCH_CAUSES, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* getCause(action) {
  const {payload} = action;

  try {
    const {data} = yield call(cause, payload);
    var title = {};
    var payment = {};
    var negotiation = {};
    var concession = {};
    const stages = [];
    data.data.stages.map((item) => {
      if (item.number === STAGE.TITLE) {
        title = item;
      } else if (item.number === STAGE.PAYMENT) {
        payment = item;
      } else if (item.number === STAGE.NEGOTIATION) {
        negotiation = item;
      } else if (item.number === STAGE.CONCESSION) {
        concession = item;
      }  else {
        stages.push(item);
      }
    });
    yield put({ type: FETCH_NEGOTIATION, payload: negotiation });
    yield put({ type: FETCH_PAYMENT, payload: payment });
    yield put({ type: FETCH_CONCESSION, payload: concession });
    yield put({ type: FETCH_TITLE, payload: title });
    yield put({ type: FETCH_CAUSE, payload: {...data.data, stages} });
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getCauseInfo(action) {
  const {payload} = action;
  try {
    const {data} = yield call(cause_info, payload);
    yield put({type: FETCH_CAUSE_INFO, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getCauseFullInfo(action) {
  const {payload} = action;
  try {
    const {data} = yield call(cause_full_info, payload);
    yield put({type: FETCH_CAUSE_FULL_INFO, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getCauseFiles(action) {
  const {payload} = action;
  try {
    const {data} = yield call(cause_files, payload);
    yield put({type: FETCH_CAUSE_FILES, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getNormalCauseFiles(action) {
  const {payload} = action;
  try {
    const {data} = yield call(cause_normal_files, payload);
    yield put({type: FETCH_CAUSE_NORMAL_FILES, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getCatastroCauseFiles(action) {
  const {payload} = action;
  try {
    const {data} = yield call(cause_catastro_files, payload);
    yield put({type: FETCH_CAUSE_CATASTRO_FILES, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getCatastros(action) {
  const { payload } = action;
  try {
    const {data} = yield call(document_list_all, CORE.QUERY.CATASTRO);

    const catastros = yield all(data.data.map(async c => {
      const causeInfo = await cause_info(c.cause_id);

      const rol = causeInfo.data.data.attributes.find(a => a.type === 'textfield_rol') || "";
      const court = causeInfo.data.data.attributes.find(a => a.type === 'select_resource_mininfo') || "";

      const catastro = {
        ...c,
        cause: {
          name: causeInfo.data.data.name,
          rol,
          court,
          client: causeInfo.data.data.client,
          society: causeInfo.data.data.society,
          description: causeInfo.data.data.description,
        }
      };
      return catastro;
    }));
    yield put({type: FETCH_CATASTROS, payload: {...data, data: catastros}});
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
};

function* setCause(action) {
  const {payload} = action;

  try {
    yield put({type: FETCH_SET_CAUSE, payload})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* setCauseInfo(action) {
  const {payload} = action;

  try {
    yield put({type: FETCH_SET_CAUSE_INFO, payload})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* createCause(action) {
  const {payload} = action;
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.CREATE }})
    const {data} = yield call(create_cause, payload);
    try{
      yield call(create_stage_by_number, data.data.id, STAGE.TITLE);
    } catch (e){
      console.log(e);
    }
    try {
      yield call(create_stage_by_number, data.data.id, STAGE.PAYMENT);
    } catch (e){
      console.log(e);
    }
    try {
      yield call(create_stage_by_number, data.data.id, STAGE.NEGOTIATION);
    } catch (e){
      console.log(e);
    }
    try {
      yield call(create_stage_by_number, data.data.id, STAGE.CONCESSION);
    } catch (e){
      console.log(e);
    }
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
    window.location.href = `/cause/${data.data.id}/stages`;
  } catch (e) {
    if (e.request.status === STATES.STATUS.UNAUTHORIZE) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
  }
}

function* updateCause(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const { causeId, cause } = payload;

    yield call(cause_update, causeId, cause);

    const {data} = yield call(cause_info, causeId);
    yield put({type: FETCH_CAUSE_INFO, payload: data.data})

    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch(e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
  }
}

function* deleteCauseFromProject(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const { causeId, cause } = payload;

    yield call(cause_update, causeId, cause);

    const getProject = (state) => state.project.project;
    const projectStore = yield select(getProject);

    const response = yield call(project, projectStore.id);
    let query = filterQueryMaker([[CAUSES.PROJECT_ID, response.data.id]]);
    query += ORDER + CAUSE.ORDER.LAST_UPDATED;
    const causeResponse = yield call(causes, query);
    response.data.causes = causeResponse.data.data;
    yield put({type: FETCH_PROJECT, payload: response.data})

    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch(e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
  }
}

function* deleteCause(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const { causeId } = payload;
    const getCauses = (state) => state.cause.causes;
    const causesStore = yield select(getCauses);
    yield call(delete_cause, causeId);
    const newCauses = causesStore.data.filter(obj => obj.id !== causeId);
    yield put({type: FETCH_CAUSES, payload: newCauses});
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
  }
}

function* watchDeleteCauseFromProject() {
  yield takeLatest(DELETE_CAUSES_FROM_PROJECT, deleteCauseFromProject)
}

function* watchDeleteCause() {
  yield takeLatest(DELETE_CAUSE, deleteCause)
}

function* watchUpdateCause() {
  yield takeLatest(UPDATE_CAUSE, updateCause)
}

function* watchGetCauses() {
  yield takeLatest(GET_CAUSES, getCauses)
}

function* watchGetCause() {
  yield takeLatest(GET_CAUSE, getCause)
}

function* watchGetCauseInfo() {
  yield takeLatest(GET_CAUSE_INFO, getCauseInfo)
}

function* watchGetCauseFullInfo() {
  yield takeLatest(GET_CAUSE_FULL_INFO, getCauseFullInfo)
}

function* watchGetCauseFiles() {
  yield takeLatest(GET_CAUSE_FILES, getCauseFiles)
}

function* watchGetCauseNormalFiles() {
  yield takeLatest(GET_CAUSE_NORMAL_FILES, getNormalCauseFiles)
}

function* watchGetCauseCatastroFiles() {
  yield takeLatest(GET_CAUSE_CATASTRO_FILES, getCatastroCauseFiles)
}

function* watchCreateCause() {
  yield takeLatest(CREATE_CAUSE, createCause)
}

function* watchSetCause() {
  yield takeLatest(SET_CAUSE, setCause)
}

function* watchSetCauseInfo() {
  yield takeLatest(SET_CAUSE_INFO, setCauseInfo)
}

function* watchGetCatastros() {
  yield takeLatest(GET_CATASTROS, getCatastros)
}

export default [
  watchDeleteCauseFromProject(),
  watchDeleteCause(),
  watchGetCauses(),
  watchGetCause(),
  watchGetCauseInfo(),
  watchGetCauseFullInfo(),
  watchGetCauseFiles(),
  watchCreateCause(),
  watchSetCause(),
  watchUpdateCause(),
  watchGetCatastros(),
  watchSetCauseInfo(),
  watchGetCauseNormalFiles(),
  watchGetCauseCatastroFiles(),
]
