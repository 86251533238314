import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  Container,
  ButtonToolbar,
  Button,
  Form,
  Table,
  Modal,
} from "react-bootstrap";
import Input from "./input.causes";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { POST_TASK, DELETE_TASK } from "../../sagas/tasks/types";
import { SET_ALERTS_BY_CAUSE } from "../../sagas/alert/types";
import { TASK, STATES } from "../../config/constants/states";
import { GENERAL } from "../../config/constants/tableTitles";
import { USER_TYPES } from "../../config/constants/user";
import { ATTRIBUTE_TYPE } from "../../config/constants/components";
import UploadModal from "../modal.upload";
import CustomModal from "../modal";
import Loading from "../loading";
import { BadgeStates } from "../../resolver/cause/surveillance";

const {
  COMPLETE_AND_CREATE_NEXT,
  CONTINUE_AND_CREATE_NEXT,
  CONTINUE,
  COMPLETE,
  EMPTY,
} = TASK;

class TaskCard extends Component {
  constructor(props) {
    super(props);
    this.showCreateModal = this.showCreateModal.bind(this);
    this.handleUploadClose = this.handleUploadClose.bind(this);
  }

  state = {
    attributeValues: [],
    errors: [],
    show: false,
    deleteConfirmationModal: false,
    uploadModalShow: false,
    currentFile: {},
  };

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.attributeValues) !==
      JSON.stringify(this.state.attributeValues)
    ) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      });
    }
  }

  generateAttributes(attributes, nextTask, errors) {
    return attributes.map((item) => {
      return (
        <Input
          handleChange={(e) => this.handleChange(e)}
          handleDateChange={(date, id) => this.handleDateChange(date, id)}
          {...item}
          nextTask={nextTask}
          errors={errors}
        />
      );
    });
  }

  handleDateChange(date, id) {
    try {
      const attributeValues = this.state.attributeValues;
      const cause = this.props.cause;
      const idx = attributeValues.findIndex((e) => e.id === id);

      if (attributeValues[idx].type.includes("alarm_date")) {
        this.props.setAlert(cause.id, date, id);
      }

      attributeValues[idx].value = date;
      this.setState({
        attributeValues,
        cause,
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleChange(e) {
    const attributeValues = this.state.attributeValues;
    const cause = this.props.cause;
    const { name, value } = e.target;
    const idx = attributeValues.findIndex((e) => e.id === name);

    attributeValues[idx].value = value;
    this.setState({
      attributeValues,
      cause,
    });
  }

  async validateAttributes() {
    const errors = this.state.attributeValues.filter((a) => {
      try {
        return (
          a.value === "" &&
          a.type !== "upload" &&
          a.type !== ATTRIBUTE_TYPE.CHECK_UNIQUE &&
          !(a.type === "select" && a.options.length === 0)
        );
      } catch (e) {
        console.log(e);
      }
    });
    this.setState({ errors });
  }

  async handleCompletion(state) {
    const task = {
      ...this.props.task,
      state: state,
      attributeValues: this.state.attributeValues,
    };
    const nombres = [
      "Fin de Servidumbre Judicial",
      "Fin Tramitación",
      "Fin Servidumbre Convencional",
      "Fin del petitorio",
      "Fin de la Tramitación",
      "Fin Servidumbre Administrativa",
      "Fin de Tramitación",
      "Fin de la tramitación",
      "Fin de Nulidad",
      "Fin Nulidad",
      "Fin de Oposición",
      "Fin Oposición",
      "Fin petitorio minero",
    ];
    if (!nombres.includes(this.props.task.name)) {
      this.props.postTask(
        task,
        this.props.cause,
        this.props.alertByCause,
        this.props.causeInfo
      );
    } else {
      // Si es la tarea final pasara con estado 6 que será terminado
      const task = {
        ...this.props.task,
        state: 6,
        attributeValues: this.state.attributeValues,
      };
      this.props.postTask(
        task,
        this.props.cause,
        this.props.alertByCause,
        this.props.causeInfo
      );
    }
  }

  handleClose() {
    this.setState({ show: false, deleteConfirmationModal: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  handleDeleteModalShow() {
    this.setState({ deleteConfirmationModal: true });
  }
  // ACTION BUTTONS
  generateTable(files) {
    const { t } = this.props;
    return files.map((item) => {
      return (
        <tr>
          <td className="align-middle text-left">
            <p>{item.name === "" ? t(item.label) : item.name}</p>
            <span>{item.name === "" ? "" : t(item.label)}</span>
          </td>
          <td className="align-middle text-left">
            <BadgeStates state={item.state} />
          </td>
          <td className="align-middle float-right actions">
            <Button
              variant="link"
              onClick={() => this.handleUploadClick(item)}
              className="rounded-0 sm"
            >
              {t("translation:general>load")}
            </Button>
          </td>
        </tr>
      );
    });
  }

  showCreateModal() {
    const type = this.props.user.type ? this.props.user.type.trim() : "";
    if (type === USER_TYPES.SUPER_ADMIN || type === USER_TYPES.ADMIN) {
      const { t } = this.props;
      return (
        <>
          <CustomModal
            show={this.state.uploadModalShow}
            onHide={this.handleUploadClose}
          >
            <UploadModal
              title={t("translation:modal>documents>title")}
              subtitle={t("translation:modal>documents>subtitle")}
              closeModal={this.handleUploadClose}
              {...this.props}
              file={this.state.currentFile}
            />
          </CustomModal>
        </>
      );
    }
    return <React.Fragment></React.Fragment>;
  }

  finalizeTaskButton(props, complete_state, continue_state) {
    const { id, state } = this.props.task;
    const actualStage = this.props.stages.find((stage) => stage.state === 3);
    const { status, resource } = this.props.error;
    const { t } = this.props;
    const nombres = [
      "Fin de Servidumbre Judicial",
      "Fin Tramitación",
      "Fin Servidumbre Convencional",
      "Fin del petitorio",
      "Fin de la Tramitación",
      "Fin Servidumbre Administrativa",
      "Fin de Tramitación",
      "Fin de la tramitación",
      "Fin de Nulidad",
      "Fin Nulidad",
      "Fin de Oposición",
      "Fin Oposición",
      "Fin petitorio minero",
    ];
    if (actualStage && !nombres.includes(this.props.task.name)) {
      return (
        <>
          <Button
            variant="outline-primary ml-2"
            onClick={(e) => this.handleShow()}
          >
            {t("translation:general>continue")}
          </Button>
          <Button
            variant="primary"
            onClick={async (e) => {
              await this.validateAttributes();
              if (this.state.errors.length === 0) {
                this.handleCompletion(complete_state);
              }
            }}
            className="ml-2"
          >
            <Loading title={t("translation:general>complete")} />
          </Button>
          <Modal show={this.state.show} onHide={(e) => this.handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("translation:modal>actions>continue>title")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("translation:modal>actions>continue>subtitle")}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="button"
                variant="outline-primary rounded-0"
                onClick={(e) => this.handleClose()}
              >
                {t("translation:general>cancel")}
              </Button>
              <Button
                variant="primary rounded-0"
                onClick={(e) => {
                  this.handleCompletion(continue_state);
                  if (this.props.status === 0) this.handleClose();
                }}
              >
                <Loading title={t("translation:general>continue")} />
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    } else if (
      actualStage &&
      nombres.includes(this.props.task.name) &&
      state !== 6
    ) {
      return (
        <>
          <Button
            variant="outline-primary ml-2"
            onClick={(e) => this.handleShow()}
          >
            {t("translation:general>finalize")}
          </Button>
          <Modal show={this.state.show} onHide={(e) => this.handleClose()}>
            <Modal.Header closeButton>
              <Modal.Title>
                {t("translation:modal>actions>finalize>title")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("translation:modal>actions>finalize>subtitle")}
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="button"
                variant="outline-primary rounded-0"
                onClick={(e) => this.handleClose()}
              >
                {t("translation:general>cancel")}
              </Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  this.handleCompletion(complete_state);
                  this.handleClose();
                }}
                className="ml-2"
              >
                <Loading title={t("translation:general>finalize")} />
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    }
  }

  deleteTaskButton() {
    const { id, state } = this.props.task;
    const actualStage = this.props.stages.find((stage) => stage.state === 3);
    const { status, resource } = this.props.error;
    if (actualStage) {
      const index = actualStage.tasks.length - 1;
      const lastTask = actualStage.tasks[index];
      if (lastTask.id === id && state !== 6) {
        const { t } = this.props;
        return (
          <>
            <Button
              variant="outline-danger"
              onClick={(e) => this.handleDeleteModalShow()}
            >
              {t("translation:general>delete")}
            </Button>
            <Modal
              show={this.state.deleteConfirmationModal}
              onHide={(e) => this.handleClose()}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {t("translation:modal>actions>delete>title")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {t("translation:modal>actions>delete>subtitle")}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="button"
                  variant="outline-primary rounded-0"
                  onClick={(e) => this.handleClose()}
                >
                  {t("translation:general>cancel")}
                </Button>
                <Button
                  variant="danger rounded-0"
                  disabled={
                    status === STATES.STATUS.WAITING &&
                    resource === GENERAL.CREATE
                  }
                  onClick={(e) => {
                    this.handleDelete(e);
                  }}
                >
                  <Loading
                    resource="delete"
                    title={t("translation:general>delete")}
                  />
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }
    }
  }

  handleDelete(e) {
    const { id } = this.props.task;
    const causeId = this.props.cause.id;
    e.preventDefault();
    this.props.deleteTask(causeId, id);
    this.handleClose();
  }

  handleUploadClose() {
    this.setState({ uploadModalShow: false });
  }

  handleUploadShow() {
    this.setState({ uploadModalShow: true });
  }

  handleUploadClick(props) {
    this.handleUploadShow();
    this.setState({ currentFile: props });
  }

  render() {
    const state = this.props.task.state;
    const UploadModal = this.showCreateModal;
    const { t } = this.props;
    const ActionButtons = () => {
      const complete_state =
        state === EMPTY ? COMPLETE_AND_CREATE_NEXT : COMPLETE;
      const continue_state =
        state === EMPTY ? CONTINUE_AND_CREATE_NEXT : CONTINUE;

      if (this.props) {
        return (
          <>
            <ButtonToolbar className="button float-right pt-3">
              {this.deleteTaskButton()}
              {this.finalizeTaskButton(
                this.props,
                complete_state,
                continue_state
              )}
            </ButtonToolbar>
          </>
        );
      } else {
        return <div />;
      }
    };
    return (
      <Col className="col-12" lg={7} xl={8}>
        <Card className="right-content">
          <Container>
            <UploadModal />

            <Row className="mb-4 mt-4">
              <Col className="col-12 mb-4" xl={4}>
                <div className="title p-2 mt-2">
                  <span>{t("translation:general>task")}</span>
                  <h4>{t(this.props.task.name)}</h4>
                  <p>{t(this.props.task.description)}</p>
                </div>
              </Col>

              <Col className="col-12 mb-4" xl={8}>
                <Form.Row className="mt-5">
                  {this.generateAttributes(
                    this.state.attributeValues,
                    this.props.task.nextTask,
                    this.state.errors
                  )}
                </Form.Row>
              </Col>
              {this.props.files.length > 0 ? (
                <Col className="hr-custom">
                  <Card className="info__customers mt-4">
                    <Table className="mb-3" hover>
                      <thead>
                        <tr>
                          <th className="th-custom" width="80%" scope="col">
                            {t("translation:general>name")}
                          </th>
                          <th className="th-custom" scope="col">
                            {t("translation:general>state")}
                          </th>
                          <th className="th-custom" scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>{this.generateTable(this.props.files)}</tbody>
                    </Table>
                  </Card>
                </Col>
              ) : null}
            </Row>
            <Row className="mb-3 mt-4 line">
              <Col className="col-12">
                <ActionButtons />
              </Col>
            </Row>
          </Container>
        </Card>
      </Col>
    );
  }
}

const data = (state) => ({
  error: state.error,
  task: state.task.task,
  attributeValues: state.task.task.hasOwnProperty("attributeValues")
    ? state.task.task.attributeValues
    : [],
  files: state.task.task.hasOwnProperty("files") ? state.task.task.files : [],
  cause: state.cause.cause,
  stages: state.cause.cause.hasOwnProperty("stages")
    ? state.cause.cause.stages
    : [],
  causeInfo: state.cause.causeInfo,
  alertByCause: state.alert.alertByCause,
  user: state.user.user,
  status: state.error.status,
});

const actions = (dispatch) => ({
  postTask: (task, cause, alertByCause, causeInfo) =>
    dispatch({
      type: POST_TASK,
      payload: {
        task,
        cause,
        alertByCause,
        causeInfo,
      },
    }),
  setAlert: (causeId, startDate, attributeValueId) =>
    dispatch({
      type: SET_ALERTS_BY_CAUSE,
      payload: {
        causeId,
        startDate,
        attributeValueId,
      },
    }),
  deleteTask: (causeId, taskId) =>
    dispatch({
      type: DELETE_TASK,
      payload: {
        causeId,
        taskId,
      },
    }),
});

export default connect(data, actions)(withTranslation()(TaskCard));
