import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import SideBar from '../../../components/causes/negotiation/sideBar'
import NegotiationCard from '../../../components/causes/negotiation/card'
import '../../../css/app.css'
import { USER_TYPES } from '../../../config/constants/user';
import { GET_TASKS_ATTRIBUTES } from '../../../sagas/negotiation/types'

class Negotiation extends Component {

  // userTypeShow(user){
  //   if(user.type){
  //     const type = user.type.trim();
  //     if(type === USER_TYPES.CLIENT || type === USER_TYPES.VISUALIZER){
  //       return <InfoCard isParent={true} {...this.props}/>;
  //     } else {
  //       return <TaskCard isParent={true} {...this.props}/>;
  //     }
  //   }
  // }

  constructor(props) {
    super(props);
    this.state = {
      lastTask: {},
    }
  }

  componentDidMount() {
    this.props.getNegotiationTasks();
  }

  componentDidUpdate(nextProps, nextState) {
    if (JSON.stringify(nextProps.negotiations) !== JSON.stringify(this.props.negotiations)) {
      this.props.getNegotiationTasks();
    }
  }

  render() {
    return (
      <Container className="pb-5">
        <Row className="mtn-5">
          <SideBar {...this.props}/>
          <NegotiationCard {...this.props}/>
          {/* {this.userTypeShow(this.props.user)} */}
        </Row>
      </Container>
    )
  }
}

const data = state => ({
  user: state.user.user,
  negotiations: state.stage.negotiation.hasOwnProperty('tasks')
    ? state.stage.negotiation.tasks
    : [],
});

const actions = dispatch => ({
  getNegotiationTasks: () => dispatch({type: GET_TASKS_ATTRIBUTES}),
})

export default withRouter(connect(data, actions)(Negotiation));
