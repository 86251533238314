export const PATH = {
  USERS: 'users/',
  CLIENTS: 'clients/',
  CLIENT: 'client/',
  SOCIETIES: 'societies/',
  SOCIETY: 'society/',
  STAGES: 'stages/',
  PROFILE: 'profile/',
  CAUSES: 'causes/',
  CAUSE: 'cause/'
}

export default PATH;
