import React, {Component} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CauseCard from '../../../components/projects/causeCard.project';
import {connect} from "react-redux";
import { GET_PROJECT_CAUSES } from '../../../sagas/project/types';
import { CAUSES } from '../../../config/constants/filter';
import Empty from '../../../components/emptyCard';
import { CAUSE } from '../../../config/constants/query';
import { withTranslation } from "react-i18next";

class Causes extends Component {
  componentWillMount(){
    const {id} = this.props.match.params;
    const query = [[CAUSES.PROJECT_ID, id]];
    this.props.getProjectCause(query);
  }

  generateTable(causes){
    return causes.map((item, index) => {
      return (
        <CauseCard cause={item}/>
      );
    })
  }

  emptyTable = () => {
    const { t } = this.props;
    return (
    <Col className="pl-2" xs="7" md="3">
      <h5>
        {t("translation:emptyTable>projects>causes>title")}
      </h5>
      <p className="text-muted">
        {t("translation:emptyTable>projects>causes>subtitle")}
      </p>
    </Col>
  )}

  toogleEmpty = () => {
    const causes = this.props.project.causes ? this.props.project.causes : [] ;
    const EmptyTable = this.emptyTable;
    if (causes.length > 0) {
      return this.generateTable(causes);
    }
    return (
      <Empty type="empty-table">
        <EmptyTable />
      </Empty>
    )
  }

  render() {
    return (
      <Container className="pb-5 info">
        <Row className="mtn-5">
          <Col className="col-12">
            <Container className="right-content">
              <Card className="p-4">
                <Row>
                  {this.toogleEmpty()}
                </Row>
              </Card>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

const actions = dispatch => ({
  getProjectCause: (query) => dispatch({type: GET_PROJECT_CAUSES, payload: { filter: query, order: CAUSE.ORDER.LAST_UPDATED }}),
});


const data = state => ({
  project: state.project.project,
});

export default connect(data, actions)(withTranslation()(Causes));
