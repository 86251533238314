import {takeLatest, call, put, select} from 'redux-saga/effects'
import { SET_AVATAR } from "./types";
import { avatar } from "../../network/user";
import { LOGIN_URL } from '../../config/constants/routes';
import { DOCUMENT } from '../../config/constants/states';
import { UPLOAD_FILE_INFO } from "../../stores/file/types";
import Cookies from 'universal-cookie'
import { FETCH_USER_DATA } from '../../stores/user/types';

const cookies = new Cookies();

function* updateAvatar(action) {
  try {
    const fileState = {
      progress: DOCUMENT.PROGRESS.EMPTY,
      responseState: DOCUMENT.SUCCESS,
    };
    yield put({type: UPLOAD_FILE_INFO, payload: { ...fileState}})
    const { payload } = action;
    const { data } = yield call(avatar, payload);
    const { url } = data;

    fileState.progress = DOCUMENT.PROGRESS.OK;

    const getUser = (state) => state.user.user;
    const user = yield select(getUser);
    user.avatar = url;

    yield put({type: FETCH_USER_DATA, payload: user })

    yield put({type: UPLOAD_FILE_INFO, payload: { ...fileState}})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    const fileState = {
      progress: DOCUMENT.PROGRESS.ERROR,
      responseState: DOCUMENT.ERROR,
    };

    yield put({type: UPLOAD_FILE_INFO, payload: { ...fileState}})
  }
}

function* watchSetAvatar() {
  yield takeLatest(SET_AVATAR, updateAvatar)
}

export default [
  watchSetAvatar(),
];
