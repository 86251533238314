import {takeLatest, call, put, select} from 'redux-saga/effects';
import {UPLOAD_FILE, GET_FILES_CAUSE, UPLOAD, UPLOAD_INFO, UPLOAD_BY_URL, UPDATE_FILE, UPDATE_BY_URL} from "./types";
import {FETCH_TASK, FETCH_TITLE_TASK} from "../../stores/task/types";
import { UPLOAD_FILE_INFO } from "../../stores/file/types";
import {fileUpload, getFiles, fileUrlUpload} from "../../network/files";
import { create_file_task, update_file_task } from '../../network/causes';
import { alert } from '../../network/alerts';
import { DOCUMENT } from '../../config/constants/states';
import { FETCH_ALERT_BY_CAUSE } from '../../stores/alert/types';
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

function* uploadFile(action) {
  const {payload} = action;
  const {file, task, file_id} = payload;
  try {
    const {data} = yield call(fileUpload, file);
    const t = task;
    t.files = task.files.map(f => {
      if(f.id === file_id) {
        f.name = `${data.original_name}.${data.extension}`;
        f.file_id = data.id;
      }
      return f;
    });
    yield put({type: FETCH_TASK, payload: t});
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* upload(action) {
  const {payload} = action;
  const {file, fileInfo, causeId} = payload;
  try {
    const {data} = yield call(fileUpload, file, causeId);
    const uploadedFile = yield call(create_file_task, {
      name: file.name,
      description: data.original_name,
      file_id: data.id,
      ...fileInfo,
    });
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.SUCCESS,
    };
    yield put({type: UPLOAD_FILE_INFO, payload: { ...uploadedFile.data.data, ...fileState}})
    const alerts = yield call(alert, causeId);
    yield put({type: FETCH_ALERT_BY_CAUSE, payload: alerts.data.data})
  } catch (e) {
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.ERROR,
    };
    yield put({type: UPLOAD_FILE_INFO, payload: fileState})
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* updateFileTask(action) {
  const { payload } = action;
  const { type, task, uploadedFile } = payload;
  if (task.files) {
    task.files = task.files.map(item => {
      const file = uploadedFile;
      if (item.id === file.id) {
        return {
          id: file.id,
          name: file.name,
          file_id: file.file_id,
          state: file.state,
          label: file.document.label,
          description: file.description,
          type: 'upload',
        }
      }
      return item;
    })
    yield put({ type, payload: task })
  }
}

function* updateFileTasks(action) {
  const { payload } = action;
  const getTasks = (state) => state.task;
  const {task, taskTitle} = yield select(getTasks);
  yield* updateFileTask({ payload:
    {
      type: FETCH_TASK,
      task,
      uploadedFile: payload.uploadedFile,
    },
  });
  yield* updateFileTask({ payload:
    {
      type: FETCH_TITLE_TASK,
      task: taskTitle,
      uploadedFile: payload.uploadedFile,
    },
  });
}

function* updateFile(action) {
  const {payload} = action;
  const {file, fileInfo, causeId} = payload;
  try {
    const {data} = yield call(fileUpload, file, causeId);
    const uploadedFile = yield call(update_file_task, {
      name: file.name,
      description: fileInfo.description || data.original_name,
      file_id: data.id,
      ...fileInfo,
    });
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.SUCCESS,
    };

    yield* updateFileTasks({ payload: { uploadedFile: uploadedFile.data.data } });
    yield put({type: UPLOAD_FILE_INFO, payload: { ...uploadedFile.data.data, ...fileState}})
  } catch (e) {
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.ERROR,
    };
    yield put({type: UPLOAD_FILE_INFO, payload: fileState})
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
  }
}

function* uploadByUrl(action) {
  const {payload} = action;
  const {file, fileInfo} = payload;
  try {
    const {data} = yield call(fileUrlUpload, file);
    const uploadedFile = yield call(create_file_task, {
      name: file.name,
      description: '',
      file_id: data.data.id,
      ...fileInfo,
    });
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.SUCCESS,
    };
    yield put({type: UPLOAD_FILE_INFO, payload: { ...uploadedFile.data.data, ...fileState}})
  } catch (e) {
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.ERROR,
    };
    yield put({type: UPLOAD_FILE_INFO, payload: fileState})
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* updateByUrl(action) {
  const {payload} = action;
  const {file, fileInfo} = payload;
  try {
    const {data} = yield call(fileUrlUpload, file);
    const uploadedFile = yield call(update_file_task, {
      name: file.name,
      description: data.data.original_name,
      file_id: data.data.id,
      ...fileInfo,
    });
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.SUCCESS,
    };

    yield* updateFileTasks({ payload: { uploadedFile: uploadedFile.data.data } });
    yield put({type: UPLOAD_FILE_INFO, payload: { ...uploadedFile.data.data, ...fileState}})
  } catch (e) {
    const fileState = {
      progress: 100,
      responseState: DOCUMENT.ERROR,
    };
    yield put({type: UPLOAD_FILE_INFO, payload: fileState})
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* setUploadState(action) {
  const {payload} = action;
  try {
    yield put({type: UPLOAD_FILE_INFO, payload})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* file(action) {
  const {payload} = action;
  const {cause_id, file_id} = payload;
  try {
    const {data} = yield call(getFiles, { resource_id: cause_id, file_id: file_id });
    
    const file = new Blob([data], {type: 'application/pdf'});

    const fileUrl = URL.createObjectURL(file);
    window.open(fileUrl);
  } catch(e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* watchUploadFile() {
  yield takeLatest(UPLOAD_FILE, uploadFile);
}

function* watchObtainFile() {
  yield takeLatest(GET_FILES_CAUSE, file);
}

function* watchUpload() {
  yield takeLatest(UPLOAD, upload);
}

function* watchUploadInfo() {
  yield takeLatest(UPLOAD_INFO, setUploadState);
}

function* watchUploadUrl() {
  yield takeLatest(UPLOAD_BY_URL, uploadByUrl);
}

function* watchUpdateFile() {
  yield takeLatest(UPDATE_FILE, updateFile);
}

function* watchUpdateUrl() {
  yield takeLatest(UPDATE_BY_URL, updateByUrl);
}

export default [
  watchUploadFile(),
  watchObtainFile(),
  watchUpload(),
  watchUploadInfo(),
  watchUploadUrl(),
  watchUpdateFile(),
  watchUpdateUrl(),
]
