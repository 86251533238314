export const NAME = { id: '12310' ,type: 'text', name:'name', label: 'name' };
export const RUT = { id: '12311' ,type: 'text', name:'taxpayer_id', label: 'rut', pattern: "[0-9]{1,3}.[0-9]{3}.[0-9]{3}-[0-9Kk]{1}", title:"ej. 12.345.678-5"};
export const PHONE = { id: '12312' ,type: 'text', name:'phone', label: 'phone', pattern:"[+]{1}[0-9]{6,12}", title:"ej. +56987654321" };
export const ADDRESS = { id: '12313' ,type: 'text', name:'address', label: 'address' };
export const EMAIL = { id: '12314' ,type: 'email', name:'email', label: 'email', errorMessage: 'Correo electrónico ya existente.'};
export const USER_FIRST_NAME = { id: '12315' ,type: 'text', name:'first_name', label: 'names'};
export const USER_LAST_NAME = { id: '12316' ,type: 'text', name:'last_name', label: 'lastNames'};
export const PASSWORD = { id: '12317' ,type: 'password', name:'password', label: 'password'};
export const NEW_PASSWORD = { id: '12318' ,type: 'password', name:'new_password', label: 'newPassword', minlength: 6};
export const NEW_PASSWORD_CONFIRMATION = { id: '12319' ,type: 'password', name:'new_password_confirmation', label: 'newPasswordConfirmation', errorMessage: 'La contraseña no coincide.'};
export const OLD_PASSWORD = { id: '12320' ,type: 'password', name:'old_password', label: 'actualPassword', errorMessage: 'Contraseña incorrecta.'};
