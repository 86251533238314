import React from 'react';
import { SERVIDUMBRE } from '../../../config/constants/various';
import { DOCUMENT } from '../../../config/constants/states';
import { useTranslation } from "react-i18next";

const { CATASTRO } = DOCUMENT;

function StateOptionsByType(props) {
  const { t } = useTranslation();
  const { type } = props;
  if (type === SERVIDUMBRE.ADMINISTRATIVA
    || type === SERVIDUMBRE.CONVENCIONAL
    || type === SERVIDUMBRE.JUDICIAL) {
      return (
      <React.Fragment>
        <option value={CATASTRO.UPDATE.NUMBER}>{t("translation:states>surveillance>update")}</option>
        <option value={CATASTRO.ENLARGMENT.NUMBER}>{t("translation:states>surveillance>enlargment")}</option>
        <option value={CATASTRO.REDUCTION.NUMBER}>{t("translation:states>surveillance>reduction")}</option>
      </React.Fragment>
      )
    }
  return (
    <React.Fragment>
      <option value={CATASTRO.ABARCAMIENTO.NUMBER}>{t("translation:states>surveillance>outreach")}</option>
      <option value={CATASTRO.SUPERPOSICION.NUMBER}>{t("translation:states>surveillance>overlap")}</option>
      <option value={CATASTRO.UPDATE.NUMBER}>{t("translation:states>surveillance>update")}</option>
    </React.Fragment>
  );
}

export default StateOptionsByType;