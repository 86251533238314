import React, { Component } from 'react'
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default class card extends Component {

  render() {
    return (
      <Card className={this.props.className}>
        <div className="title d-flex bd-highlight align-items-center p-4">
          <h5 className="w-100 bd-highlight">{this.props.title}</h5>
          <Button className="flex-shrink-1 bd-highlight" variant="link"><Link to={this.props.to}>{this.props.button}</Link></Button>
        </div>
        {this.props.children}
      </Card>
    )
  }
}
