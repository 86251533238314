import React from 'react'
import image from '../../assets/images/illustration.svg'
import bg from '../../assets/images/bg_blue.svg'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'

export default () => (
  <div className="bg-error">
    <Container className="d-flex align-items-center">
        <Row className="d-flex align-items-center">
          <Col md="3" lg="4">
          <h1>
            Error 500
          </h1>
          <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec a est a velit gravida fermentum a at lectus. Praesent ultrices dignissim sapien in vehicula.
          </p>
          <Button className="rounded-0" variant="primary">Volver</Button>
          </Col>
          <Col md="9" lg="8">
            <Image fluid src={image}/>
          </Col>
        </Row>
      </Container>
  </div>
);
