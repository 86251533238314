import React, { Component } from 'react'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Row, Card, Button, Table } from 'react-bootstrap';
import { GET_CAUSE } from "../../../sagas/cause/types";
import { GET_TASKS_BY_STAGE } from "../../../sagas/tasks/types";
import CustomModal from './modal.concession';
import { STATES, TASK } from '../../../config/constants/states';
import { CHECK_TASK_CAUSES } from '../../../config/constants/components';
import dateHelper from '../../../helpers/date.helper'
import { USER_TYPES } from '../../../config/constants/user';
import { FILL_CONCESSION, CREATE_CONCESSION, GET_CONCESSION } from '../../../sagas/concession/types';

const { CONCESSION } = CHECK_TASK_CAUSES;

function getDate(task) {
  if (task.attributeValues) {
    const date = task.attributeValues[0].value;
    return dateHelper.parseDate(new Date(date));
  }
  return ''
}

function TableContent(props) {
  const { tasks } = props;
  let taskTable = [];
  return tasks.map((task, index) => {
    return (
      <tr>
        <td>
          {index + 1}
        </td>
        <td>
          {task.name}
        </td>
      </tr>
    )
  });
}

class Information extends Component {
  state = {
    modalShow: false,
    emptyTask: {},
  }

  deleteEmptyTask = () => {
    const { tasks } = this.props;
    if (tasks.length !== TASK.EMPTY) {
      const lastState = tasks[tasks.length - 1].state;
      const task = (lastState === TASK.EMPTY) ? tasks.pop() : {};
      this.setState({ emptyTask: task })
    }
  }

  componentDidMount() {
    this.deleteEmptyTask()
    this.props.getTasks(this.props.concession.id)
  }

  componentDidUpdate(nextProps) {
    if (nextProps.concession !== this.props.concession) {
      this.deleteEmptyTask()
    }
  }

  modalShowAndCreate = (taskName) => {
    if (!this.state.emptyTask.id) {
      const taskId = this.props.staticTasks.find(item => item.name.includes(taskName)).id;
      this.props.createTask({
          stage_check_id: this.props.concession.id,
          task_id: taskId,
          state: TASK.EMPTY,
        });
    } else {
      this.props.getConcession(this.state.emptyTask.id);
    }
    this.setState({modalShow: true});
  }

  modalShow = (task) => {
    this.props.getConcession(task.id);
    this.setState({modalShow: true});
  }

  modalClose = () => {
    this.props.getCause(this.props.cause.id);
    this.setState({modalShow: false});
  }

  saveTask = (attributeValues) => {
    const task = {
      ...this.props.taskConcession,
      state: TASK.COMPLETE,
      attributeValues,
    }

    this.props.fillConcession(task);
    if (this.props.status === STATES.STATUS.SUCCESS) {
      this.props.getCause(this.props.cause.id);
      this.setState({modalShow: false});
    };
  }

  render() {
    const type = this.props.user.type || '';
    const { t } = this.props;
    return (
      <Card className="info__customers mt-3">
        <div className="title d-flex bd-highlight align-items-center p-4">
          <h5 className="w-75 bd-highlight">{t("translation:general>concessions/plants")}</h5>
          {(type.includes(USER_TYPES.SUPER_ADMIN) || type.includes(USER_TYPES.ADMIN))
            ? (
            <Button onClick={() => this.modalShowAndCreate(CONCESSION.FIRST_TASK)} className="rounded-0">
              {`${t("translation:general>add")} ${t("translation:general>concession")}`}
            </Button>
            )
            : (<React.Fragment/>)}
        </div>
        <CustomModal
          modalShow={this.state.modalShow}
          saveTask={this.saveTask}
          onHide={this.modalClose}/>
        <Container className="table mh-400">
          <Row className="row mt-4">
            <Table>
              <thead>
                <tr>
                  <th>
                    {t("translation:general>#")}
                  </th>
                  <th>
                    {t("translation:general>concessionName")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <TableContent
                  tasks={this.props.tasks}
                  modalShowAndCreate={this.modalShowAndCreate}
                  modalShow={this.modalShow}
                  user={this.props.user}/>
              </tbody>
            </Table>
          </Row>
        </Container>
      </Card>
    )
  }
}

const actions = dispatch => ({
  fillConcession: (task) => dispatch({type: FILL_CONCESSION, payload: task}),
  createTask: (createTask) => dispatch({type: CREATE_CONCESSION, payload: { createTask }}),
  getTasks: (stageId) => dispatch({ type: GET_TASKS_BY_STAGE, payload: stageId }),
  getConcession: (taskId) => dispatch({type: GET_CONCESSION, payload: taskId}),
  getCause: (id) => dispatch({type: GET_CAUSE, payload: id}),
});


const data = state => ({
  user: state.user.user,
  cause: state.cause.cause,
  status: state.error.status,
  concession: state.stage.concession,
  staticTasks: state.stage.tasks,
  tasks: state.stage.concession.tasks,
  taskConcession: state.task.taskConcession,
});

export default connect(data, actions)(withTranslation()(Information));
