import React, { Component } from 'react';
import { Table, Card } from 'react-bootstrap';
import '../css/app.css';

function generateTitles(props) {
  return props.titles.map((title, index) => (<th className="th-custom" key={`table-${title}${index}`} scope="col">{title}</th>))
}

export default class Tables extends Component {

  render () {
    return (
      <Card className="info__customers mt-4">
        <Table className="mb-3" hover>
          <thead>
            <tr>
              {generateTitles(this.props)}
            </tr>
          </thead>
          <tbody>
            {this.props.children}
          </tbody>
        </Table>
      </Card>
    )
  }
}