import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Row, Container, Col, Button, ButtonToolbar} from 'react-bootstrap'
import {AlertTable} from '../../components/dashboard';
import CatastroCard from '../../components/dashboard/card.catastro';
import {GreatingBar, Modal} from '../../components';
import Input from '../../components/causes/input.causes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { GET_ALERTS } from "../../sagas/alert/types";
import CreateCauses from "../../components/causes/create.causes";
import { USER_TYPES } from '../../config/constants/user';
import { GET_CATASTROS } from '../../sagas/cause/types';
import Empty from '../../components/emptyCard';
import { withTranslation } from "react-i18next";

function Title(props) {
  return (
    <Col className="col-12">
      <div className={`contain-header d-flex align-items-center justify-content-between ${props.bsPrefix}`}>
        <h5>{props.title}</h5>
        <Link className="nav-link" to={props.to}>
          {props.viewAll}
          <FontAwesomeIcon className="ml-2" icon="arrow-right"/>
        </Link>
      </div>
    </Col>
  )
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {modalShow: false};
    this.modalClose = this.modalClose.bind(this);
  }

  componentWillMount() {
    this.props.getAlerts();
    this.props.getCatastros();
  }

  modalClose(){
    this.setState({ modalShow: false });
  }

  generateAttributes(attributes) {
    return attributes.map((item, index) => {
      return (
        <Input key={item.id} {...item} />
      )
    })
  }

  showCreateModal(user){
    const { t } = this.props;
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      return (
        <>
          <ButtonToolbar className="button float-right">
            <Button variant="primary" onClick={() => this.setState({modalShow: true})}>
              {t("translation:general>create")} {t("translation:general>cause").toLowerCase()} <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
            </Button>
          </ButtonToolbar>

          <Modal show={this.state.modalShow} onHide={this.modalClose}>
            <CreateCauses/>
          </Modal>
        </>
      );
    }
  }

  generateCatastroCards = () => {
    if (this.props.catastros.data) {
      const catastros = this.props.catastros.data;
      return catastros.map(catastro => {
        return (
          <CatastroCard catastro={catastro}/>
        )
      })
    }
  }

  emptyAlertTable = () => {
      const { t } = this.props;
      return (
      <Col className="pl-2" xs="7" md="3">
        <h5>
          {t("translation:emptyTable>alert>title")}
        </h5>
        <p className="text-muted">
          {t("translation:emptyTable>alert>message")}
        </p>
        <Link to="/causes">
          <Button className="rounded-0">{t("translation:general>check")} {t("translation:general>causes")}</Button>
        </Link>
      </Col>
    );
  }

  toogleEmptyAlerts = () => {
    const EmptyAlertTable = this.emptyAlertTable;
    if (this.props.alerts.find(a => a.priority === 'alta')) {
      return (
        <AlertTable content={this.props.alerts}/>
      )
    }
    return (
      <Empty type="empty-table" icon="exclamation-triangle">
        <EmptyAlertTable />
      </Empty>
    )
  }

  emptyCatastrosCards = () => {
    const { t } = this.props;
    return (
    <Col className="pl-2" xs="7" md="3">
      <h5>
        {t("translation:emptyTable>cadastre>title")}
      </h5>
      <p className="text-muted">
        {t("translation:emptyTable>alert>message")}
      </p>
      <Link to="/causes">
        <Button className="rounded-0">{t("translation:general>check")} {t("translation:general>causes")}</Button>
      </Link>
    </Col>
  )}

  toogleCatastro = () => {
    const Catastros = this.generateCatastroCards;
    const EmptyCatastros = this.emptyCatastrosCards;
    if (this.props.catastros.total > 0) {
      return (<Catastros/>)
    }
    return (
      <Empty type="empty-table" icon="exclamation-triangle">
        <EmptyCatastros/>
      </Empty>
    )
  }

  render() {
    const ToogleEmptyAlerts = this.toogleEmptyAlerts;
    const ToogleCatastros = this.toogleCatastro;
    const { t } = this.props;
    return (
      <>
        <GreatingBar title={t("translation:general>hello")} subtitle={` ${this.props.user.first_name}`}>
        {this.showCreateModal(this.props.user)}
        </GreatingBar>

        <Container className="pb-5 mh-600">
          <Row>
            <Title bsPrefix="mt-4" title={t("translation:general>alerts")} to="/dashboard/alerts" viewAll={t("translation:dashboard>seeAllAlerts")}/>
            <ToogleEmptyAlerts/>
          </Row>
        </Container>
        <Container>
          <Row>
          <Col className="col-12">
            <div className={`contain-header d-flex align-items-center justify-content-between mt-5`}>
              <h5>{t("translation:general>surveillance")}</h5>
            </div>
          </Col>
          </Row>
          <Row>
            <ToogleCatastros></ToogleCatastros>
          </Row>

          {/* TODO: Implement last activityclassName="ml-1"
          <Row>
            <RecentActivity activity={[]}/>
          </Row>
          */}
        </Container>
      </>
    )
  }
}


const data = state => ({
  user: state.user.user,
  alerts: state.alert.alerts,
  catastros: state.cause.catastros,
});

const actions = dispatch => ({
  getAlerts: () => dispatch({type: GET_ALERTS}),
  getCatastros: (limit = 6, filter = []) => dispatch({type: GET_CATASTROS, payload: {limit, filter}})
});


export default connect(data, actions)(withTranslation()(Dashboard));
