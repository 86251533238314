export const FETCH_CAUSES = 'cause/FETCH_CAUSES';
export const FETCH_CAUSE = 'cause/FETCH_CAUSE';
export const FETCH_CAUSE_INFO = 'cause/FETCH_CAUSE_INFO';
export const FETCH_CAUSE_FULL_INFO = 'cause/FETCH_CAUSE_FULL_INFO';
export const FETCH_SET_CAUSE = 'cause/FETCH_SET_CAUSE';
export const FETCH_SET_CAUSE_INFO = 'cause/FETCH_SET_CAUSE_INFO';
export const FETCH_CAUSE_FILES = 'cause/FETCH_CAUSE_FILES';
export const FETCH_CAUSE_NORMAL_FILES = 'cause/FETCH_CAUSE_NORMAL_FILES';
export const FETCH_CAUSE_CATASTRO_FILES = 'cause/FETCH_CAUSE_CATASTRO_FILES';
export const FETCH_CATASTROS = 'cause/FETCH_CATASTROS';
