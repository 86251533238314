import { put, takeLatest, call } from 'redux-saga/effects';
import Cookies from 'universal-cookie'
import {REMOVE_USER_AUTH} from "./types";
import {TOGGLE_USER_AUTH} from "../../stores/user/types";

const cookies = new Cookies();

function* checkTokenPresence() {
  const {uuid_session} = cookies.getAll();
  if (uuid_session) {
    yield put({type: TOGGLE_USER_AUTH})
  }
}

function* removeToken() {
  try {
    const isRemoved = cookies.remove('uuid_session');
  } catch (err) {
    console.log(err);
  }
}

function* watchRemoveToken() {
  yield takeLatest(REMOVE_USER_AUTH, removeToken);
}

export default [
  checkTokenPresence(),
  watchRemoveToken(),
]
