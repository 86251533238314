import React, {Component} from 'react'
import {connect} from "react-redux";
import {Switch, Route} from 'react-router-dom'
import {Login} from "./pages/login";
import Dashboard from "./pages/dashboard";
import Causes from "./pages/causes";
import Clients from "./pages/clients";
import Societies from "./pages/societies";
import Projects from "./pages/projects";
import Project from "./pages/project";
import Users from "./pages/users";
import Profile from "./pages/profile";
import ProfileConfiguration from "./pages/profile/configuration";
import ClientProfile from "./pages/profile/client";
import SocietyProfile from "./pages/profile/society";
import Cause from "./pages/cause";
import Alerts from "./pages/dashboard/alerts";
import NotFound from './pages/404'
import App from "./App";
import { PublicRoute, PrivateRoute } from "./components/routes";
import { GET_USER } from "./sagas/user/types";

class Routes extends Component {

  componentWillMount() {
    if (this.props.isLoggedIn) {
      this.props.getUser();
    }
  }


  render() {
    return (
      <Switch>
        <PublicRoute path="/" exact component={App}/>
        <PublicRoute extrict path={["/login", "/login/password/forgot", "/login/password/reset"]} component={Login}/>
        <PrivateRoute strict exact path="/dashboard" component={Dashboard}/>
        <PrivateRoute strict exact path="/dashboard/alerts" component={Alerts}/>
        <PrivateRoute exact path="/projects" component={Projects}/>
        <PrivateRoute strict path={["/project/:id/causes",
          "/project/:id/general_information",
          "/project/:id/concessions"]} component={Project}/>
        <PrivateRoute strict exact path="/causes" component={Causes}/>
        <PrivateRoute strict path={["/cause/:id/stages",
          "/cause/:id/info",
          "/cause/:id/documents",
          "/cause/:id/alerts",
          "/cause/:id/titles",
          "/cause/:id/surveillance",
          "/cause/:id/negotiation"]} component={Cause}/>
        <PrivateRoute exact path="/clients" component={Clients}/>
        <PrivateRoute exact path="/societies" component={Societies}/>
        <PrivateRoute exact path="/users" component={Users}/>
        <PrivateRoute exact path="/profile" component={Profile}/>
        <PrivateRoute exact path="/profile/configuration" component={ProfileConfiguration}/>
        <PrivateRoute exact path="/clients/profile/:id" component={ClientProfile}/>
        <PrivateRoute exact path={["/clients/profile/:client_id/society/:society_id",
          "/societies/profile/:society_id"]} component={SocietyProfile}/>
        {/*NotFoundComponent*/}
        <Route path="/error" component={NotFound}/>
      </Switch>
    )
  }
}

const data = state => ({
  isLoggedIn: state.user.isLoggedIn
});

const actions = dispatch => ({
  getUser: () => dispatch({type: GET_USER})
});

export default connect(data, actions)(Routes)
