import React, {Component} from 'react';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {Col, Form, Badge, Modal, Button, Table, Container} from "react-bootstrap";
import { GET_FILES_CAUSE  } from "../../../sagas/file/types";
import { ATTRIBUTE_TYPE } from '../../../config/constants/components';
import attributeValueManager from '../../../helpers/attributeValueManager';

class CreateCauses extends Component {

  state = {
    filteredCause: [],
    changeClient: false,
    task: {},
    attributeValues: [],
    errors: [],
  };

  constructor(props) {
    super(props);
    this.infoTitleTaskCard = this.infoTitleTaskCard.bind(this);
    this.generateAttributes = this.generateAttributes.bind(this);
    this.infoCol = this.infoCol.bind(this);
  }

  checkTaskValue(props, task){
    const { nextTask, next_stage_id } = task;
    const { value, placeholder} = props;
    const response = placeholder.split(' ');
    const nt = nextTask.find(obj => obj.id === value);
    if(nt && !next_stage_id)
    {
      return ( nt.is_default ? response[0] : response[1]);
    }
    else if (nt && next_stage_id){
      return response[1];
    } else {
      return response[0];
    }
  }

  infoCol(props, task) {
      const { t } = this.props;
      let value = attributeValueManager(props) || '-';
      if(props.type === ATTRIBUTE_TYPE.SELECT_TASK && props.value) value = task.nextTask.find(obj => obj.id === value).name;
      if(props.type === ATTRIBUTE_TYPE.CHECK_TASK && props.value) value = this.checkTaskValue(props,task);
      return (
        <Col key={props.id} className="col-12 mb-2 title pb-4" md={6}>
          <span className="mb-2">{t(props.label)}</span>
          <p>{value}</p>
        </Col>
      )
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.attributeValues) !== JSON.stringify(this.props.attributeValues)) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      })
    }
  }

  generateAttributes(attributes, task, errors) {
    return attributes.map((item) => {
      return this.infoCol(item, errors, task);
    })
  }

  handleClick(file_id) {
    const {id} = this.props.cause;
    this.props.getFile(id, file_id);
  }

  generateTable(files) {
    const { t } = this.props;
    return files.map((item) => {
      return(
        <tr>
          <td className="align-middle text-left">
            <p>{t(item.label)}</p>
            <span>{(item.name === "") ? t("translation:general>notEnteredDocument") : item.name}</span>
          </td>
          <td className="align-middle float-right actions">
            <Button
              variant="btn"
              className="btn-link"
              onClick={() => this.handleClick(item.file_id)}
              >
                {t("translation:general>show")}
            </Button>
          </td>
        </tr>
      )
    })
  }

  infoTitleTaskCard() {
    const { attributeValues, errors} = this.props.task;
    const { task, t } = this.props;
    return (
      <Container>
            <Form.Row className="right-content">
              {this.generateAttributes(attributeValues, task, errors)}
            </Form.Row>
          {(this.props.files.length > 0)
              ? (
                <div className="info__customers">
                  <Table hover>
                    <thead>
                      <tr>
                        <th className="bb-0" width="100%">{t("translation:general>name")}</th>
                        <th className="bb-0"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.generateTable(this.props.files)}
                    </tbody>
                  </Table>
                </div>
                )
              : null
          }
      </Container>
    )
  }

  render() {
    const InfoTitleTaskCard = this.infoTitleTaskCard;
    const{ t } = this.props;
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
        <Modal.Header className="d-flex align-items-center" closeButton>
          <Modal.Title>{t(this.props.task.name)}</Modal.Title>
          <Badge className="danger-custom rounded-0 ml-2">{t(this.props.task.description)}</Badge>
        </Modal.Header>
        <Modal.Body>
          {
              (this.props.task.attributeValues)
              ? <InfoTitleTaskCard isParent={true} {...this.props.task}/>
              : null
            }
        </Modal.Body>
      </Modal>
    );
  }
}

const actions = dispatch => ({
  getFile: (cause_id, file_id) => dispatch({type: GET_FILES_CAUSE, payload: {cause_id, file_id}})
});


const data = state => ({
  task: state.task.taskInfo,
  files: state.task.task.hasOwnProperty('files')?
    state.task.task.files
    : [],
  cause: state.cause.cause,
  causeInfo: state.cause.causeInfo,
  alertByCause: state.alert.alertByCause,
});

export default connect(data, actions)(withTranslation()(CreateCauses));
