import React, {Component} from 'react';
import {connect} from "react-redux";
import {Col, Row, Form, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { GET_USERS_BY_CLIENT_SOCIETY, SET_PERMISSION, GET_TYPES, GET_USERS } from "../../sagas/user/types";
import { USER_TYPES } from '../../config/constants/user'
import { GET_CAUSE_INFO } from "../../sagas/cause/types";

class AddUser extends Component {
  constructor(props){
    super(props);
    const { t } = props;
    this.state = {
      users: [],
      label: t("translation:general>userType"),
      content: [],
    };
  }
  componentWillMount(){
    //this.props.getUsersClientsSocieties(this.props.client_society_id);
    this.props.getUsers();
    this.props.getTypes();
    this.props.getCauseInfo(this.props.cause_id);
  }
  componentDidUpdate(prevProps){
    if(prevProps.types !== this.props.types && this.props.types ){
      const types = this.props.types.filter(obj => (obj.name === USER_TYPES.ADMIN || obj.name === USER_TYPES.CLIENT || obj.name === USER_TYPES.SUPER_ADMIN || obj.name === USER_TYPES.VISUALIZER));
      this.setState({
          content:[...this.state.content, ...types],
      });
    }
  }
  handleChangeUser(e) {
    this.setState({[e.target.name]: e.target.value});
  }
  handleChangeCheckBox(e){
        const usersByType = this.props.users.filter(obj => obj.type === e.target.value);
        usersByType.map(obj => {
          return Object.defineProperty(obj, 'user_client_id', Object.getOwnPropertyDescriptor(obj, 'id'));
        });
        const list = []
        for (let item of usersByType){
          let aux = true
          for (let obj of this.props.causeInfo.users){
            if (obj.id === item.id){
              aux = false
            }
          }
          if (aux){
            list.push(item)
          }
        }
        this.setState({ users: list });
  };

  handleSubmitUser(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const obj = Object.fromEntries(data)
    obj.causeId = this.props.cause_id;
    this.props.setPermission(obj);
    this.props.closeModal();
  }
  render() {
    const {label, content} = this.state;
    const { t } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSubmitUser(e)}>
          <Row className="pt-3 pb-4">
            <Col className="col-12" xl={5}>
                <div className="title p-2">
                  <h4>{t("translation:modal>addUser>title")}</h4>
                  <p>{t("translation:modal>addUser>subtitle")}</p>
                </div>
            </Col>
            <Col className="col-12" xl={7}>
              <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                as="select"
                className="text-capitalize"
                name="typeId"
                required
                onChange={e => this.handleChangeCheckBox(e)}>
                  <option value="">{t("translation:form>selectUserType")}</option>
                  {content.map(option => (
                    <option key={`type-${option.id}`} id={option.id} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("translation:form>selectUser")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="userId"
                  required
                  onChange={e => this.handleChangeUser(e)}>
                   <option value="">{t("translation:form>selectUserType")}</option>
                  {this.state.users.map((el, i) =>
                    <option key={i} value={el.user_client_id}>{el.first_name} {el.last_name}</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>create")}</Button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
  }
}

const data = state => ({
  usersClientSociety: state.user.usersClientSociety,
  users: state.user.users,
  types: state.user.types,
  causeInfo: state.cause.causeInfo,
});
const actions = dispatch => ({
  setPermission: (body) => dispatch({type: SET_PERMISSION, payload: body}),
  getUsersClientsSocieties: (client_society_id) => dispatch({type: GET_USERS_BY_CLIENT_SOCIETY, payload: client_society_id}),
  getTypes: () => dispatch({type: GET_TYPES}),
  getUsers: () => dispatch({type: GET_USERS}),
  getCauseInfo: (id) => dispatch({type: GET_CAUSE_INFO, payload: id}),
});

export default connect(data, actions)(withTranslation()(AddUser));
