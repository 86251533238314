import React, { Component } from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { Row, Card, Button, Table, Modal, Col } from 'react-bootstrap';
import { GET_PAYMENT, FILL_PAYMENT } from "../../../sagas/payment/types";
import { SET_ALERTS_BY_CAUSE } from "../../../sagas/alert/types";
import Input from '../input.causes';
import UploadModal from './upload.payment';
import Loading from '../../loading';
import { USER_TYPES } from '../../../config/constants/user';
import { ATTRIBUTE_TYPE } from '../../../config/constants/components'
import attributeValueManager from '../../../helpers/attributeValueManager';
import { BadgeStates } from '../../../resolver/cause/surveillance';

function Files(props) {
  if (props.files.length > 0) {
    const { t } = props;
    const files = props.files.map(item => {
      return (
        <tr>
          <td className="align-middle text-left">
            <p>{(item.name === "") ? t(item.label) : item.name}</p>
            <span>{(item.name === "") ? "" : t(item.label)}</span>
          </td>
          <td className="align-middle text-left">
            <BadgeStates state={item.state}/>
          </td>
        </tr>
        )
    })
    return (
      <Card className="info__customers mt-3">
        <Table>
          <thead>
            <tr>
              <th>
                {t("translation:general>document")}
              </th>
              <th>
                {t("translation:general>state")}
              </th>
            </tr>
          </thead>
          <tbody>
            {files}
          </tbody>
        </Table>
      </Card>
    )
  }
  return (<React.Fragment/>)
}

function Inputs(props) {
  if (props.attributeValues) {
    return props.attributeValues.map((item) => {
      return (<Input
        handleChange={props.handleChange}
        handleDateChange={props.handleDateChange}
        {...item}
        errors={props.errors}/>)
    });
  }
  return (<React.Fragment/>)
}

function InfoCol(props) {
  const task = props.taskPayment;
  if (props.attributeValues) {
    const { t } = props;
    if (t) {
      return props.attributeValues.map((item) =>
      {
      let value = item.value || '-';
      if (item.type === ATTRIBUTE_TYPE.SELECT_TASK && item.value) value = task.nextTask.find(obj => obj.id === value).name;
      else if (item.type === ATTRIBUTE_TYPE.CHECK_TASK && item.value) value = checkTaskValue(item, task);
      else value = attributeValueManager(item);
      return (
        <Col key={item.id} className="col-12 mb-2 title" md={6}>
          <span className="mb-2">{t(item.label)}</span>
          <p>{value}</p>
        </Col>
      )}
    )
    }
  }
  return (<React.Fragment/>)
}

function checkTaskValue(props, task) {
  const { nextTask, next_stage_id } = task;
  const { value, placeholder } = props;
  const response = placeholder.split(' ');
  const nt = nextTask.find(obj => obj.id === value);
  if(nt && !next_stage_id)
  {
    return ( nt.is_default ? response[0] : response[1]);
  }
  else if (nt && next_stage_id){
    return response[1];
  } else {
    return response[0];
  }
}

class CustomModal extends Component {
  state = {
    attributeValues: [],
    errors: [],
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.attributeValues) !== JSON.stringify(this.state.attributeValues)) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      })
    }
  }

  handleUpload = () => {
    this.props.getPayment(this.props.taskPayment.id)
  }

  handleDateChange = (date, id) => {
    try {
      const { attributeValues } = this.state;
      const { cause } = this.props;
      const idx = attributeValues.findIndex(e => e.id === id);

      if (idx !== -1 && attributeValues[idx].type.includes('alarm_date')) {
        this.props.setAlert(cause.id, date, id);
      }
      attributeValues[idx].value = date;
      this.setState({
        attributeValues,
        cause
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e) => {
    const attributeValues = this.state.attributeValues;
    const cause = this.props.cause;
    const {name, value} = e.target;
    const idx = attributeValues.findIndex(e => e.id === name);

    attributeValues[idx].value = value;
    this.setState({
      attributeValues,
      cause
    })
  }

  render() {
    const type = this.props.user.type || '';
    if (this.props.taskPayment) {
      const file = (this.props.taskPayment.files) ? this.props.taskPayment.files[0] : {};
      const { t } = this.props;
      return (
        <Modal
          show={this.props.modalShow}
          onHide={this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("translation:general>proportionalPatent")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="right-content">
            <Row>
              <Col className="col-12" xl={4}>
                <div className="title p-2">
                  <h4>{t(this.props.taskPayment.name)}</h4>
                  <p className="text-muted">{t(this.props.taskPayment.description)}</p>
                </div>
              </Col>
              <Col className="col-12" xl={8}>
                {(type.includes(USER_TYPES.SUPER_ADMIN) || type.includes(USER_TYPES.ADMIN))
                  ? <Inputs
                      attributeValues={this.props.taskPayment.attributeValues}
                      handleChange={this.handleChange}
                      handleDateChange={this.handleDateChange}
                      errors={this.state.errors}/>
                  : <InfoCol
                      task={this.props.taskPayment}
                      attributeValues={this.props.taskPayment.attributeValues}/>
                }
              </Col>
              <Col xs={{span: 8, offset: 4}}>
                <Files t={t} files={this.props.taskPayment.files || []}/>
                {(type.includes(USER_TYPES.SUPER_ADMIN) || type.includes(USER_TYPES.ADMIN))
                  ? (<UploadModal
                  title={t("translation:modal>documents>title")}
                  subtitle={t("translation:modal>documents>subtitle")}
                  closeModal={this.handleUpload}
                  {...this.props}
                  file={file}/>
                  )
                  : <React.Fragment></React.Fragment>
                }
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded-0" onClick={() => this.props.saveTask(this.state.attributeValues)}>
              <Loading title={t("translation:general>save")}/>
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (<React.Fragment/>);
    }
  }
}

const actions = dispatch => ({
  fillPayment: (task) => dispatch({type: FILL_PAYMENT, payload: task}),
  getPayment: (taskId) => dispatch({type: GET_PAYMENT, payload: taskId}),
  setAlert: (causeId, startDate, attributeValueId) => dispatch({type: SET_ALERTS_BY_CAUSE, payload: {
    causeId,
    startDate,
    attributeValueId,
  }}),
});

const data = state => ({
  payment: state.stage.payment,
  attributeValues: state.task.taskPayment.hasOwnProperty('attributeValues')
  ? state.task.taskPayment.attributeValues
  : [],
  cause: state.cause.cause,
  tasks: state.stage.payment.tasks,
  taskPayment: state.task.taskPayment,
  user: state.user.user,
});

export default connect(data, actions)(withTranslation()(CustomModal));
