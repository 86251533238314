import {FETCH_ALERT_BY_CAUSE, FETCH_ALERTS, SET_ALERT_BY_CAUSE} from "./types";

const initial = {
  alerts: [],
  alertByCause: []
};

export default (state = initial, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_ALERTS:
      return {...state, alerts: payload};
    case FETCH_ALERT_BY_CAUSE:
      return {...state, alertByCause: payload};
      case SET_ALERT_BY_CAUSE:
        return {...state, alertByCause: payload};
    default:
      return state;
  }
}
