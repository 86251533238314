import axios from './config';

export const getRegion = async () => await axios.get('/b/p/regions',
  {
    headers: {
      service: "REGION"
    }
  });

export const getProvince = async () => await axios.get('/b/p/provinces',
  {
    headers: {
      service: "REGION"
    }
  });

export const getCommune = async () => await axios.get('/b/p/communes',
  {
    headers: {
      service: "REGION"
    }
  });

export const getCourt = async () => await axios.get('/b/p/courts',
  {
    headers: {
      service: "REGION"
    }
  });
