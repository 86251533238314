import React, {Component} from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, Button, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Information from '../information';
import Avatar from '../avatar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import AddUser from './addUser.cause'
import { GET_CAUSE_INFO, GET_CAUSE_CATASTRO_FILES } from "../../sagas/cause/types";
import { GET_FILES_CAUSE  } from "../../sagas/file/types";
import { USER_TYPES } from '../../config/constants/user';
import { DOCUMENT } from '../../config/constants/states';
import { SEND_NOTIFICATION } from '../../sagas/notificator/types';
import NotModal from '../../components/modal.notification';
import CustomModal from '../../components/modal';
import {GET_ALERTS_BY_CAUSE} from "../../sagas/alert/types";

const PATH = {
  STAGE: '/cause/:id/stages',
  INFO: '/cause/:id/info',
  ALERTS: '/cause/:id/alerts',
  DOCUMENTS: '/cause/:id/documents',
  TITLES: '/cause/:id/titles',
  SURVEILLANCE: '/cause/:id/surveillance',
  NEGOTIATION: '/cause/:id/negotiation',
};

function NavLink(props) {
  return (
    <Nav.Item as="li">
      <Link {...props}>{props.name}</Link>
    </Nav.Item>
  )
}

function usersAttached(users) {
  return users.map(user => {
    return (
      <Avatar key={user.id} {...user}/>
    )
  })
}

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.showCatastro = this.showCatastro.bind(this);
  }

  state = {
    isActive: [],
    modalShow: false,
    notModalShow: false,
  }

  componentWillMount() {
    const {path} = this.props.match;
    this.setState({
      isActive: [
        (path === PATH.STAGE),
        (path === PATH.INFO),
        (path === PATH.ALERTS),
        (path === PATH.DOCUMENTS),
        (path === PATH.SURVEILLANCE),
        (path === PATH.TITLES),
        (path === PATH.NEGOTIATION),
      ],
  })
  this.props.getCauseCatastroFiles(this.props.match.params.id,{ limit: 1, pagination: 1});
  this.modalClose = this.modalClose.bind(this);
  }

  modalClose(){
    this.setState({ modalShow: false });
  }

  notModalClose = () => {
    this.setState({ notModalShow: false })
  }

  notModalShow = () => {
    this.setState({ notModalShow: true })
  }

  generateInformation() {
    if (this.props.causeInfo.attributes) {
      const { t } = this.props;
      return this.props.causeInfo.attributes.map(attribute => (
        <Information {...attribute} title={t(attribute.label)} value={attribute.value} cause={this.props.causeInfo.type} />
      ));
    }
  }

  handleClick(isActive) {
    this.props.getAlerts(this.props.case.id);
    this.setState({isActive});
  }

  showAddUserAction(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN || type === USER_TYPES.ADMIN ){
      const { t } = this.props;
      return (
        <>
        <OverlayTrigger
          className="red-tooltip"
          placement="left" overlay={<Tooltip id="tooltip-disabled">{t("translation:general>add")} {t("translation:general>user")}</Tooltip>}>
          <Button className="add rounded-circle" onClick={() => this.setState({ modalShow: true })}>
            <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
          </Button>
        </OverlayTrigger>
        <CustomModal show={this.state.modalShow} onHide={this.modalClose}>
          <AddUser cause_id={this.props.causeInfo.id} client_society_id={this.props.causeInfo.client_society_id} closeModal={this.modalClose}/>
        </CustomModal>
        </>
      )
    }
  }

  showCatastro() {
    if (this.props.causeCatastroFiles.data) {
      const lastCatastro = (this.props.causeCatastroFiles.data.length > 0) ? this.props.causeCatastroFiles.data[0] : null;
      if (lastCatastro) {
        const { t } = this.props;
        const state = this.catastroState(lastCatastro.state);
        return (
          <Col xs={4} lg="auto">
            <p className="small">{t("translation:general>cadastre")}</p>
            <Button
              onClick={() => {
                this.props.getFile(this.props.match.params.id, lastCatastro.file_id);
              }}
              variant="link"
              size="sm"
              style={{color:'white', textDecoration: 'none'}}>
              <FontAwesomeIcon className="fa-xs mr-2" style={{color: state.color}} icon={['fas', 'circle']}/>
              {t(state.label)}
            </Button>
          </Col>
        )
      } else {
        return null;
      }
    } else return null;
  }

  catastroState(state) {
    const { t } = this.props;
    switch (state) {
      case DOCUMENT.CATASTRO.UPDATE.NUMBER:
      case DOCUMENT.CATASTRO.UPLOADED.NUMBER:
        return { color: DOCUMENT.COLOR.GREEN, label: t("translation:states>surveillance>uploaded") };

      case DOCUMENT.CATASTRO.OPOSICION.NUMBER:
        return { color: DOCUMENT.COLOR.RED, label: t("translation:states>surveillance>opposition") };

      case DOCUMENT.CATASTRO.SUPERPOSICION.NUMBER:
        return { color: DOCUMENT.COLOR.RED, label: t("translation:states>surveillance>overlap") };

      case DOCUMENT.CATASTRO.ABARCAMIENTO.NUMBER:
        return { color: DOCUMENT.COLOR.RED, label: t("translation:states>surveillance>outreach") };

      case DOCUMENT.CATASTRO.ENLARGMENT.NUMBER:
        return { color: DOCUMENT.COLOR.RED, label: t("translation:states>surveillance>enlargment").split(' ').shift() };

      case DOCUMENT.CATASTRO.REDUCTION.NUMBER:
        return { color: DOCUMENT.COLOR.RED, label: t("translation:states>surveillance>reduction").split(' ').shift() };

      default:
        return { color: DOCUMENT.COLOR.YELLOW, label: t("translation:states>surveillance>empty") };
    }
  }

  notificationButton = () => {
    const { user } = this.props;
    const type = user.type ? user.type.trim() : '';
    const { t } = this.props;
    if (type === USER_TYPES.CLIENT) {
      return (
        <React.Fragment>
          <Button
            className="rounded-0"
            variant="info"
            onClick={() => this.notModalShow()}>
              {t("translation:form>enter")} {t("translation:general>request")}
          </Button>
          <NotModal onHide={this.notModalClose} show={this.state.notModalShow}/>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }


  render() {
    const Catastro = this.showCatastro;
    const {params} = this.props.match;
    const hasTitle = (this.props.title.name);
    const hasNegotiation = (this.props.negotiation.name);
    const NotButton = this.notificationButton;
    const owner = this.props.causeInfo.society || this.props.causeInfo.client;
    const { t } = this.props;
    return (
      <div className="header text-white d-flex align-items-center">
        <Container>
          <Row>
            <Col>
              <div className="d-flex justify-content-end">
                {/* Aca agregar componentes de button */}
                <NotButton/>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="align-self-center" md={12} lg={3} xl={4}>
              <h3 className="d-inline-block title"><span
                className="d-inline-block font-weight-light">{`${owner} `}</span> {` / ` } {t(`glosary:${this.props.causeInfo.name}`)}
              </h3>
              {/*<Badge variant="primary" pill className="ml-2">{cause.mineral}</Badge>*/}
              <p className="mt-2 description">{t(`glosary:${this.props.causeInfo.description}`)}</p>
            </Col>
            <Col sm={12} md={8} lg={7} xl={6} className="align-self-center">
              <Row className="mt-1 header__infocausa">
                <Catastro/>
                {this.generateInformation()}
              </Row>
            </Col>
            <Col className="align-self-center header__users mt-2" sm={12} md={4} lg={2} xl={2}>
              <Row className="d-flex align-items-center">
                <Col xs={8}>
                  {this.props.causeInfo.hasOwnProperty('users')
                    ? usersAttached(this.props.causeInfo.users)
                    : []}
                </Col>
                <Col xs={4}>
                  {this.showAddUserAction(this.props.user)}
                </Col>
              </Row>
            </Col>

            <Col className="col-12 mt-3">
              <Nav as="ul" className="header__subnav">

                <NavLink className={`nav-link ${(this.state.isActive[0]) ? "active" : ""}`}
                         to={`/cause/${params.id}/stages/`} id="nav_stages"
                         name={t("translation:general>stages")}
                         onClick={(e) => this.handleClick([true, false, false, false, false, false, false])}/>

                <NavLink className={`nav-link ${(this.state.isActive[1]) ? "active" : ""}`}
                         to={`/cause/${params.id}/info/`} id="nav_info"
                         name={t("translation:general>information")}
                         onClick={(e) => this.handleClick([false, true, false, false, false, false, false])}/>

                <NavLink className={`nav-link ${(this.state.isActive[2]) ? "active" : ""}`}
                         to={`/cause/${params.id}/alerts/`} id="nav_alerts"
                         name={t("translation:general>alerts")}
                         onClick={(e) => this.handleClick([false, false, true, false, false, false, false])}/>

                <NavLink className={`nav-link ${(this.state.isActive[3]) ? "active" : ""}`}
                         to={`/cause/${params.id}/documents/`} id="nav_documents"
                         name={t("translation:general>documents")}
                         onClick={(e) => this.handleClick([false, false, false, true, false, false, false])}/>

                <NavLink className={`nav-link ${(this.state.isActive[4]) ? "active" : ""}`}
                         to={`/cause/${params.id}/surveillance/`} id="nav_surveillance"
                         name={t("translation:general>surveillance")}
                         onClick={(e) => this.handleClick([false, false, false, false, true, false, false])}/>
                {(hasTitle)
                  ? (
                  <NavLink className={`nav-link ${(this.state.isActive[5]) ? "active" : ""}`}
                           to={`/cause/${params.id}/titles/`} id="nav_titles"
                           name={t("translation:general>titles")}
                           onClick={(e) => this.handleClick([false, false, false, false, false, true, false])}/>)
                  : null
                }
                {(hasNegotiation)
                  ? (
                      <NavLink className={`nav-link ${(this.state.isActive[6]) ? "active" : ""}`}
                        to={`/cause/${params.id}/negotiation/`} id="nav_negotiation"
                        name={t("translation:general>negotiation")}
                        onClick={(e) => this.handleClick([false, false, false, false, false, false, true])}/>)
                  : null
                }

              </Nav>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

const data = state => ({
  user: state.user.user,
  causeInfo: state.cause.causeInfo,
  task: state.task.task,
  causeCatastroFiles: state.cause.causeCatastroFiles,
  title: state.stage.title,
  negotiation: state.stage.negotiation,
})
const actions = dispatch => ({
  getCauseInfo: (id) => dispatch({type: GET_CAUSE_INFO, payload: id}),
  getFile: (cause_id, file_id) => dispatch({type: GET_FILES_CAUSE, payload: {cause_id, file_id}}),
  getCauseCatastroFiles: (id, pagination) => dispatch({type: GET_CAUSE_CATASTRO_FILES, payload: {id, pagination}}),
  sendNotification: (data) => dispatch({type: SEND_NOTIFICATION, payload: data}),
  getAlerts: (id) => dispatch({type: GET_ALERTS_BY_CAUSE, payload: id}),
});
export default connect(data, actions)(withTranslation()(NavBar))
