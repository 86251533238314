import {takeLatest, call, put, select, all } from 'redux-saga/effects'
import {
  tasks,
  fill_task,
  create_task,
} from "../../network/tasks";
import { cause } from "../../network/causes";
import { FILL_PAYMENT, GET_PAYMENT, CREATE_PAYMENT, GET_TASKS_ATTRIBUTES } from "./types";
import { FETCH_PAYMENT } from "../../stores/stage/types";
import { FETCH_PAYMENT_TASK } from "../../stores/task/types";
import { FETCH_ERROR_STATUS } from '../../stores/errors/types';
import { STATES } from '../../config/constants/states';
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'
import { GENERAL } from '../../config/constants/resources';

const cookies = new Cookies();

function* getTasksById(action) {
  const {payload} = action;
  try {
    const {data} = yield call(tasks, payload);
    return data.data;
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getPayment(action) {
  const task = yield* getTasksById(action);
  yield put({type: FETCH_PAYMENT_TASK, payload: task});
}

function* getPaymentStage() {
  try {
    const getCause = (state) => state.cause.cause;
    const causeStore = yield select(getCause);
    const causeAxios = yield call(cause,  causeStore.id);
    const payment = causeAxios.data.data.stages.flatMap(st => {
      if (st.number === 101) return [st];
      return []
    });
    if (payment[0]) {
      yield put({ type: FETCH_PAYMENT, payload: payment[0] });
      yield* getTasksAttributes();
    }
  } catch(e) {
    console.log(e);
  }
}

function* getTasksAttributes() {
  try {
    const getTasks = (state) => state.stage.payment;
    const payment = yield select(getTasks);
    const paymentTasks = yield all(payment.tasks.map(async t => {
      const {data} = await tasks(t.id);
      return {...data.data, name: t.name};
    }))
    payment.tasks = paymentTasks;
    yield put({ type: FETCH_PAYMENT, payload: payment });
  } catch (e) {
    console.log(e);
  }
}

function* fillPaymentTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const task = yield call(fill_task, payload);
    const {data} = yield call(tasks, task.data.data.id);
    yield put({type: FETCH_PAYMENT_TASK, payload: data.data});
    yield* getPaymentStage();
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    console.log(e);
  }
}

function* createTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const { createTask } = payload;
    const {data} = yield call(create_task, createTask);
    return data.data;
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    if (e.request.status === STATES.STATUS.UNAUTHORIZE) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* createAndShow(action){
  try {
    const createdTask = yield* createTask(action);
    const showTask = yield* getTasksById({payload: createdTask.id});
    yield put({type: FETCH_PAYMENT_TASK, payload: showTask});
  } catch(e) {
    console.log(e);
  }
}

function* watchGetTaskPaymentAttributes() {
  yield takeLatest(GET_TASKS_ATTRIBUTES, getTasksAttributes);
}

function* watchCreateAndShow() {
  yield takeLatest(CREATE_PAYMENT, createAndShow);
}

function* watchFillTitleTask() {
  yield takeLatest(FILL_PAYMENT, fillPaymentTask);
}

function* watchGetPayment() {
  yield takeLatest(GET_PAYMENT, getPayment);
}

export default [
  watchGetTaskPaymentAttributes(),
  watchFillTitleTask(),
  watchCreateAndShow(),
  watchGetPayment(),
];