export const GET_TASKS_BY_ID = 'sagas/tasks/GET_TASKS_BY_ID';
export const GET_TITLE_TASK_BY_ID = 'sagas/tasks/GET_TITLE_TASK_BY_ID';
export const GET_TASKS_FOR_INFO_BY_ID = 'sagas/tasks/GET_TASKS_FOR_INFO_BY_ID';
export const GET_TASKS_BY_STAGE = 'sagas/tasks/GET_TASKS_BY_STAGE';
export const POST_TASK = 'sagas/tasks/POST_TASK';
export const SET_TASK = 'sagas/tasks/SET_TASK';
export const SET_TITLE_TASK = 'sagas/tasks/SET_TASK';
export const FILL_TASK = 'sagas/tasks/FILL_TASK';
export const DELETE_TASK = 'sagas/tasks/DELETE_TASK';
export const FILL_TITLE_TASK = 'sagas/tasks/FILL_TITLE_TASK';
export const CREATE_AND_FILL_TASK = 'sagas/tasks/CREATE_AND_FILL_TASK';
