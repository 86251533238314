import axios from './config';
import { CORE } from '../config/constants/network';

export const projects = async () => await axios.get('/b/p/projects',
  {
    headers: {
      service: "CORE"
    }
  });

export const project = async (id) => await axios.get('/b/p/projects/' + id,
  {
    headers: {
      service: "CORE"
    }
  });

export const create_project = async (project) => await axios.post('/b/p/projects',
  project,
  {
    headers: {
      service: "CORE"
    }
  }
);

export const fill_task = async (obj) => await axios.post('/b/p/projects/task',
  obj,
  {
    headers: {
      service: "CORE"
    }
  }
);

export const add_cause = async (id, causeId) => axios.post(`/b/p/projects/${id}/cause`,
  causeId,
  {
    headers: {
      service: "CORE"
    }
  }
);
