import React, {Component} from 'react';
import {connect} from "react-redux";
import {Col, Row, Form, Button, Card, Container, Modal } from "react-bootstrap";
import { FILL_PROJECT_TASK } from "../../sagas/project/types";
import { PROJECT } from '../../config/constants/states';
import { withTranslation } from "react-i18next";

class AddConcession extends Component{
  handleChange(e){
    this.setState({[e.target.name]: e.target.value});
  }
  handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);
    const aux = Object.fromEntries(data);
    const attributes = Object.entries(aux).map(([key,value]) => {
      const findatt = this.props.concession.attributes.find(obj => obj.id === key);
      return {
        id: findatt.id,
        value,
      }
    });
    const obj = {
      project_information_attribute_id: this.props.concession.id,
      state: PROJECT.COMPLETE,
      attributes,
    }
    this.props.fillProjectTask(obj);
    this.props.modalClose();

  };
  render() {
    const concessions = this.props.concession ? this.props.concession.attributes : [];
    const { t } = this.props;
    return (
      <>
        <Modal.Header closeButton>
          <h5>{`${t("translation:general>add")} ${t("translation:general>concession").toLowerCase()}`}</h5>
        </Modal.Header>
        <Card className="right-content">
          <Container>
            <Row className="pt-2 pb-4">
              <Col xl={12}>
                <form onSubmit={e => this.handleSubmit(e)}>
                  {concessions.map(item => {
                    return <Form.Group>
                        <Form.Label htmlFor={item.id}>{t(item.label)}</Form.Label>
                        <Form.Control onChange={e => this.handleChange(e)} name={item.id} className="rounded-0" placeholder={`${t("translation:general>enter")} ${t(item.label).toLowerCase()}`} required/>
                      </Form.Group>
                  })}
                  <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>add")}</Button>
                </form>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  }
}

const data = state => ({
});

const actions = dispatch => ({
  fillProjectTask: (obj) => dispatch({type: FILL_PROJECT_TASK, payload: obj}),
});

export default connect(data, actions)(withTranslation()(AddConcession));
