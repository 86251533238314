import React, { Component } from 'react'
import {OverlayTrigger, Image, Tooltip} from 'react-bootstrap'
import EMPTY_AVATAR from '../assets/images/avatar.svg'

export default class Avatar extends Component {
  render() {
    const avatar = this.props.avatar || EMPTY_AVATAR;
    const { first_name, last_name } = this.props;
    return (
      <OverlayTrigger
        key={this.props.id}
        className="red-tooltip text-capitalize"
        placement="left"
        overlay={<Tooltip id="tooltip-disabled">{first_name} {last_name}</Tooltip>}>
        <Image
          key={this.props.id}
          className="profile_1"
          src={avatar}
          roundedCircle
          height={(this.props.height !== undefined) ? this.props.height : 60}
          width={(this.props.height !== undefined) ? this.props.height : 60}/>
      </OverlayTrigger>
    )
  }
}
