import {FETCH_REGION, FETCH_PROVINCE, FETCH_COMMUNE, FETCH_COURT, FETCH_RESOURCE} from "./types";

const initial = {
  regions: [],
  provinces: [],
  communes: [],
  courts: [],
};

export default (state = initial, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_REGION:
      return {...state, regions: payload}
    case FETCH_PROVINCE:
      return {...state, provinces: payload}
    case FETCH_COMMUNE:
      return {...state, communes: payload}
    case FETCH_COURT:
      return {...state, courts: payload}
    case FETCH_RESOURCE:
      return {...state, current: payload}
    default:
      return state;
  }
}
