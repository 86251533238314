import {
  FETCH_TASK,
  FETCH_TASK_INFO,
  FETCH_TITLE_TASK,
  FETCH_PAYMENT_TASK,
  FETCH_NEGOTIATION_TASK,
  FETCH_CONCESSION_TASK } from "./types";

const initial = {
  task: {},
  taskInfo: {},
  taskTitle: {},
  taskPayment: {},
  taskNegotiation: {},
  taskConcession: {},
}

export default (state = initial, action) => {
  const {type, payload} = action

  switch (type) {
    case FETCH_TASK:
      return {...state, task: payload}
    case FETCH_TASK_INFO:
      return {...state, taskInfo: payload}
    case FETCH_TITLE_TASK:
      return {...state, taskTitle: payload}
    case FETCH_PAYMENT_TASK:
      return {...state, taskPayment: payload}
    case FETCH_CONCESSION_TASK:
      return {...state, taskConcession: payload}
      case FETCH_NEGOTIATION_TASK:
        return {...state, taskNegotiation: payload}
    default:
      return state;
  }
}
