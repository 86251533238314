import React, { Component } from 'react'
import { connect } from "react-redux";
import { Switch } from 'react-router-dom'
import { NavBar } from '../../components/projects';
import { PrivateRoute } from "../../components/routes";
import Causes from './causes';
import Information from './information';
import Concessions from './concessions';

import { GET_COMMUNES } from "../../sagas/region/types"
import { GET_PROJECT } from "../../sagas/project/types";
import { CAUSE } from '../../config/constants/query';

class Index extends Component {

  componentWillMount() {
    const {id} = this.props.match.params;
    this.props.getProject(id);
    this.props.getCommune();
  }
  render() {
    return (
      <>
        <NavBar {...this.props}/>
        <Switch>
          <PrivateRoute exact path="/project/:id/causes" component={Causes}/>
          <PrivateRoute exact path="/project/:id/concessions" component={Concessions}/>
          <PrivateRoute exact path="/project/:id/general_information" component={Information}/>
        </Switch>
      </>
    )
  }
}

const actions = dispatch => ({
  getProject: (id) => dispatch({type: GET_PROJECT, payload: { id, order: CAUSE.ORDER.LAST_UPDATED }}),
  getCommune: () => dispatch({type: GET_COMMUNES}),
});


const data = state => ({
  project: state.project.project,
});

export default connect(data, actions)(Index);
