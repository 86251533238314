import setTaskName from "./setTaskName";
import {TASK, STAGE} from "../../../config/constants/states";

const {
  COMPLETE_AND_CREATE_NEXT,
  COMPLETE,
} = TASK;

const {
  EMPTY,
  INCOMPLETE_STATE,
  ACTUAL,
  COMPLETE_STATE,
} = STAGE;

export default (cause, prevTask) => {
  let exist = false;
  const task = setTaskName(prevTask);
  const c = cause;
  const stages = c.stages.map(stage => {
    let isComplete = 0;
    stage.tasks = stage.tasks.map(t => {
      isComplete = (t.state === COMPLETE_AND_CREATE_NEXT
        || t.state === COMPLETE)
        ? isComplete + 1
        : isComplete;
      if (t.id === task.id) {
        exist = true;
        return {
          id: task.id,
          name: task.name,
          state: task.state,
          created_at: task.created_at,
        }
      }
      return t;
    });
    stage.state = (isComplete === stage.tasks.length && stage.tasks.length !== 0)
      ? COMPLETE_STATE
      : (stage.id === task.stage.id)
      ? ACTUAL
      : (stage.state === EMPTY)
      ? EMPTY
      : INCOMPLETE_STATE;
    return stage;
  })
  c.stages = stages;
  const taskStage = stages.findIndex(s => {
    return (s.id === task.stage.id)
  });
  if (taskStage < 0) {
    c.stages.push({
      ...task.stage,
      state: ACTUAL,
      tasks: [{
        id: task.id,
        name: task.name,
        state: task.state,
        created_at: task.created_at,
      }],
    });
  } else if (exist === false){
    c.stages[taskStage].tasks.push({
      id: task.id,
      name: task.name,
      state: task.state,
      created_at: task.created_at,
    });
    c.stages[taskStage].state = ACTUAL;
  }
  return c;
}
