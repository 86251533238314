import React, { Component } from 'react'
import { Container, Row, Col, Image, Card} from 'react-bootstrap';
import { connect } from 'react-redux';
import EMPTY_AVATAR from '../../assets/images/avatar.svg'
import { withTranslation } from "react-i18next";

function Information(props) {
  return (
    <Col className="mb-4" xl={6} xs={6}>
      <div className="title p-2">
        <p className="small">{props.title}</p>
        <h6>{props.value}</h6>
      </div>
    </Col>
  )
}

class UserProfile extends Component {
  render() {
    const { user, t } = this.props;
    return (
      <>
      <div className="dasheader text-white d-flex align-items-center">
        <Container>
          <Row>
            <Col bsPrefix="col-6">
              <h3><span>{t("translation:general>myProfile")}</span></h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mtn-3">
        <Row>
          <Col className="col-12">
            <Card className="right-content">
              <Container className="mb-5">
                <Row className="mt-5">
                  <Col xl={1}/>
                  <Col className="col-12" xl={2}>
                    <Image className="ml-4" key={`user-${user.id}`} src={user.avatar || EMPTY_AVATAR} roundedCircle height={60} width={60}/>
                    <div className="title p-2">
                      <h4>{`${user.first_name} ${user.last_name}`}</h4>
                      <p>{t(user.type)}</p>
                    </div>
                  </Col>
                  <Col className="col-12" xl={8}>
                    <Row className="mb-4 mt-4">
                      <Information title={t("translation:general>email")} value={user.email}/>
                      <Information title={t("translation:general>phone")} value={user.phone}/>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
      </>
    )
  }
}

const data = state => ({
  user: state.user.user
});

export default connect(data, null)(withTranslation()(UserProfile));
