import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Col, Row, Form, ProgressBar, Badge } from "react-bootstrap";
import { SET_AVATAR } from "../sagas/avatar/types";
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UPLOAD_INFO } from "../sagas/file/types";
import { DOCUMENT } from '../config/constants/states';

class ModalUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadState: 0,
      uploadMode: 1,
      file: {},
      progess: 0,
    };
  }

  componentWillMount() {
    this.props.setUploadFile({
      progress: 0,
      responseState: DOCUMENT.SUCCESS,
    });
  }

  handleUpload = (e) => {
    this.props.setUploadFile({progress: DOCUMENT.PROGRESS.EMPTY, responseState: DOCUMENT.SUCCESS});
    let file = e.target.files[0];
    this.setState({file: file, progress: DOCUMENT.PROGRESS.EMPTY});

    this.props.setAvatar(
      file,
    );
  }

  handleDropUpload = (files) => {
    if (files.length > 0) {
      this.props.setUploadFile({progress: DOCUMENT.PROGRESS.EMPTY, responseState: DOCUMENT.SUCCESS});
      let file = files[0];
      this.setState({file: file, progress: DOCUMENT.PROGRESS.EMPTY});

      this.props.setAvatar(
        file,
      );
    }
  }

  render() {
    const { progress, responseState } = this.props.uploadState;
    const { t } = this.props;
    if (progress === DOCUMENT.PROGRESS.OK && responseState === DOCUMENT.SUCCESS) this.props.closeModal();
    return (
      <React.Fragment>
        <Form>
          <Row>
            <Col className="col-12" xl={4}>
              <div className="title p-2">
                <h4>{t("translation:modal>avatar>title")}</h4>
                <p>{t("translation:modal>avatar>subtitle")}</p>
                {
                  (progress === DOCUMENT.PROGRESS.ERROR && responseState === DOCUMENT.ERROR)
                    ? <Badge className="danger-custom rounded-0 mt-3">{t("translation:modal>avatar>error")}</Badge>
                    : <React.Fragment/>
                  }
              </div>
            </Col>
            <Col className="col-12 mb-3 mt-2" xl={8}>

              <Col className="col-12 check">
                <Dropzone onDrop={this.handleDropUpload} noClick>
                  {({getRootProps, getInputProps}) => (
                    <section className="container">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Form.Row className="mt-2">
                          <Form.Label htmlFor="select1">{t(this.props.label)}</Form.Label>
                          <Form className="uploader" id="file-upload-form">
                            <input id={`file-upload-${this.props.id}`} type="file" name="file" onChange={this.handleUpload}/>

                            <Form.Label id="file-drag" htmlFor={`file-upload-${this.props.id}`}><img className="hidden" id="file-image" src="#" alt="Preview"/>
                              <div id="start"></div>
                              <FontAwesomeIcon className="fa-2x mb-4 fac" icon={['fas', 'file-upload']}/>
                              <div>
                                <h5>{t("translation:modal>uploadDocument>title")}</h5>
                                <p>{t("translation:modal>uploadDocument>subtitle")}</p>
                                <div className="hidden" id="response"></div>
                                <div id="messages"></div>
                              </div>
                              <ProgressBar
                                key={`progress-${this.props.id}`}
                                className="progress mt-4"
                                id="file-progress"
                                striped
                                animated
                                now={this.props.uploadState.progress}
                                variant={this.props.uploadState.responseState}/>
                              <div>{(this.props.name !== '') ? this.props.name
                                : (this.state.file !== null) ? this.state.file.name : ''}
                              </div>
                            </Form.Label>
                          </Form>
                        </Form.Row>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Col>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}


const data = state => ({
  uploadState: state.file.uploadFile,
});

const actions = dispatch => ({
  setUploadFile: (file) => dispatch({type: UPLOAD_INFO, payload: file}),
  setAvatar: (file) => dispatch({type: SET_AVATAR, payload: file}),
});

export default connect(data, actions)(withTranslation()(ModalUpload));
