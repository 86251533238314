import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import { Col } from 'react-bootstrap'
import { SIZE_COLUMN } from '../../config/constants/various';
import { withTranslation } from "react-i18next";

class DateInput extends Component {

  state = {
    date: '',
  }

  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString()===str;
  }

  componentDidMount() {
    if (this.isIsoDate(this.props.value)) this.setState({ date: new Date(this.props.value) })
  }

  render() {
    const sizeColumn = this.props.sizeColumn || SIZE_COLUMN;
    const { t } = this.props;
    return (
      <Col className="col-12 check mb-4" sm={sizeColumn}>
        <p>{t(this.props.label)}</p>
        <DatePicker
          className="form-control zi-2"
          format='d/M/y'
          dayPlaceholder={t("translation:general>day").toLowerCase()}
          monthPlaceholder={t("translation:general>month").toLowerCase()}
          yearPlaceholder={t("translation:general>year").toLowerCase()}
          onChange={(date) => {
            this.setState({ date })
            this.props.handleDateChange(date, this.props.id)
          }}
          value={this.state.date}
        />
      </Col>
    );
  }
}
export default (withTranslation()(DateInput));
