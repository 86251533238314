import {
  FETCH_CAUSE,
  FETCH_CAUSE_INFO,
  FETCH_CAUSE_FULL_INFO,
  FETCH_CAUSES,
  FETCH_SET_CAUSE,
  FETCH_CAUSE_FILES,
  FETCH_CAUSE_NORMAL_FILES,
  FETCH_CAUSE_CATASTRO_FILES,
  FETCH_SET_CAUSE_INFO,
  FETCH_CATASTROS,
} from "./types";

const initial = {
  causes: [],
  cause: {},
  causeInfo: {},
  causeFiles: [],
  causeNormalFiles: {},
  causeCatastroFiles: {},
  catastros: {},
};

export default (state = initial, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_SET_CAUSE: 
      return {...state, cause: payload};
    case FETCH_SET_CAUSE_INFO: 
      return {...state, causeInfo: payload};
    case FETCH_CAUSES:
      return {...state, causes: payload};
    case FETCH_CAUSE:
      return {...state, cause: payload};
    case FETCH_CAUSE_INFO:
      return {...state, causeInfo: payload}
    case FETCH_CAUSE_FULL_INFO:
      return {...state, causeFullInfo: payload}
    case FETCH_CAUSE_FILES:
      return {...state, causeFiles: payload}
    case FETCH_CAUSE_NORMAL_FILES:
      return {...state, causeNormalFiles: payload}
    case FETCH_CAUSE_CATASTRO_FILES:
      return {...state, causeCatastroFiles: payload}
      case FETCH_CATASTROS:
        return {...state, catastros: payload}
    default:
      return state;
  }
}
