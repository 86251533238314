export const USER_TYPES = {
  ADMIN: 'Admin',
  CLIENT: 'Client',
  SUPER_ADMIN: 'Super-Admin',
  VISUALIZER: 'Visualizer',

}

export const EMPTY_AVATAR = 'http://placehold.it/80x80';
export const NO_CLIENT = '0';
export const NO_SOCIETY = '0';
