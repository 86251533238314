import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function LanguageButton() {
  const { t, i18n } = useTranslation();

  function handleClick(lang) {
    localStorage.setItem('lang',lang);
    i18n.changeLanguage(lang);
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className="rounded-0 ml-1" id="dropdown-basic">
        {t("translation:languageButton>language")}
      </Dropdown.Toggle>

      <Dropdown.Menu className="rounded-0">
        <Dropdown.Item onClick={() => handleClick("es")}>
          {t("translation:languageButton>spanish")}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleClick("en")}>
          {t("translation:languageButton>english")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageButton;
