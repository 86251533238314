import axios from './config';

export const fileUpload = async (file, causeId) => {
  let formdata = new FormData();
  formdata.append('file', file);
  formdata.set('resource_id', causeId);
  formdata.set('fileName', 'file')

  const response = await axios.post('/b/p/file/upload', formdata, {
    headers: {
      service: "FILE",
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const fileUrlUpload = async (file) => await axios.post('/b/p/address', file, {
    headers: {
      service: "FILE",
    },
  });

export const getFiles = async (file) => axios.post('/b/p/file/obtain',{
    ...file
  },
  {
    headers: {
      service: "FILE",
    },
    responseType: 'blob',
  });
