import React, {Component} from 'react';
import {connect} from "react-redux";
import {Col, Row, Form, Button} from "react-bootstrap";
import {CREATE_CLIENT} from "../../sagas/client/types";
import { withTranslation } from "react-i18next";
import {
  NAME,
  RUT,
  PHONE,
  ADDRESS,
  EMAIL,
} from "../../config/constants/formAttributes"

const attributes = [
  NAME,
  RUT,
  PHONE,
  ADDRESS,
  EMAIL,
]

class CreateClient extends Component {
  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    this.props.createClient(Object.fromEntries(data));
    this.props.closeModal();
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSubmit(e)}>
        <Row className="pt-3 pb-4">
        <Col className="col-12" xl={5}>
            <div className="title p-2">
              <h4>{t("translation:modal>clients>title")}</h4>
              <p>{t("translation:modal>clients>subtitle")}</p>
            </div>
        </Col>
        <Col className="col-12" xl={7}>
        {attributes.map(item => {
          return <Form.Group>
              <Form.Label htmlFor={item.id}>{t(`translation:form>${item.label}`)}</Form.Label>
              <Form.Control onChange={e => this.handleChange(e)} className="rounded-0" {...item} placeholder={`${t("translation:form>enter")} ${t(`translation:form>${item.label}`).toLowerCase()}`}  required/>
            </Form.Group>
        })}
          <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>create")}</Button>
        </Col>
      </Row>
        </form>
      </React.Fragment>
    );
  }
}

const actions = dispatch => ({
  createClient: (client) => dispatch({type: CREATE_CLIENT, payload: client})
});

export default connect(null, actions)(withTranslation()(CreateClient));
