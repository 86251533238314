import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default class InfoBar extends Component {
  constructor(props){
    super(props);
  }
  
  render () {
    return (
      <div className="dasheader text-white d-flex align-items-center">
        <Container>
          <Row>
            <Col bsPrefix="col-6">
              <h3><span>{this.props.title}</span>{this.props.subtitle}</h3>
            </Col>
            <Col bsPrefix="col-6">
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
