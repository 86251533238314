import React, { Component } from 'react'
import { Col, Form, Button, ProgressBar } from 'react-bootstrap'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {UPLOAD_FILE} from "../sagas/file/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from './dropzone';

const UPLOADED = 100;
const SEND = 75;

class inputBox extends Component {
  state = {
    file: null,
    progress: 0,
  }

  onChange(e) {
    let file = e.target.files[0];
    this.setState({file: file, progress: 0});
  }

  handleUpload(e) {
    this.props.uploadFile(
      this.state.file,
      this.props.task,
      this.props.id,
    );

    this.setState({progress: SEND});
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      const file = prevProps.task.files.find(f => f.id === prevProps.id);
      if (JSON.stringify(prevState.file) === JSON.stringify(this.state.file)) {
        if(prevState.progress < UPLOADED && file.name === prevState.file.name) {
          this.setState({ progress: UPLOADED })
        }
      }
    } catch(e) {
      console.log(e)
    }
  }

  render() {
    const { t } = this.props;
    return(
      <Col className="col-12 check">
        <Form.Row className="mt-2">
          <Form.Label htmlFor="select1">{t(this.props.label)}</Form.Label>
          <Form className="uploader" id="file-upload-form">
            <input id={`file-upload-${this.props.id}`} type="file" name="file" onChange={(e) => this.onChange(e)}/>

            <Form.Label id="file-drag" htmlFor={`file-upload-${this.props.id}`}><img className="hidden" id="file-image" src="#" alt="Preview"/>
              <div id="start"></div>
              <FontAwesomeIcon className="fa-2x mb-4 fac" icon={['fas', 'file-upload']}/>
              <div>
                <h5>{t("translation:modal>uploadDocument>title")}</h5>
                <p>{t("translation:modal>uploadDocument>subtitle")}</p>
                <div className="hidden" id="response"></div>
                <div id="messages"></div>
              </div>
              <ProgressBar key={`progress-${this.props.id}`} className="progress mt-4" id="file-progress" now={this.state.progress}/>
              <div>{(this.props.name !== '') ? this.props.name
                : (this.state.file !== null) ? this.state.file.name : ''}
              </div>
              <Dropzone/>
            </Form.Label>
            <Button variant="primary rounded-0 btn-block text-center mt-3" onClick={async (e) => this.handleUpload(e)}>{t("translation:general>load")}</Button>
          </Form>
        </Form.Row>
      </Col>
    )
  }
}

const data = state => ({
  files: state.files,
  task: state.task.task,
});

const actions = dispatch => ({
  uploadFile: (file, task, file_id) => dispatch({type: UPLOAD_FILE, payload: {file, task, file_id}}),
})


export default connect(data, actions)(withTranslation()(inputBox))
