import React, {Component} from 'react';
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import { InfoCard, SideBar, TaskCard } from '../../../components/causes';
import '../../../css/app.css'
import { USER_TYPES } from '../../../config/constants/user';
import {GET_ALERTS_BY_CAUSE} from "../../../sagas/alert/types";

class Cause extends Component {
  componentDidMount() {
    if (this.props.cause.id) {
      /* this.props.getAlerts(this.props.cause.id); */
    }
  }

  userTypeShow(user){
    if(user.type){
      const type = user.type.trim();
      if(type === USER_TYPES.CLIENT || type === USER_TYPES.VISUALIZER){
        return <InfoCard isParent={true} {...this.props}/>;
      } else {
        return <TaskCard isParent={true} {...this.props}/>;
      }
    }
  }

  render() {
    return (
      <Container className="pb-5">
        <Row className="mtn-5">
          <SideBar {...this.props}/>
          {this.userTypeShow(this.props.user)}
        </Row>
      </Container>
    )
  }
}

const data = state => ({
  user: state.user.user,
  cause: state.cause.cause,
});

const actions = dispatch => ({
  getAlerts: (id) => dispatch({type: GET_ALERTS_BY_CAUSE, payload: id}),
})

export default withRouter(connect(data, actions)(Cause));
