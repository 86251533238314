import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Button, Modal, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tables, Avatar } from '../../../components';
import AddUser from '../../../components/users/add.user';
import Input from '../../../components/causes/input.causes';
import { GET_SOCIETY } from "../../../sagas/society/types";
import { GET_CLIENT } from "../../../sagas/client/types";
import { GET_USERS } from "../../../sagas/user/types";
import { GENERAL } from '../../../config/constants/tableTitles';
import { PATH } from '../../../config/constants/paths';
import { USER_TYPES } from '../../../config/constants/user';
import SmallEmpty from '../../../components/smallEmptyCard';
import Empty from '../../../components/mediumEmptyCard';
import { withTranslation } from "react-i18next";

const titlesUsers = [];

function Information(props) {
  return (
    <Col className="mb-2 left-content__title" xl={12} xs={6}>
      <div className="p-2">
        <p className="small">{props.title}</p>
        <h6>{props.value}</h6>
      </div>
    </Col>
  )
}

class SocietyProfile extends Component {
  constructor(props){
    super(props);
    this.state={
      modalShow: false,
      modalAddUser: false,
      user: {},
    }
    this.modalClose = this.modalClose.bind(this);
  }
  modalClose(){
    this.setState({ modalShow: false, modalAddUser: false, });
  }
  componentWillMount(){
    const {client_id, society_id} = this.props.match.params;
    this.props.getUsers();
    if (!client_id){
      this.props.getSociety(society_id);
    } else{
      this.props.getClient(client_id);
    }
    // this.props.getSociety();
  }
  generateAttributes(attributes) {
    return attributes.map((item, index) => {
      return (
            <Input key={item.id} {...item} />
          )
    })
  }

  generateAvatars(users) {
    const avatars = users.map(user =>{
      return(
        <Avatar key={user.id} height={40} {...user}/>
      )
    })
    return avatars;
  }
  handleClickUser(item, e){
    this.setState({modalShow: true, user: item})
  }
  generateUserTable(content){
    const { t } = this.props;
    const element = content.map(aux => {
      const users = this.props.users ? this.props.users : [];
      const item = users.length > 0 ? users.find(obj => obj.id === aux.user_client_id) : [];
      if(item){
        return (
          <tr key={item.id} id={item.id} onClick={(e) => this.handleClickUser(item, e)}>
            <td><Avatar height={30} {...item}/></td>
            <td>
              <p className='text-capitalize'>{`${item.first_name} ${item.last_name}`}</p><span>{item.email}</span>
            </td>
            <td className="align-middle">
            <h6>
              <Badge className={this.handleType(item)}>
                {t(item.type)}
              </Badge>
            </h6>
            </td>
          </tr>
        )
      }
    })
    return element;
  }
  handleType(item) {
    const type = item ? item.type : '';
      switch (type) {
        case USER_TYPES.ADMIN:
          return 'secondary-custom rounded-0';
        case USER_TYPES.SUPER_ADMIN:
          return 'success-custom rounded-0';
        case USER_TYPES.VISUALIZER:
          return 'warning-custom rounded-0';
        default:
          return 'danger-custom rounded-0';
      }
    }
  generateCauseTable(content){
    const { t } = this.props;
    const element = content.map(item => {
      return (
        <tr  key={item.id}>
          <td></td>
          <td>
            <p>{item.name}</p>
            <span>{t(item.type)}</span>
          </td>
          <td className="align-middle">{item.created_at}</td>
          <td className="align-middle">{item.rol}</td>
          <td className="align-middle">{item.judge}</td>
          <td className="align-middle actions">
            <Link className="nav-link text-center" to={`/${PATH.CAUSE}${item.id}/${PATH.STAGES}`}>{t("translation:general>show")}</Link>
          </td>
        </tr>
      )
    });
    return element;
  }

  userModal() {
    const { user } = this.state ;
    const { t } = this.props;
    return (
      <Modal show={this.state.modalShow} onHide={this.modalClose}
        size="sm"
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton>
          <h5>{t("translation:modal>userDetail>title")}</h5>
        </Modal.Header>
        <Card className="right-content">
          <Container>
          <Row className="pt-2 pb-4">
            <Col xl={12}>
                <div className="title p-2">

                  <h4 className='text-capitalize'>{`${user.first_name} ${user.last_name}`}</h4>
                  <Badge className={this.handleType(user)}>{t(user.type)}</Badge>
                </div>
                <Information title={t("translation:modal>userDetail>userType")} value={t(user.type)}/>
                <Information title={t("translation:general>phone")} value={user.phone}/>
                <Information title={t("translation:general>email")} value={user.email}/>
            </Col>
          </Row>
          </Container>
        </Card>
      </Modal>
    )
  }

  showAddUserModal(user) {
    const type = user.type ? user.type.trim() : '';
    const { client_id, society_id } = this.props.match.params;
    const client = this.props.client.societies ? this.props.client : { societies: [], name: '' };
    const societyAux = client.societies.find(obj => obj.id === society_id) || {};
    const society = client_id ? societyAux : this.props.society;
    const { t } = this.props;
    if (type === USER_TYPES.SUPER_ADMIN) {
      return (
        <>
          <Button variant='link' onClick={() => this.setState({modalAddUser: true})}>
            <FontAwesomeIcon className="fa-sm mr-1" icon={['far', 'plus']}/>
            {t("translation:general>add")}
          </Button>
          <Modal show={this.state.modalAddUser} onHide={this.modalClose}>
            <AddUser modalClose={this.modalClose} client_id={client_id} society_id={society.id} representatives={society.representatives}/>
          </Modal>
        </>
      )
    }
  }

  emptyCard = () => {
    const { t } = this.props;
    return (
    <SmallEmpty type="small-empty-table">
      <Col xs="6">
        <h5>
          {t("translation:emptyTable>representatives>title")}
        </h5>
        <p className="text-muted">{t("translation:emptyTable>representatives>subtitleAdmin")}</p>
        <Button
          onClick={() => this.setState({modalAddUser: true})}
          className="rounded-0">
            {t("translation:general>add")}
        </Button>
      </Col>
    </SmallEmpty>
  )}

  toogleEmptyUsers = (representatives) => {
    const EmptyCard = this.emptyCard;
    if (representatives.length > 0) {
      return this.generateUserTable(representatives)
    }
    const { user } = this.props;
    if (user.type) {
      const type = user.type.trim();
      if(type === USER_TYPES.CLIENT || type === USER_TYPES.VISUALIZER) {
        const { t } = this.props;
        return (
          <SmallEmpty type="small-empty-table">
            <Col xs="6">
              <h5>
                {t("translation:emptyTable>representatives>title")}
              </h5>
              <p className="text-muted">{t("translation:emptyTable>representatives>subtitle")}</p>
            </Col>
          </SmallEmpty>
        )
      }
    }
    return (
      <EmptyCard/>
    )
  }

  emptyCauses = () => {
    const { t } = this.props;
    return (
    <Empty type="medium-empty-table">
      <Col xs="4">
        <h5>
          {t("translation:emptyTable>clientSocieties>causes>title")}
        </h5>
        <p className="text-muted">{t("translation:emptyTable>clientSocieties>causes>subtitle")}</p>
        <Link to="/causes">
          <Button className="rounded-0">
              {t("translation:general>causes")}
          </Button>
        </Link>
      </Col>
    </Empty>
  )}

  toogleEmptyCauses = (causes) => {
    const EmptyCauses = this.emptyCauses;
    if (causes.length > 0) {
      const { t } = this.props;
      const titlesCauses = [
        GENERAL.EMPTY,
        t("translation:general>name"),
        t("translation:general>created"),
        t("translation:general>rol"),
        t("translation:general>judge"),
        GENERAL.EMPTY];
      return (
        <Tables titles={titlesCauses}>
          {this.generateCauseTable(causes)}
        </Tables>
      )
    }
    return (
      <EmptyCauses/>
    )
  }

  render() {
    const { client_id, society_id } = this.props.match.params;
    const client = this.props.client.societies ? this.props.client : { societies: [], name: '' };
    const societyAux = client.societies.find(obj => obj.id === society_id) || {};
    const society = client_id ? societyAux : this.props.society;
    const clientName = client_id ? client.name : '' ;
    const messageClientName = clientName ? `${clientName} / ` : ''
    const representatives = society.representatives ? society.representatives : [];
    const causes = society.causes ? society.causes : [];
    const { t } = this.props;
    return (
      <>
      <div className="dasheader text-white d-flex align-items-center">
        <Container>
          <Row>
            <Col bsPrefix="col-6">
              <h3><span>{messageClientName}{society.name}</span></h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="pb-5">
        <Row className="mtn-5">
          <Col className="col-xl-4 col-lg-5 col-12">
            <Card className='info__general'>
            <div className="contain-header d-flex align-items-center title justify-content-between mt-4 pl-4">
              <h5>{t("translation:general>information")}</h5>
            </div>
              <Information title={t("translation:general>taxpayerId")} value={society.taxpayer_id}/>
              <Information title={t("translation:general>address")} value={society.address}/>
              <Information title={t("translation:general>email")} value={society.email}/>
              <Information title={t("translation:general>phone")} value={society.phone}/>
              <div className="contain-header d-flex align-items-center title justify-content-between mt-2 pl-4">
                <h5>{t("translation:general>representatives")}</h5>
                {this.showAddUserModal(this.props.user)}
              </div>
              <Tables titles={titlesUsers}>
                { this.toogleEmptyUsers(representatives)}
                {this.userModal()}
              </Tables>
            </Card>
          </Col>
          <Col className="col-12 col-xl-8 col-lg-7">
          <Card className='info__general right-content'>
            <Row>
              <Col className="col-12">
                <div className="contain-header d-flex align-items-center title justify-content-between mt-4 pl-4">
                  <h5>{t("translation:general>causes")}</h5>
                </div>
              </Col>
            </Row>
            {this.toogleEmptyCauses(causes)}
          </Card>
          </Col>
        </Row>
      </Container>
      </>
    )
  }
}

const data = state => ({
  society: state.society.society,
  client: state.client.client,
  users: state.user.users,
  user: state.user.user,
});


const actions = dispatch => ({
  getClient: (clientId) => dispatch({type: GET_CLIENT, payload: clientId}),
  getSociety: (societyId) => dispatch({type: GET_SOCIETY, payload: societyId}),
  getUsers: () => dispatch({type: GET_USERS}),
});

export default connect(data, actions)(withTranslation()(SocietyProfile));
