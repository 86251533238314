import {takeLatest, call, put} from 'redux-saga/effects';
import {GET_REGIONS, GET_PROVINCES, GET_COMMUNES, GET_COURTS, SET_RESOURCE} from "./types";
import {FETCH_REGION, FETCH_PROVINCE, FETCH_COMMUNE, FETCH_COURT, FETCH_RESOURCE} from "../../stores/region/types";
import {getRegion, getProvince, getCommune, getCourt} from "../../network/regions";

function* getRegions(action) {
  try {
    const {data} = yield call(getRegion);

    yield put({type: FETCH_REGION, payload: data.data});
  } catch (e) {
    console.log(e);
  }
}

function* getProvinces(action) {
  try {
    const {data} = yield call(getProvince);

    yield put({type: FETCH_PROVINCE, payload: data.data});
  } catch(e) {
    console.log(e);
  }
}

function* getCommunes(action) {
  try {
    const {data} = yield call(getCommune);

    yield put({type: FETCH_COMMUNE, payload: data.data});
  } catch(e) {
    console.log(e);
  }
}

function* getCourts(action) {
  try {
    const {data} = yield call(getCourt);

    yield put({type: FETCH_COURT, payload: data.data});
  } catch(e) {
    console.log(e);
  }
}

function* setResource(action) {
  const {payload} = action;
  try {
    yield put({type: FETCH_RESOURCE, payload});
  } catch(e) {
    console.log(e);
  }
}

function* watchGetRegion() {
  yield takeLatest(GET_REGIONS, getRegions);
}

function* watchGetProvince() {
  yield takeLatest(GET_PROVINCES, getProvinces);  
}

function* watchGetCommune() {
  yield takeLatest(GET_COMMUNES, getCommunes);
}

function* watchGetCourt() {
  yield takeLatest(GET_COURTS, getCourts);
}

function* watchSetResource() {
  yield takeLatest(SET_RESOURCE, setResource);
}

export default [
  watchGetRegion(),
  watchGetProvince(),
  watchGetCommune(),
  watchGetCourt(),
  watchSetResource(),
]
