import React, { Component } from 'react'
import { Col, Form, FormCheck } from 'react-bootstrap'
import { withTranslation } from "react-i18next";

class uniqueCheck extends Component {
  state = {
    value: (this.props.value !== "") ? "checked" : "",
  }

  handleChange(e){
    e.target.value = ((this.state.value) ? "" : "checked");
    this.setState({value: e.target.value});
    this.props.handleChange(e);
  }
  render() {
    const { t } = this.props;
    return(
      <Col className="col-12 check" sm={12}>
        <FormCheck.Label htmlFor={`check-unique-${this.props.id}`}>{t(this.props.placeholder)}</FormCheck.Label>
        <Form.Control as="div" className="input-group" id={`check-unique-${this.props.id}`}>
          <div className="input-group-prepend">
            <Form.Check
              className="mt-1"
              name={this.props.id}
              onChange={(e) => {
                this.handleChange(e);
              }}
              value= {this.state.value}
              checked= {(this.state.value !== "")}
              type="checkbox"
            />
            <p className="ml-2 mt-1">{t(this.props.label)}</p>
          </div>
        </Form.Control>
      </Col>
    )
  }
}

export default (withTranslation()(uniqueCheck));
