import {takeLatest, call, put, select, all } from 'redux-saga/effects'
import {
  tasks,
  fill_task,
  create_task,
} from "../../network/tasks";
import { cause } from "../../network/causes";
import { setAlert } from "../../network/alerts";
import { GET_NEGOTIATION, CREATE_AND_FILL_NEGOTIATION, GET_TASKS_ATTRIBUTES } from "./types";
import { FETCH_NEGOTIATION } from "../../stores/stage/types";
import { FETCH_NEGOTIATION_TASK } from "../../stores/task/types";
import { FETCH_ERROR_STATUS } from '../../stores/errors/types';
import { STATES, STAGE } from '../../config/constants/states';
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'
import { GENERAL } from '../../config/constants/resources';

const cookies = new Cookies();

function* getTasksById(action) {
  const {payload} = action;
  try {
    const {data} = yield call(tasks, payload);
    return data.data;
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* getNegotiation(action) {
  const task = yield* getTasksById(action);
  yield put({type: FETCH_NEGOTIATION_TASK, payload: task});
}

function* getNegotiationStage() {
  try {
    const getCause = (state) => state.cause.cause;
    const causeStore = yield select(getCause);
    const causeAxios = yield call(cause,  causeStore.id);
    const negotiation = causeAxios.data.data.stages.find((st) => (st.number === STAGE.NEGOTIATION));
    if (negotiation) {
      yield put({ type: FETCH_NEGOTIATION, payload: negotiation });
      yield* getTasksAttributes();
    }
  } catch(e) {
    console.log(e);
  }
}

function* getTasksAttributes() {
  try {
    const negotiation = yield* getNegotiationStore();
    const negotiationTasks = yield all(negotiation.tasks.map(async (t) => {
      const {data} = await tasks(t.id);
      return data.data;
    }))
    negotiation.tasks = negotiationTasks;
    yield put({ type: FETCH_NEGOTIATION, payload: negotiation });
  } catch (e) {
    console.log(e);
  }
}

function* getNegotiationStore() {
  const getTasks = (state) => state.stage.negotiation;
  return yield select(getTasks);
}

function* createTask(action) {
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.WAITING, resource: GENERAL.DEFAULT }})
    const { payload } = action;
    const { createTask } = payload;
    const negotiation = yield* getNegotiationStore();
    const {data} = yield call(create_task, {
      stage_check_id: negotiation.id,
      task_id: createTask.id,
    });
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: STATES.STATUS.SUCCESS }})
    return data.data;
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: { status: e.request.status }})
    if (e.request.status === STATES.STATUS.UNAUTHORIZE) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* fillNegotiation(action) {
  try {
    const { payload } = action;
    const {data} = yield call(fill_task, payload);
    return data.data;
  } catch(e) {
    console.log(e);
  }
}

function* generateAlert(action) {
  try {
    const getCause = (state) => state.cause.cause;
    const cause = yield select(getCause);
    const { payload } = action;
    if (payload.attributeValue) {
      const dateAttribute = payload.attributeValue.find(att => att.attribute.type.name.includes('alarm_date'));
      const createAlert = {
        causeId: cause.id,
        startDate: dateAttribute.value,
        attributeValueId: dateAttribute.id,
      }
      yield call(setAlert, createAlert);
    }
  } catch(e) {
    console.log(e)
  }
}

function* createAndFill(action){
  try {
    const { payload } = action;
    const createdTask = yield* createTask(action);
    const attributeValues = yield all(createdTask.attributeValues.map(att => {
      const foundAttribute = payload.createTask.attributeValues.find(a => a.label === att.label);
      att.value = foundAttribute.value;
      return att;
    }));
    const taskToFill = {...createdTask, attributeValues, state: payload.createTask.state};
    const filledTask = yield* fillNegotiation({ payload: taskToFill });
    if (payload.createTask.hasAlert) {
      yield* generateAlert({ payload: filledTask });
    }
    const showTask = yield* getTasksById({payload: createdTask.id});
    yield* getNegotiationStage();
    yield put({type: FETCH_NEGOTIATION_TASK, payload: showTask});
  } catch(e) {
    console.log(e);
  }
}

function* watchGetTaskNegotiationAttributes() {
  yield takeLatest(GET_TASKS_ATTRIBUTES, getTasksAttributes);
}

function* watchCreateAndFill() {
  yield takeLatest(CREATE_AND_FILL_NEGOTIATION, createAndFill);
}

function* watchGetNegotiation() {
  yield takeLatest(GET_NEGOTIATION, getNegotiation);
}

export default [
  watchGetTaskNegotiationAttributes(),
  watchCreateAndFill(),
  watchGetNegotiation(),
];