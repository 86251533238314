import React, { Component } from 'react'
import { Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export default class modal extends Component {

  generateButtons(handleChange) {
    const rest = (this.props.total % this.props.per_page > 0) ? 1 : 0;
    const totalPages = this.props.total / this.props.per_page + rest;
    let buttons = [];
    buttons.push(
      <ToggleButton
        variant='light'
        value={this.props.page - 1}
        disabled={(this.props.page - 1 < 1)}
        onChange={() => handleChange(this.props.page - 1)}>
          <FontAwesomeIcon className="fa-sm" icon={['far', 'chevron-left']}/>
      </ToggleButton>
      );

    for (let i = 1; i <= totalPages; i += 1) {
      buttons.push(
        <ToggleButton
          variant='light'
          onChange={() => handleChange(i)}
          value={i}>
            {i}
        </ToggleButton>
      )
    }

    buttons.push(
      <ToggleButton
        variant='light'
        value={this.props.page + 1}
        disabled={(this.props.page + 1 > totalPages)}
        onChange={() => handleChange(this.props.page + 1)}>
          <FontAwesomeIcon className="fa-sm" icon={['far', 'chevron-right']}/>
      </ToggleButton>
      )
    return buttons;
  }

  render() {
    return (
      <Col className='text-center'>
      <ToggleButtonGroup type="radio" className="mt-2" name="pagination" defaultValue={[this.props.page]}>
          {this.generateButtons(this.props.handleChange)}
        </ToggleButtonGroup>
      </Col>
    )
  }
}
