import { FETCH_ERROR, FETCH_ERROR_STATUS } from "./types";

const initial = {
  status: 0,
  hasError: false,
  message: '',
  resource: '',
}

export default (state = initial, action) => {
  const {type, payload} = action

  switch (type) {
    case FETCH_ERROR_STATUS:
      return { ...state, ...payload }
    case FETCH_ERROR:
      return { ...state, hasError: payload }
    default:
      return state;
  }
}
