import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  Badge,
  FormControl
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RESET_PASSWORD } from "../../../../sagas/password/types";
import MonttImage from "../../../../assets/images/logo.svg";
import { STATES } from "../../../../config/constants/states";
import { USER } from "../../../../config/constants/resources";
import LanguageButton from "../../../../components/language.button";
import { withTranslation } from "react-i18next";

class ResetPassword extends Component {
  state = {
    new_password: "",
    new_password_confirmation: ""
  };

  handleChange = e => {
    const { name, value } = e.target;
    const passwords = {};

    passwords[name] = value;
    this.setState(passwords);
  };

  validatePasswords = () => {
    const { new_password, new_password_confirmation } = this.state;
    return new_password === new_password_confirmation;
  };

  handleSubmit = e => {
    e.preventDefault();
    const { id } = this.props.match.params;
    const { new_password } = this.state;
    if (this.validatePasswords()) {
      this.props.sendResetPassword({ token: id, password: new_password });
    }
  };

  render() {
    const { error, t } = this.props;
    return (
      <Form className="login__form" onSubmit={this.handleSubmit}>
        <Row className="text-left mb-5">
          <LanguageButton />
        </Row>
        <Row className="text-left mb-3 mt-5">
          <Col>
            <Image className="img-logo" src={MonttImage}/>
          </Col>
        </Row>
        <div className="text-left mt-5 mb-4">
          <h1 className="h3 mb-2font-weight-normal">Recuperar contraseña</h1>
          <h5 className="mb-3 font-weight-normal">
            Ingrese su nueva contraseña
          </h5>
        </div>
        <Form.Group>
          <Form.Label bsPrefix="label" htmlFor="email">
            Ingrese nueva contraseña
          </Form.Label>
          <Form.Control
            as="input"
            className="rounded-0"
            id="password"
            type="password"
            name="new_password"
            onChange={this.handleChange}
            placeholder="Introducir nueva contraseña"
            required=""
          />
        </Form.Group>
        <Form.Group>
          <Form.Label bsPrefix="label" htmlFor="email">
            Repita contraseña
          </Form.Label>
          <Form.Control
            as="input"
            className="rounded-0"
            id="password"
            type="password"
            name="new_password_confirmation"
            onChange={this.handleChange}
            placeholder="Introducir contraseña"
            isInvalid={!this.validatePasswords()}
            required=""
          />
          {this.validatePasswords() ? (
            <React.Fragment />
          ) : (
            <FormControl.Feedback type="invalid">
              La contraseña no coincide
            </FormControl.Feedback>
          )}
        </Form.Group>
        <Button
          variant="primary"
          className="rounded-0 text-center"
          block
          type="submit"
        >
          Restaurar contraseña
        </Button>
        {error.status === STATES.STATUS.SUCCESS &&
        error.resource === USER.PASSWORD ? (
          <Badge className="success-custom rounded-0">
            Contraseña cambiada con exito
          </Badge>
        ) : error.hasError && error.resource === USER.PASSWORD ? (
          <Badge className="danger-custom rounded-0">
            Token de restauración expirado
          </Badge>
        ) : (
          <React.Fragment />
        )}
        <Link className="mt-3" to="/login">
          <FontAwesomeIcon
            className="fa-sm mr-2"
            icon={["fas", "arrow-left"]}
          />
          Ir a login
        </Link>
        <h4 className="mt-5">{t("translation:general>contactInformation")}</h4>
        <h6>info@fast-min.com</h6>
      </Form>
    );
  }
}

const actions = dispatch => ({
  sendResetPassword: passwords =>
    dispatch({ type: RESET_PASSWORD, payload: passwords })
});

const data = state => ({
  error: state.error
});

export default connect(data, actions)(withTranslation()(ResetPassword));
