import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, Badge, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tables, Avatar } from '../../../components';
import AddUser from '../../../components/users/add.user';
import AddSociety from '../../../components/societies/add.society';
import Input from '../../../components/causes/input.causes';
import {GET_USER, GET_USERS} from "../../../sagas/user/types";
import {GET_CLIENT} from "../../../sagas/client/types";
import { GENERAL } from '../../../config/constants/tableTitles';
import { PATH } from '../../../config/constants/paths';
import { USER_TYPES } from '../../../config/constants/user';
import SmallEmpty from '../../../components/smallEmptyCard';
import Empty from '../../../components/mediumEmptyCard';
import { withTranslation } from "react-i18next";

const titlesUsers = [];

function Information(props) {
  return (
    <Col className="mb-2 left-content__title" xl={12} xs={6}>
      <div className="p-2">
        <p className="small">{props.title}</p>
        <h6>{props.value}</h6>
      </div>
    </Col>
  )
}

class ClientProfile extends Component {
  constructor(props){
    super(props);
    this.state={
      modalShow: false,
      modalAddUser: false,
      modalAddSociety: false,
      user: {},
    }
    this.modalClose = this.modalClose.bind(this);
  }

  componentWillMount(){
    this.props.getClient(this.props.match.params.id);
    this.props.getUsers();
  }

  modalClose(){
    this.setState({ modalShow: false, modalAddUser: false, modalAddSociety: false });
  }

  generateAttributes(attributes) {
    return attributes.map((item, index) => {
      return (
            <Input key={item.id} {...item} />
          )
    })
  }

  handleType(item) {
    const type = item ? item.type : '';
    switch (type) {
      case USER_TYPES.ADMIN:
        return 'secondary-custom rounded-0';
      case USER_TYPES.SUPER_ADMIN:
        return 'success-custom rounded-0';
      case USER_TYPES.VISUALIZER:
        return 'warning-custom rounded-0';
      default:
        return 'danger-custom rounded-0';
    }
  }

  generateUserTable(content){
    const { t } = this.props;
    const element = content.map(aux => {
    const users = this.props.users ? this.props.users : [];
    const item = users.length > 0 ? users.find(obj => obj.id === aux.user_client_id) : [];
    if(item){
      return (
        <tr key={item.id} id={item.id} onClick={(e) => this.handleClickUser(item, e)}>
          <td><Avatar height={30} {...item}/></td>
          <td>
            <p className='text-capitalize'>{`${item.first_name} ${item.last_name}`}</p><span>{item.email}</span>
          </td>
          <td className="align-middle">
          <h6>
            <Badge className={this.handleType(item)}>
              {t(item.type)}
            </Badge>
          </h6>
          </td>
        </tr>
      )
    }  
    })
    return element;
  }

  generateTable(content){
    const { t } = this.props;
    const element = content.map(item => {
      return (
        <tr key={item.society_id}>
          <td></td>
          <td className="align-middle">{item.name}</td>
          <td className="align-middle">{item.causes_number}</td>
          <td className="align-middle">{item.email}</td>
          <td className="align-middle">{item.created_at}</td>
          <td className="align-middle">
            <Col>{this.generateAvatars(item.representatives)}</Col>
          </td>
          <td className="align-middle actions">
            <Link className="nav-link text-center" to={{pathname: `${this.props.client.id}/${PATH.SOCIETY}${item.id}`, state: {society: item, clientName: this.props.client.name}}}>{t("translation:general>show")}</Link>
          </td>
        </tr>
      )
    })
    return element;
  }

  generateAvatars(users) {
    const usersAux = this.props.users ? this.props.users : [];
    const avatars = users.map(user =>{
      const item = usersAux.length > 0 ? usersAux.find(obj => obj.id === user.user_client_id) : [];
      return(
        <Avatar height={40} {...item}/>
      )
    })
    return avatars;
  }

  handleClickUser(item, e){
    this.setState({modalShow: true, user: item})
  }

  generateTableCause(causes) {
    const { t } = this.props;
    const element = causes.map(item => {
      return (
        <tr  key={item.id}>
          <td></td>
          <td>
            <p>{item.name}</p>
            <span>{t(item.type)}</span>
          </td>
          <td className="align-middle">{item.created_at}</td>
          <td className="align-middle">{item.rol}</td>
          <td className="align-middle">{item.judge}</td>
          <td className="align-middle actions">
            <Link className="nav-link text-center" to={`/${PATH.CAUSE}${item.id}/${PATH.STAGES}`}>{t("translation:general>show")}</Link>
          </td>
        </tr>
      )
    });
    return element;
  }

  userModal() {
    const { user } = this.state ;
    const { t } = this.props;
    return (
      <Modal show={this.state.modalShow} onHide={this.modalClose}
        size="sm"
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
        centered>
        <Modal.Header closeButton>
          <h5>{t("translation:modal>userDetail>title")}</h5>
        </Modal.Header>
        <Card className="right-content">
          <Container>
          <Row className="pt-2 pb-4">
            <Col xl={12}>
                <div className="title p-2">

                  <h4 className='text-capitalize'>{`${user.first_name} ${user.last_name}`}</h4>
                  <Badge className={this.handleType(user)}>{user.type}</Badge>
                </div>
                <Information title={t("translation:modal>userDetail>userType")} value={t(user.type)}/>
                <Information title={t("translation:general>phone")} value={user.phone}/>
                <Information title={t("translation:general>email")} value={user.email}/>
            </Col>
          </Row>
          </Container>
        </Card>
      </Modal>
    )
  }

  showAddUserModal(user) {
    const type = user.type ? user.type.trim() : '';
    if (type === USER_TYPES.SUPER_ADMIN) {
      const { t } = this.props;
      return (
        <>
          <Button variant='link' onClick={() => this.setState({modalAddUser: true})}>
            <FontAwesomeIcon className="fa-sm mr-1" icon={['far', 'plus']}/>
            {t("translation:general>add")}
          </Button>
          <Modal show={this.state.modalAddUser} onHide={this.modalClose}>
            <AddUser modalClose={this.modalClose} client_id={this.props.client.id} representatives={this.props.client.representatives}/>
          </Modal>
        </>
      )
    }
  }

  showAddSocietiesModal(user) {
    const type = user.type ? user.type.trim() : '';
    if (type === USER_TYPES.SUPER_ADMIN) {
      const { t } = this.props;
      return (
        <>
          <Button variant='link' onClick={() => this.setState({modalAddSociety: true})}>
            <FontAwesomeIcon className="fa-sm mr-1" icon={['far', 'plus']}/>
            {t("translation:general>add")}
          </Button>
          <Modal show={this.state.modalAddSociety} onHide={this.modalClose}>
            <AddSociety modalClose={this.modalClose} client_id={this.props.client.id} societies_client={this.props.client.societies}/>
          </Modal>
        </>
      )
    }
  }

  emptyCard = () => {
    const { t } = this.props;
    return (
    <SmallEmpty type="small-empty-table">
      <Col xs="6">
        <h5>
          {t("translation:emptyTable>representatives>title")}
        </h5>
        <p className="text-muted">{t("translation:emptyTable>representatives>subtitleAdmin")}</p>
        <Button
          onClick={() => this.setState({modalAddUser: true})}
          className="rounded-0">
            {t("translation:general>add")}
        </Button>
      </Col>
    </SmallEmpty>
  )}

  toogleEmptyUsers = () => {
    const { client } = this.props;
    const representatives = client.representatives || [];
    const EmptyCard = this.emptyCard;
    if (representatives.length > 0) {
      return this.generateUserTable(representatives)
    }
    return (
      <EmptyCard/>
    )
  }

  emptyCauses = () => {
    const { t } = this.props;
    return (
    <Empty type="medium-empty-table">
      <Col xs="4">
        <h5>
          {t("translation:emptyTable>clientSocieties>causes>title")}
        </h5>
        <p className="text-muted">{t("translation:emptyTable>clientSocieties>causes>subtitle")}</p>
        <Link to="/causes">
          <Button className="rounded-0">
              {t("translation:general>causes")}
          </Button>
        </Link>
      </Col>
    </Empty>
  )}

  toogleEmptyCauses = () => {
    const { client, t } = this.props
    const causes = client.causes || [];
    const EmptyCauses = this.emptyCauses;
    if (causes.length > 0) {
      const titlesCause = [
        GENERAL.EMPTY,
        t("translation:general>name"),
        t("translation:general>created"),
        t("translation:general>rol"),
        t("translation:general>judge"),
        GENERAL.EMPTY];
      return (
        <Tables titles={titlesCause}>
          {this.generateTableCause(causes)}
        </Tables>
      )
    }
    return (
      <EmptyCauses/>
    )
  }

  emptySocieties = () => {
    const { user, t } = this.props;
    const type = user.type ? user.type.trim() : '';
    if (type === USER_TYPES.SUPER_ADMIN) {
      return (
        <Empty type="medium-empty-table">
          <Col xs="4">
            <h5>
              {t("translation:emptyTable>clientSocieties>title")}
            </h5>
            <p className="text-muted">{t("translation:emptyTable>clientSocieties>subtitleAdmin")}</p>
            <Button onClick={() => this.setState({modalAddSociety: true})} className="rounded-0">
                {t("translation:general>add")}
            </Button>
          </Col>
        </Empty>
    )}
    return (
      <Empty type="medium-empty-table">
        <Col xs="4">
          <h5>
            {t("translation:emptyTable>clientSocieties>title")}
          </h5>
          <p className="text-muted">{t("translation:emptyTable>clientSocieties>subtitle")}</p>
        </Col>
      </Empty>
    )
  }

  toogleEmptySocieties = () => {
    const { client, t } = this.props
    const societies = client.societies || [];
    const EmptySocieties = this.emptySocieties;
    if (societies.length > 0) {
      const titles = [
        GENERAL.EMPTY,
        t("translation:general>name"),
        t("translation:general>causesNumber"),
        t("translation:general>email"),
        t("translation:general>created"),
        t("translation:general>representatives"),
        GENERAL.EMPTY];
      return (
        <Tables titles={titles}>
          {this.generateTable(societies)}
        </Tables>
      )
    }
    return (
      <EmptySocieties/>
    )
  }


  render() {
    const { client, t } = this.props
    const ToogleUsers = this.toogleEmptyUsers;
    return (
      <>
      <div className="dasheader text-white d-flex align-items-center">
        <Container>
          <Row>
            <Col bsPrefix="col-6">
              <h3><span>{client.name}</span></h3>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="pb-5">
        <Row className="mtn-5">
          <Col className="col-xl-4 col-lg-5 col-12">
            <Card className='info__general'>
            <div className="contain-header d-flex align-items-center title justify-content-between mt-4 pl-4">
              <h5>{t("translation:general>information")}</h5>
            </div>
              <Information title={t("translation:general>taxpayerId")} value={client.taxpayer_id}/>
              <Information title={t("translation:general>address")} value={client.address}/>
              <Information title={t("translation:general>email")} value={client.email}/>
              <Information title={t("translation:general>phone")} value={client.phone}/>
              <div className="contain-header d-flex align-items-center title justify-content-between mt-2 pl-4">
                <h5>{t("translation:general>representatives")}</h5>
                {this.showAddUserModal(this.props.user)}
              </div>
              <Tables titles={titlesUsers}>
                <ToogleUsers/>
                {this.userModal()}
              </Tables>
            </Card>
          </Col>
          <Col className="col-12 col-xl-8 col-lg-7">
          <Card className='info__general right-content'>
            <Row>
              <Col className="col-12">
                <div className="contain-header d-flex align-items-center title justify-content-between mt-4 pl-4">
                  <h5>{t("translation:general>societies")}</h5>
                  {this.showAddSocietiesModal(this.props.user)}
                  {/*<Link className="nav-link" to={{pathname: "#", state: {client}}}>Agregar nueva sociedad<FontAwesomeIcon className="ml-2" icon="plus"/></Link>*/}
                </div>
              </Col>
            </Row>
              {this.toogleEmptySocieties()}
            <Row>
              <Col className="col-12">
                <div className="contain-header d-flex align-items-center title justify-content-between mt-4 pl-4">
                  <h5>{t("translation:general>causes")}</h5>
                </div>
              </Col>
            </Row>
            {this.toogleEmptyCauses()}
          </Card>
          </Col>
        </Row>
      </Container>

      </>
    )
  }
}
const data = state => ({
  client: state.client.client,
  users: state.user.users,
  user: state.user.user,
});


const actions = dispatch => ({
  get: () => dispatch({type: GET_USER}),
  getClient: (clientId) => dispatch({type: GET_CLIENT, payload: clientId}),
  getUsers: () => dispatch({type: GET_USERS}),
});

export default connect(data, actions)(withTranslation()(ClientProfile));
