import React, {Component} from 'react';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {GET_DIAGRAMS} from "../../sagas/diagram/types";
import {Col, Row, Form, Button} from "react-bootstrap";
import {filteredCause} from "../../stores/diagram";
import {CREATE_CAUSE} from "../../sagas/cause/types";
import Loading from "../loading";
import { GET_CLIENTS, GET_CLIENT } from "../../sagas/client/types";
import { STATES } from "../../config/constants/states";
import { GENERAL } from '../../config/constants/resources';

class CreateCauses extends Component {

  state = {
    filteredCause: []
  };

  componentWillMount() {
    this.props.getDiagrams();
    this.props.getClients();
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
    if (e.target.name === 'cause_type') {
      this.handleCauseTypeChange(e);
    }
  }

  handleChangeClient(e) {
    this.handleChange(e);
    this.props.getClient(e.target.value);
  }

  handleCauseTypeChange(e) {
    const {diagrams} = this.props.filterCause(e.target.value);
    this.setState({
      filteredCause: diagrams
    })
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    this.props.createCause(Object.fromEntries(data));
  }

  render() {
    const societies = this.props.client.societies || [];
    const { t } = this.props;
    const { status, resource } = this.props.error;
    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSubmit(e)}>
          <Row>
            <Col className="col-12" xl={5}>
              <div className="title p-2">
                <h4>{t("translation:modal>causes>title")}</h4>
                <p>{t("translation:modal>causes>subtitle")}</p>
              </div>
            </Col>
            <Col className="col-12 mb-3" xl={7}>
              <Form.Group>
                <Form.Label>{t("translation:form>selectClient")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="client"
                  required
                  onChange={e => this.handleChangeClient(e)}>
                  <option disabled selected>{t("translation:form>selectClient")}</option>
                  {this.props.clients.map((el, i) =>
                    <option key={i} value={el.id}>{el.name}</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("translation:form>selectSociety")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="society_client_id"
                  onChange={e => this.handleChange(e)}>
                  <option disabled selected>{t("translation:form>selectSociety")}</option>
                  <option key={0} value={this.props.client.client_society_id}>{t("translation:general>noSociety")}</option>
                  {societies.map((el, i) =>
                    <option key={i + 1} value={el.client_society_id}>{el.name}</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("translation:form>selectDiagramType")}</Form.Label>
                <Form.Control
                  required
                  name="cause_type"
                  as="select"
                  className="text-capitalize"
                  onChange={e => this.handleChange(e)}>
                  <option disabled selected>{t("translation:form>selectDiagramType")}</option>
                  {this.props.cause_type.map((el, i) =>
                    <option key={i} value={el.id}>{t(el.name)}</option>
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("translation:form>selectDiagram")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="cause"
                  required
                  onChange={e => this.handleChange(e)}>
                  <option disabled selected>{t("translation:form>selectDiagram")}</option>
                  {this.state.filteredCause.map((el, i) =>
                    <option key={i} value={el.id}>{t(el.name)}</option>
                  )}
                </Form.Control>
              </Form.Group>

              <Button
                variant="primary rounded-0 float-right"
                type="submit"
                disabled={(status === STATES.STATUS.WAITING && resource === GENERAL.CREATE)}>
                <Loading resource={GENERAL.CREATE} title={t("translation:general>create")}/>
              </Button>
            </Col>
          </Row>
        </form>
      </React.Fragment>
    );
  }
}

const data = state => ({
  cause_type: state.diagram.diagrams.map(el => ({id: el.id, name: el.name})),
  filterCause: id => filteredCause(state, id),
  clients: state.client.clients,
  client: state.client.client,
  error: state.error,
});

const actions = dispatch => ({
  getDiagrams: () => dispatch({type: GET_DIAGRAMS}),
  createCause: (cause) => dispatch({type: CREATE_CAUSE, payload: cause}),
  getClients: () => dispatch({type: GET_CLIENTS}),
  getClient: (clientId) => dispatch({type: GET_CLIENT, payload: clientId}),
});

export default connect(data, actions)(withTranslation()(CreateCauses));
