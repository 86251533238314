import axios from './config';

export const societies = async (query = '') => await axios.get('/b/p/societies' + query,
  {
    headers: {
      service: "CLIENT"
    }
  });

export const society = async (societyId) => await axios.get(`/b/p/societies/${societyId}`,
  {
    headers: {
      service: "CLIENT"
    }
});

export const create_society = async (society) => await axios.post('/b/p/societies', society,
  {
    headers: {
      service: "CLIENT"
    }
  });
