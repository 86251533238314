import React, { Component } from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { Row, Button, Modal, Col } from 'react-bootstrap';
import { GET_CONCESSION, FILL_CONCESSION } from "../../../sagas/concession/types";
import Input from '../input.causes';
import Loading from '../../loading';
import { USER_TYPES } from '../../../config/constants/user';
import { ATTRIBUTE_TYPE } from '../../../config/constants/components'
import attributeValueManager from '../../../helpers/attributeValueManager';

function Inputs(props) {
  if (props.attributeValues) {
    return props.attributeValues.map((item) => {
      return (<Input
        handleChange={props.handleChange}
        handleDateChange={props.handleDateChange}
        {...item}
        errors={props.errors}/>)
    });
  }
  return (<React.Fragment/>)
}

function InfoCol(props) {
  const task = props.taskConcession;
  const { t } = props;
  if (props.attributeValues) {
    return props.attributeValues.map((item) =>
      {
      let value = item.value || '-';
      if (item.type === ATTRIBUTE_TYPE.SELECT_TASK && item.value) value = task.nextTask.find(obj => obj.id === value).name;
      else if (item.type === ATTRIBUTE_TYPE.CHECK_TASK && item.value) value = checkTaskValue(item, task);
      else value = attributeValueManager(item);
      return (
        <Col key={item.id} className="col-12 mb-2 title" md={6}>
          <span className="mb-2">{t(item.label)}</span>
          <p>{value}</p>
        </Col>
      )}
    )
  }
  return (<React.Fragment/>)
}

function checkTaskValue(props, task) {
  const { nextTask, next_stage_id } = task;
  const { value, placeholder } = props;
  const response = placeholder.split(' ');
  const nt = nextTask.find(obj => obj.id === value);
  if(nt && !next_stage_id)
  {
    return ( nt.is_default ? response[0] : response[1]);
  }
  else if (nt && next_stage_id){
    return response[1];
  } else {
    return response[0];
  }
}

class CustomModal extends Component {
  state = {
    attributeValues: [],
    errors: [],
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.attributeValues) !== JSON.stringify(this.state.attributeValues)) {
      this.setState({
        attributeValues: nextProps.attributeValues,
      })
    }
  }

  handleUpload = () => {
    this.props.getconcession(this.props.taskConcession.id)
  }

  handleDateChange = (date, id) => {
    try {
      const { attributeValues } = this.state;
      const { cause } = this.props;
      const idx = attributeValues.findIndex(e => e.id === id);

      attributeValues[idx].value = date;
      this.setState({
        attributeValues,
        cause
      })
    } catch (e) {
      console.log(e);
    }
  }

  handleChange = (e) => {
    const attributeValues = this.state.attributeValues;
    const cause = this.props.cause;
    const {name, value} = e.target;
    const idx = attributeValues.findIndex(e => e.id === name);

    attributeValues[idx].value = value;
    this.setState({
      attributeValues,
      cause
    })
  }

  render() {
    const type = this.props.user.type || '';
    if (this.props.taskConcession) {
      const { t } = this.props;
      return (
        <Modal
          show={this.props.modalShow}
          onHide={this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t("translation:modal>concessions>title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="right-content">
            <Row>
              <Col className="col-12" xl={4}>
                <div className="title p-2">
                  <h4>{t(this.props.taskConcession.name)}</h4>
                  <p className="text-muted">{t(this.props.taskConcession.description)}</p>
                </div>
              </Col>
              <Col className="col-12" xl={8}>
                {(type.includes(USER_TYPES.SUPER_ADMIN) || type.includes(USER_TYPES.ADMIN))
                  ? <Inputs
                      attributeValues={this.props.taskConcession.attributeValues}
                      handleChange={this.handleChange}
                      handleDateChange={this.handleDateChange}
                      errors={this.state.errors}/>
                  : <InfoCol
                      t={t}
                      task={this.props.taskConcession}
                      attributeValues={this.props.taskConcession.attributeValues}/>
                }
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="rounded-0" onClick={() => this.props.saveTask(this.state.attributeValues)}>
              <Loading title={t("translation:general>save")}/>
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else {
      return (<React.Fragment/>);
    }
  }
}

const actions = dispatch => ({
  fillConcession: (task) => dispatch({type: FILL_CONCESSION, payload: task}),
  getConcession: (taskId) => dispatch({type: GET_CONCESSION, payload: taskId}),
});

const data = state => ({
  concession: state.stage.concession,
  attributeValues: state.task.taskConcession.hasOwnProperty('attributeValues')
  ? state.task.taskConcession.attributeValues
  : [],
  cause: state.cause.cause,
  tasks: state.stage.concession.tasks,
  taskConcession: state.task.taskConcession,
  user: state.user.user,
});

export default connect(data, actions)(withTranslation()(CustomModal));
