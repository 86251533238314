import React, { Component } from "react";
import { Link, Switch } from "react-router-dom";
import MonttImage from "../../assets/images/logo.svg";
import { PublicRoute } from "../../components/routes";
import ForgotPassword from "./password/forgot";
import ResetPassword from "./password/reset";
import { Form, Button, Image, Row, Col, Badge } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LanguageButton from "../../components/language.button";

class LoginComp extends Component {
  loginUser = () => {
    const hasError = this.props.location.search.includes("error");
    const { t } = this.props;
    return (
      <Form className="login__form" method="POST" action="/b/login">
        <Row className="text-left mb-5">
          <LanguageButton />
        </Row>
        <Row className="text-left mb-3 mt-5">
          <Col>
            <Image className="img-logo" src={MonttImage}/>
          </Col>
        </Row>
        <div className="text-left mt-5 mb-4">
          <h1 className="h3 mb-3 font-weight-normal">{t("translation:login>enterAccount")}</h1>
        </div>
        <Form.Group bsPrefix="form-label-group">
          <Form.Control
            as="input"
            className="rounded-0"
            id="email"
            type="email"
            name="username"
            placeholder="Correo electrónico"
            required=""
            autoFocus=""
          />
          <Form.Label bsPrefix="label" htmlFor="email">
            {t("translation:general>email")}
          </Form.Label>
        </Form.Group>
        <Form.Group bsPrefix="form-label-group">
          <Form.Control
            as="input"
            className="rounded-0"
            id="password"
            type="password"
            name="password"
            placeholder="Password"
            required=""
          />
          <Form.Label bsPrefix="label" htmlFor="password">
          {t("translation:general>password")}
          </Form.Label>
        </Form.Group>
        <Button
          variant="primary"
          className="rounded-0 text-center"
          block
          type="submit"
        >
          {t("translation:login>logIn")}
        </Button>
        {hasError ? (
          <Badge className="danger-custom rounded-0">
            {t("translation:login>error>invalidUsernameOrPassword")}
          </Badge>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <Link className="mt-3 mb-5" to="/login/password/forgot">
        {t("translation:login>cantYouLogIn")}
        </Link>

        <h4 className="mt-5">{t("translation:general>contactInformation")}</h4>
        <h6>info@fast-min.com</h6>
      </Form>
    );
  };

  render() {
    const LoginUser = this.loginUser;
    const { t } = this.props;
    return (
      <main className="login">
        <Switch>
          <PublicRoute exact path="/login" component={LoginUser} />
          <PublicRoute
            exact
            path="/login/password/forgot"
            component={ForgotPassword}
          />
          <PublicRoute
            exact
            path="/login/password/reset/:id"
            component={ResetPassword}
          />
        </Switch>
        <section className="login__hero">
          <Row>
            <Col className="ml-5 mt-5" xl={3} xs={12}>
              <h2 className="text-white">{t("translation:login>keepCasesUpdated")}</h2>
            </Col>
          </Row>
        </section>
      </main>
    );
  }
}

export const Login = withTranslation()(LoginComp);
