import React from 'react';
import { SERVIDUMBRE } from '../../../config/constants/various';
import { DOCUMENT } from '../../../config/constants/states';
import { useTranslation } from "react-i18next";

const { DESCRIPTION } = DOCUMENT;

function StateOptionsByType(props) {
  const { t } = useTranslation();
  const { type } = props;
  if (type === SERVIDUMBRE.ADMINISTRATIVA
    || type === SERVIDUMBRE.CONVENCIONAL
    || type === SERVIDUMBRE.JUDICIAL) {
      return (
      <React.Fragment>
        <option value={DESCRIPTION.SEVEN}>{t(DESCRIPTION.SEVEN)}</option>
        <option value={DESCRIPTION.EIGHT}>{t(DESCRIPTION.EIGHT)}</option>
        <option value={DESCRIPTION.NINE}>{t(DESCRIPTION.NINE)}</option>
        <option value={DESCRIPTION.TEN}>{t(DESCRIPTION.TEN)}</option>
        <option value={DESCRIPTION.ELEVEN}>{t(DESCRIPTION.ELEVEN)}</option>
        <option value={DESCRIPTION.TWELVE}>{t(DESCRIPTION.TWELVE)}</option>
      </React.Fragment>
      )
    }
  return (
    <React.Fragment>
      <option value={DESCRIPTION.FIRST}>{t(DESCRIPTION.FIRST)}</option>
      <option value={DESCRIPTION.SECOND}>{t(DESCRIPTION.SECOND)}</option>
      <option value={DESCRIPTION.THIRD}>{t(DESCRIPTION.THIRD)}</option>
      <option value={DESCRIPTION.FOURTH}>{t(DESCRIPTION.FOURTH)}</option>
      <option value={DESCRIPTION.FIFTH}>{t(DESCRIPTION.FIFTH)}</option>
      <option value={DESCRIPTION.SIX}>{t(DESCRIPTION.SIX)}</option>
    </React.Fragment>
  );
}

export default StateOptionsByType;