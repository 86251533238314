import React, { Component } from 'react'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import { Container, Row, Col, Table, Button, Card } from 'react-bootstrap';
import CardCol from '../../../components/card';
import { Avatar } from '../../../components';
import { GET_CAUSE_FULL_INFO} from "../../../sagas/cause/types";
import { USER_TYPES } from '../../../config/constants/user';
import SmallEmpty from '../../../components/smallEmptyCard';
import PaymentTable from '../../../components/causes/info/table.payment'
import ConcessionTable from '../../../components/causes/info/table.concession'

function InfoCol(props) {
  const value = props.value || '-';
  const { t } = props;
  return (
    <Col className="col-12 mb-2" md={4}>
      <p className="small">{t(props.label)}</p>
      <p>{value}</p>
    </Col>
  )
}

class Information extends Component {
  componentWillMount() {
    const {id} = this.props.match.params;
    this.props.getCauseFullInfo(id);
  }

  generateColInfo() {
    try {
      const { t } = this.props;
      return this.props.causeFullInfo.map(c => <InfoCol {...c} t={t}/>);
    } catch (e) {
      console.log(e);
    }
  }

  userCols(users) {
    const { t } = this.props;
    return users.map( user => {
      return (
        <tr>
          <th>
            <Avatar key={user.id} height={40} {...user}/>
          </th>
          <td className="align-middle text-left">
            <p>{`${user.first_name} ${user.last_name}`}</p>
            <p className="small">{t(user.user_type)}</p>
          </td>
          <td className="align-middle text-right">
            {this.showDeleteAction(this.props.user)}
          </td>
        </tr>
      )
    })
  }

  showDeleteAction(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      const { t } = this.props;
      return (
      <>
        <Button variant="link">{t("translation:general>unlink")}</Button>
      </>
      )
    }
  }

  showEditAction(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      const { t } = this.props;
      return (
      <>
        <Button className="flex-shrink-1 bd-highlight" variant="link"><Link>{t("translation:general>edit")}</Link></Button>
      </>
      )
    }
  }

  emptyCard = () => {
    const { t } = this.props;
    return (
    <SmallEmpty type="small-empty-table">
      <Col xs="6">
        <h5>
          {t("translation:emptyTable>cause>information>users>title")}
        </h5>
        <p className="text-muted">{t("translation:emptyTable>cause>information>users>subtitle")}</p>
      </Col>
    </SmallEmpty>
  )}

  toogleEmptyUsers = () => {
    const EmptyCard = this.emptyCard;
    const {causeInfo} = this.props;
    const users = causeInfo.users ? causeInfo.users : [];
    if (users.length > 0) {
      return this.userCols(users)
    }
    return (
      <EmptyCard/>
    )
  }

  render() {
    const { t } =  this.props;
    return (
      <Container className="pb-5 info">
        <Row className="mtn-5">
          <Col className="col-12" lg={7} xl={8}>
            <Card className="info__general">
              <div className="title d-flex bd-highlight align-items-center p-4">
                <h5 className="w-100 bd-highlight">{t("translation:general>generalInformation")}</h5>
                {/* TODO; Implement edit.
              this.showEditAction(this.props.user)*/}
              </div>
              <Container className="table">
                <Row className="row mt-4">
                  {this.generateColInfo()}
                </Row>
              </Container>
            </Card>
            {(this.props.concession.id)
              ? <ConcessionTable/>
              : <React.Fragment/>}
            {(this.props.payment.id)
              ? <PaymentTable/>
              : <React.Fragment/>}
          </Col>
          <Col className="col-12" xl={4} lg={5}>
            <CardCol className="info__lawyers" title={t("translation:general>assignedUsers")}>
              <Table hover className="mt-3 mb-3">
                <tbody>
                  {this.toogleEmptyUsers()}
                </tbody>
              </Table>
            </CardCol>
          </Col>
        </Row>
      </Container>
    )
  }
}

const actions = dispatch => ({
  getCauseFullInfo: (id) => dispatch({type: GET_CAUSE_FULL_INFO, payload: id})
});


const data = state => ({
  payment: state.stage.payment,
  concession: state.stage.concession,
  user: state.user.user,
  causeInfo: state.cause.causeInfo,
  causeFullInfo: state.cause.causeFullInfo,
});

export default connect(data, actions)(withTranslation()(Information));
