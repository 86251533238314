import axios from './config';

export const clients_societies = async () => await axios.get('/b/p/clients/societies',
  {
    headers: {
      service: "CLIENT"
    }
  });

  export const create_client_society = async (clientSociety) => await axios.post('/b/p/clients/societies', clientSociety,
    {
      headers: {
        service: "CLIENT"
      }
    });
