import {takeLatest, call, put} from "redux-saga/effects";
import { GET_DIAGRAMS, GET_PROJECT_DIAGRAMS } from "./types";
import { diagrams, project_diagrams } from "../../network/diagrams";
import { FETCH_DIAGRAMS, FETCH_PROJECT_DIAGRAMS } from "../../stores/diagram/types";
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'

const cookies = new Cookies();


function* getDiagrams() {
  try {
    const {data} = yield call(diagrams);
    yield put({type: FETCH_DIAGRAMS, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* getProjectDiagrams() {
  try {
    const {data} = yield call(project_diagrams);
    yield put({type: FETCH_PROJECT_DIAGRAMS, payload: data.data})
  } catch (e) {
    console.log(e)
  }
}
function* watchGetDiagrams() {
  yield takeLatest(GET_DIAGRAMS, getDiagrams)
}

function* watchGetProjectDiagrams() {
  yield takeLatest(GET_PROJECT_DIAGRAMS, getProjectDiagrams)
}

export default [
  watchGetDiagrams(),
  watchGetProjectDiagrams()
]
