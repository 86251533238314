import axios from './config';
import querystring from 'querystring';
import { CORE } from '../config/constants/network';

export const causes = async (query) => await axios.get(`/b/p/cause` + query ,
  {
    headers: {
      service: "CORE"
    }
  });

export const causesbyProject = async (query = '') => await axios.get('/b/p/cause' + query,
  {
    headers: {
      service: "CORE"
    }
  });

  export const cause_normal_files = async ({id, pagination}) => await axios.get(`/b/p/cause/${id}/document?filter=Type/Name eq Normal&limit=${pagination.limit}&page=${pagination.page}`,
  {
    headers: {
      service: "CORE"
    }
  }
);

export const cause = async (id) => await axios.get('/b/p/cause/' + id,
  {
    headers: {
      service: "CORE"
    }
  });

export const cause_update = async (id, cause) => await axios.put('/b/p/cause/' + id, cause,
  {
    headers: {
      service: "CORE"
  }
});

export const cause_info = async (id) => await axios.get(`/b/p/cause/${id}/minifiedinfo`,
  {
    headers: {
      service: "CORE"
    }
  }
);

export const cause_full_info = async (id) => await axios.get(`/b/p/cause/${id}/info`,
  {
    headers: {
      service: "CORE"
    }
  }
);



export const cause_catastro_files = async ({id, pagination}) => await axios.get(`/b/p/cause/${id}/document?filter=Type/Name eq Catastro&limit=${pagination.limit}&page=${pagination.page}`,
  {
    headers: {
      service: "CORE"
    }
  }
);

export const cause_files = async (id) => await axios.get(`/b/p/cause/${id}/document`,
  {
    headers: {
      service: "CORE"
    }
  }
);

export const create_file_task = async (file) => axios.post(CORE.PATH.DOCUMENT, file,
    {
      headers: {
        service: CORE.SERVICE,
      }
    }
  ).catch(e => {
    console.log(e);
  });

export const document_list_all = async (query) => axios.get(`${CORE.PATH.DOCUMENT}${query}`,
  {
    headers: {
      service: CORE.SERVICE,
    }
  }
).catch(e => {
  console.log(e);
});

export const update_file_task = async (file) => axios.put(`${CORE.PATH.DOCUMENT}/${file.id}`, file,
  {
    headers: {
      service: CORE.SERVICE,
    }
  }
).catch(e => {
  console.log(e);
});

export const create_cause = async (cause) => await axios.post('/b/p/cause',
  {diagram_id: cause.cause, society_client_id: cause.society_client_id},
  {
    headers: {
      service: "CORE"
    }
  }
);

export const delete_cause = async (id) => await axios.delete('/b/p/cause/' + id,
  {
    headers: {
      service: "CORE"
    }
  });
