import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Modal, Container, Form, Button } from 'react-bootstrap';
import { GET_SOCIETIES } from "../../sagas/society/types";
import { CREATE_CLIENT_SOCIETY } from "../../sagas/clientSociety/types";
import { withTranslation } from "react-i18next";

class AddSociety extends Component {
  componentWillMount(){
    this.props.getSocieties();
  }
  selectFilter(){
    const { societies_client } = this.props;
    const newSocieties = [];
    this.props.societies.forEach(obj => {
      const finded = societies_client.find(el => el.id === obj.id);
      if (!finded){
        newSocieties.push(obj);
      }
    });
    return newSocieties;
  }
  handleSubmit(e){
    e.preventDefault();
    const data = new FormData(e.target);
    const obj = Object.fromEntries(data);
    obj.client_id = this.props.client_id;
    this.props.createClientSociety(obj);
    this.props.modalClose();

  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Modal.Header closeButton>
          <h5>{`${t("translation:general>add")} ${t("translation:general>society").toLowerCase()}`}</h5>
        </Modal.Header>
        <Card className="right-content">
          <Container>
            <Row className="pt-2 pb-4">
              <Col xl={12}>
                <form onSubmit={e => this.handleSubmit(e)}>
                  <Form.Group>
                    <Form.Label>{t("translation:form>selectSociety")}</Form.Label>
                    <Form.Control
                      as="select"
                      className="text-capitalize"
                      name="society_id"
                      required>
                      <option value= "" >{t("translation:form>selectSociety")}</option>
                      {this.selectFilter().map((el, i) =>
                        <option key={i} value={el.id}>{el.name}</option>
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>add")}</Button>
                </form>
              </Col>
            </Row>
          </Container>
        </Card>
      </>
    );
  }
}

const data = state => ({
  societies: state.society.societies
});


const actions = dispatch => ({
  createClientSociety: (cs) => dispatch({type: CREATE_CLIENT_SOCIETY, payload: cs}),
  getSocieties: () => dispatch({type: GET_SOCIETIES}),
});

export default connect(data, actions)(withTranslation()(AddSociety));
