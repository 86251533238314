export const USER = {
  PROFILE: 'profile',
  PASSWORD: 'password',
}

export const GENERAL = {
  DEFAULT: '',
  CREATE: 'create',
  DELETE: 'delete',
}
