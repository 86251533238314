import { takeLatest, call } from 'redux-saga/effects';
import { SEND_NOTIFICATION } from "./types";
import { notification } from "../../network/notificator";

function* sendNotification(action) {
  const { payload } = action;
  try {
    yield call(notification, payload)
  } catch(e) {
    console.log(e)
  }
}

function* watchSendNotification() {
  yield takeLatest(SEND_NOTIFICATION, sendNotification);
}

export default [
  watchSendNotification(),
]
