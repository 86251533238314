import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Navbar, Container, Image, Nav } from 'react-bootstrap'
import MonttImage from '../assets/images/logo.svg';
import CustomDropdown from './customDropdown';
import SearchBar from './searchBar';
import LanguageButton from './language.button';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";

function NavLink(props) {
  return <Link className={props.className} to={props.to}>{props.name}</Link>
}

class CustomNavigator extends Component {

  render() {
    if (this.props.user.isLoggedIn) {
      const { t } = this.props;
      return (
        <React.Fragment>
        <Navbar variant="light" bg="light" expand="lg" fixed="top">
          <Container>
            <Navbar.Brand href="/dashboard">
              <Image className="img-logo-navbar" src={MonttImage}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse className="mr-3" id="collapse1">
              <Nav className="mr-auto">
                <NavLink className="nav-link" to="/dashboard" name={t("translation:general>home")}/>
                <NavLink className="nav-link" to="/clients" name={t("translation:general>clients")}/>
                <NavLink className="nav-link" to="/societies" name={t("translation:general>societies")}/>
                <NavLink className="nav-link" to="/projects" name={t("translation:general>projects")}/>
                <NavLink className="nav-link" to="/causes" name={t("translation:general>causes")}/>
                <NavLink className="nav-link" to="/users" name={t("translation:general>users")}/>
              </Nav>
              <SearchBar/>
              <CustomDropdown src={this.props.src}/>
            </Navbar.Collapse>
            <LanguageButton/>
          </Container>
        </Navbar>
      </React.Fragment>
      )
    } else {
      return <React.Fragment/>
    }

  }
}

const data = state => ({
  user: state.user
});

export default connect(data, null)(withTranslation()(CustomNavigator));
