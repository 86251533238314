import {
  FETCH_PROJECTS,
  FETCH_PROJECT,
  FETCH_PROJECT_CAUSE,
  PUSH_PROJECT,
} from "./types";

const initial = {
  projects: {},
  project: {},
};

export default (state = initial, action) => {
  const {type, payload} = action;
  switch (type) {
    case FETCH_PROJECTS:
      return {...state, projects: payload};
    case FETCH_PROJECT:
      return {...state, project: payload};
    case FETCH_PROJECT_CAUSE:
      return {...state, project:{...state.project, causes: payload}}
    case PUSH_PROJECT:
      return {...state, projects: [...state.projects, payload ] };
    default:
      return state;
  }
}
