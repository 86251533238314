import axios from './config'


export const diagrams = async () => await axios.get('/b/p/diagram', {
  headers: {
    service: 'CORE'
  }
});

export const project_diagrams = async () => await axios.get('/b/p/diagram/projects', {
  headers: {
    service: 'CORE'
  }
});
