import axios from './config'

export const tasks = async (id) => await axios.get('/b/p/task/' + id, {
  headers: {
    service: "CORE"
  }
});

export const post_task = async (task) => await axios.post('/b/p/task', task, {
  headers : {
    service :"CORE"
  }
});

export const fill_task = async (task) => await axios.put('/b/p/task', task, {
  headers : {
    service :"CORE"
  }
});

export const create_task = async (task) => await axios.post('/b/p/task/create', task, {
  headers : {
    service :"CORE"
  }
});

export const delete_task = async (id) => await axios.delete('/b/p/task/' + id, {
  headers: {
    service: "CORE"
  }
});

export const list_tasks_by_stage = async (stageId) => await axios.get(`/b/p/stage/${stageId}/task`, {
  headers : {
    service :"CORE"
  }
});
