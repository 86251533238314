import React, { Component } from 'react';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Col, Row, Form } from "react-bootstrap";
import { UPLOAD_INFO } from "../../sagas/file/types";
import { UPLOAD, UPLOAD_BY_URL } from "../../sagas/file/types";
import { DOCUMENT } from '../../config/constants/states';
import ToogleUpload from '../toogleUpload';
import DateInput from './datepicker';
import { StateOptionsByType, DescriptionOptionsByType } from '../../resolver/cause/surveillance';

class CreateCauses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catastroState: 0,
      uploadMode: 1,
      description: '',
      hasAlert: false,
      initial_date: '',
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleUploadUrl = this.handleUploadUrl.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    this.props.setUploadFile({
      progress: 0,
      responseState: DOCUMENT.SUCCESS,
    });
  }

  handleUpload(e) {
    this.props.setUploadFile({progress: 0, responseState: DOCUMENT.SUCCESS});
    let file = e.target.files[0];
    this.setState({file: file, progress: 0});
    const state = this.state.catastroState;
    if (this.props.catastroFiles) {
      const { document_id, task_check_id } = this.props.catastroFiles.shift();
      const fileInfo = {
        document_id,
        task_check_id,
        state,
        hasAlert: this.state.hasAlert,
        description: this.state.description,
      };
      if (this.state.hasAlert) fileInfo.initial_date = this.state.initial_date;
      this.props.uploadFile(
        file,
        fileInfo,
        this.props.cause.id,
      );
    }
  }

  handleDropUpload = (files) => {
    this.props.setUploadFile({progress: 0, responseState: DOCUMENT.SUCCESS});
    let file = files[0];
    this.setState({file: file, progress: 0});
    const state = this.state.catastroState;
    if (this.props.catastroFiles) {
      const { document_id, task_check_id } = this.props.catastroFiles.shift();
      const fileInfo = {
        document_id,
        task_check_id,
        state,
        hasAlert: this.state.hasAlert,
        description: this.state.description,
      };
      if (this.state.hasAlert) fileInfo.initial_date = this.state.initial_date;
      this.props.uploadFile(
        file,
        fileInfo,
        this.props.cause.id,
      );
    }
  }

  handleUploadUrl(fileState) {
    this.props.setUploadFile({progress: 10, responseState: DOCUMENT.SENDING});
    let file = {
      url: fileState.url,
      name: fileState.name,
      resource_id: this.props.cause.id,
    };
    const state = this.state.catastroState;
    if (this.props.catastroFiles) {
      const { document_id, task_check_id } = this.props.catastroFiles.shift();
      const fileInfo = {
        document_id,
        task_check_id,
        state,
        hasAlert: this.state.hasAlert,
        description: this.state.description,
      };
      if (this.state.hasAlert) fileInfo.initial_date = this.state.initial_date;
      this.props.uploadUrlFile(
        file,
        fileInfo,
      );
    }
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Form>
          <Row>
            <Col className="col-12" xl={4}>
              <div className="title p-2">
                <h4>{t("translation:modal>cadastres>title")}</h4>
                <p>{t("translation:modal>cadastres>subtitle")}</p>
              </div>
            </Col>
            <Col className="col-12 mb-3" xl={8}>
              <Form.Group>
                <Form.Label>{t("translation:modal>cadastres>state")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="catastroState"
                  required
                  onChange={e => this.handleChange(e)}>
                  <option disabled selected>{t("translation:form>selectState")}</option>
                  <StateOptionsByType type={this.props.causeInfo.type}/>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>{t("translation:modal>cadastres>description")}</Form.Label>
                <Form.Control
                  as="select"
                  className="text-capitalize"
                  name="description"
                  required
                  onChange={e => this.handleChange(e)}>
                  <option disabled selected>{t("translation:form>description")}</option>
                  <DescriptionOptionsByType type={this.props.causeInfo.type}/>
                </Form.Control>
              </Form.Group>
              <div className="custom-control custom-switch mr-2 mb-3">
                  <input
                    onChange={() => this.setState({ hasAlert: !this.state.hasAlert })}
                    class="custom-control-input"
                    type="checkbox"
                    id="hasAlert"
                    name="hasAlert"/>
                  <label className="custom-control-label" for="hasAlert">{t("translation:modal>cadastres>alertActivate")}</label>
              </div>
              {(this.state.hasAlert)
              ? (
                <DateInput
                  handleDateChange={(date, id) => this.setState({ initial_date: date })}
                  label={t("translation:modal>cadastres>alertInitDate")}
                  value={this.state.initial_date}/>)
              : null}

              {(!this.state.hasAlert && this.state.catastroState !== 0)
                  ? (<ToogleUpload
                      closeModal={this.props.closeModal}
                      {...this.state}
                      handleUpload={this.handleUpload}
                      handleDropUpload={this.handleDropUpload}
                      handleUploadUrl={this.handleUploadUrl}/>
                  )
                  : (this.state.hasAlert && this.state.catastroState !== 0 && this.state.initial_date !== '')
                  ? (<ToogleUpload
                      closeModal={this.props.closeModal}
                      {...this.state}
                      handleUpload={this.handleUpload}
                      handleDropUpload={this.handleDropUpload}
                      handleUploadUrl={this.handleUploadUrl}/>
                    )
                  :
                  <React.Fragment/>
                }
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}


const data = state => ({
  cause: state.cause.cause,
  uploadState: state.file.uploadFile,
  catastroFiles: state.cause.causeCatastroFiles.data,
  causeInfo: state.cause.causeInfo,
});

const actions = dispatch => ({
  setUploadFile: (file) => dispatch({type: UPLOAD_INFO, payload: file}),
  uploadFile: (file, fileInfo, causeId) => dispatch({type: UPLOAD, payload: {file, fileInfo, causeId}}),
  uploadUrlFile: (file, fileInfo) => dispatch({type: UPLOAD_BY_URL, payload: {file, fileInfo}}),
});

export default connect(data, actions)(withTranslation()(CreateCauses));
