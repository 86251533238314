import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { GET_PROJECTS } from "../../sagas/project/types";
import { Col, Button, Container, Row, ButtonToolbar } from 'react-bootstrap';
import { GreatingBar, Tables, Modal } from '../../components/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreateProject from "../../components/projects/create.project";
import { GENERAL } from '../../config/constants/tableTitles';
import { USER_TYPES } from '../../config/constants/user';
import Empty from '../../components/emptyCard';

class Projects extends Component {
  constructor(props){
    super(props);
    this.state = {modalShow: false};
    this.modalClose = this.modalClose.bind(this);
  }

  componentWillMount() {
    this.props.getProjects();
  }

  modalClose(){
    this.setState({ modalShow: false });
  }

  modalShow(){
    this.setState({ modalShow: true });
  }

  generateTable(projects){
    const content = projects.data || [];
    const { t } = this.props;
    const element = content.map(item => {
      const owner = item.society_name || item.client_name;
      return (
        <tr key={item.id}>
          <td></td>
          <td>
            <p>{item.name}</p>
          </td>
          <td/>
          <td className="align-middle">{owner}</td>
          <td/>
          <td className="align-middle">{item.causes_number}</td>
          <td />
          <td className="align-middle">{item.created_at}</td>
          <td />
          <td className="align-middle actions">
            <Link className="nav-link text-center" to={`/project/${item.id}/causes`}>{t("translation:general>show")}</Link>
            {/* TODO; Implement edit.
            this.showEditAction(this.props.user)*/}
          </td>
        </tr>
      )
    })
    return element;
  }

  showCreateModal(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      const { t } = this.props;
      return (
        <>
          <ButtonToolbar className="button float-right">
            <Button variant="primary" onClick={() => this.setState({ modalShow: true })}>
              {t("translation:general>create")} {t("translation:general>project").toLowerCase()} <FontAwesomeIcon className="fa-sm" icon={['far', 'plus']}/>
            </Button>
          </ButtonToolbar>
          <Modal show={this.state.modalShow} onHide={this.modalClose}>
            <CreateProject closeModal={this.modalClose}/>
          </Modal>
        </>
      );
    }
  }

  emptyTable = () => {
    const { user, t } = this.props;
    const type = user.type ? user.type.trim() : '';
    if (type === USER_TYPES.SUPER_ADMIN) {
      return (
        <Col className="pl-2" xs="7" md="3">
          <h5>
            {t("translation:emptyTable>projects>title")}
          </h5>
          <p className="text-muted">
            {t("translation:emptyTable>projects>messageAdmin")}
          </p>
          <Button onClick={() => this.modalShow()} className="rounded-0">{`${t("translation:general>create")} ${t("translation:general>project")}`}</Button>
        </Col>
      )
    }
    return (
      <Col className="pl-2" xs="3">
        <h5>
          {t("translation:emptyTable>projects>title")}
        </h5>
        <p className="text-muted">
          {t("translation:emptyTable>projects>message")}
        </p>
      </Col>
    )
  }

  toogleEmpty = () => {
    const EmptyTable = this.emptyTable;
    const { t } = this.props;
    const data = this.props.projects.data || [];
    if (data.length > 0) {
      const titles = [
        GENERAL.EMPTY,
        t("translation:general>name"),
        GENERAL.EMPTY,
        t("translation:general>client"),
        GENERAL.EMPTY,
        t("translation:general>causesNumber"),
        GENERAL.EMPTY,
        t("translation:general>created"),
        GENERAL.EMPTY,
      ];
      return (
        <Container className="pb-5 mtn-5">
          <Row>
            <Col className="col-12">
              <Tables titles={titles}>
                {this.generateTable(this.props.projects)}
              </Tables>
            </Col>
          </Row>
        </Container>
      )
    }
    return (
      <Container className="pb-5 mtn-3">
        <Row>
          <Col className="col-12">
            <Empty type="empty-table">
              <EmptyTable />
            </Empty>
          </Col>
        </Row>
      </Container>
    )
  }

  render() {
    const ToogleEmpty = this.toogleEmpty;
    const { t } = this.props;
    return (
      <>
        <GreatingBar title={t("translation:general>projects")}>
          {this.showCreateModal(this.props.user)}
        </GreatingBar>

        <ToogleEmpty/>
      </>
    )
  }
}

const data = state => ({
  user: state.user.user,
  projects: state.project.projects,
});

const actions = dispatch => ({
  getProjects: () => dispatch({type: GET_PROJECTS}),
});

export default connect(data, actions)(withTranslation()(Projects));
