import {all} from 'redux-saga/effects'
import diagram from './diagram'
import token from './token'
import cause from './cause'
import alert from './alert'
import avatar from './avatar'
import error from './error'
import task from './tasks'
import user from './user'
import file from './file'
import stage from './stage'
import region from './region'
import client from './client'
import search from './search'
import society from './society'
import concession from './concession'
import clientSociety from './clientSociety'
import password from './password'
import project from './project'
import notificate from './notificator'
import negotiation from './negotiation'
import payment from './payment'

export default function* rootSaga() {
  yield all([
    ...user,
    ...task,
    ...file,
    ...token,
    ...cause,
    ...alert,
    ...error,
    ...stage,
    ...avatar,
    ...region,
    ...client,
    ...search,
    ...society,
    ...project,
    ...payment,
    ...diagram,
    ...password,
    ...concession,
    ...notificate,
    ...negotiation,
    ...clientSociety,
  ])
}
