import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class Titles extends Component {

  render() {
    const icon = this.props.icon || 'edit';
    return (
      <Col>
        <Card className={this.props.type}>
          <Row>
            <Col xs="1"></Col>
            <Col xs={this.props.size || "3"}>
              <div className="text-center">
                <FontAwesomeIcon className="edit-icon pulse" size="2x" icon={['far', icon]} />
              </div>
            </Col>
            {this.props.children}
          </Row>
        </Card>
      </Col>
    )
  }
}