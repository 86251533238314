const DASHBOARD = {
  ALERT_TABLE_TITLES: {
    ALERT: 'alerta',
    CASE: 'caso',
    TIME: 'tiempo',
    STAGE: 'etapa',
    STATE: 'prioridad',
  },
  ALERT_TYPES: {
    HIGH: 'alta',
    LOW: 'baja',
    MEDIUM: 'media',
  }

};

export default DASHBOARD;
