import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Col, Card, Button, Badge, Row } from 'react-bootstrap';
import { SERVIDUMBRE } from '../../config/constants/various';
import { DELETE_CAUSES_FROM_PROJECT } from '../../sagas/cause/types';
import { withTranslation } from "react-i18next";

const {
  ADMINISTRATIVA,
  CONVENCIONAL,
  JUDICIAL,
 } = SERVIDUMBRE;

class CauseCard extends Component {
  generateHeader() {
    const { id, type, name, created_at } = this.props.cause;
    const { t } = this.props;
    return (
      <Card.Header>
        <Row>
          {this.generateBadge(type)}
        </Row>
        <Card.Subtitle className="text-muted" mt="2" mb="1"></Card.Subtitle>
        <Card.Title>
          <h5 className="d-inline-block">
            {name}
          </h5>
          <p className="d-inline-block ml-2"></p>
        </Card.Title>
        <Card.Subtitle>{created_at}</Card.Subtitle>
        <Button
          size="sm"
          className="rounded-0 mt-3"
          variant="outline-danger"
          onClick={() => this.props.deleteCause(id)}>
            {t("translation:general>delete")}
        </Button>
        <Button size="sm" variant="outline-primary rounded-0 float-right mt-3" onClick={() => this.handleClick()}>{`${t("translation:general>show")} ${t("translation:general>cause")}`}</Button>
      </Card.Header>
    )
  }

  generateBadge(type) {
    const { t } = this.props;
    switch (type) {
      case JUDICIAL:
        return (
        <Badge className='danger-custom rounded-0 mb-3'>
          {t(type)}
        </Badge>
        )
      case ADMINISTRATIVA:
        return (
        <Badge className='success-custom rounded-0 mb-3'>
          {t(type)}
        </Badge>
        )
      case CONVENCIONAL:
        return (
        <Badge className='success-custom rounded-0 mb-3'>
          {t(type)}
        </Badge>
        )
      default:
        return (
        <Badge className='warning-custom rounded-0 mb-3'>
          {t(type)}
        </Badge>
        )
    }
  }

  handleClick() {
    window.location.href = `/cause/${this.props.cause.id}/stages`;
  }

  render () {
    const Header = (props) => this.generateHeader(props);
    return (
      <Col className="causes mb-4" sm="12" md="4">
        <Card>
          <Header/>
        </Card>
      </Col>
    )
  }
}


const actions = dispatch => ({
  deleteCause: (causeId, cause = { project_id: null }) => dispatch({ type: DELETE_CAUSES_FROM_PROJECT, payload: { causeId, cause } }),
});

const data = state => ({
});

export default connect(data, actions)(withTranslation()(CauseCard));
