import React, { Component } from 'react';
import { Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Header(props) {
  return (
    <Card.Header>
      <Card.Subtitle className="text-muted" mt="2" mb="1">minera 1</Card.Subtitle>
      <Card.Title>
        <h5 className="d-inline-block">{props.rol}</h5>
        <p className="d-inline-block ml-2">{`· ${props.cause}`}</p>
      </Card.Title>
      <Card.Subtitle>{props.judge}</Card.Subtitle>
    </Card.Header>
  )
}

function Stage(props) {
  return(
    <Card.Body className="cardbody__document d-flex align-items-center">
      <div className="ic_doc">
        <FontAwesomeIcon className="fa-lg ml-2" icon={['far', 'expand-arrows']} />
      </div>
      <div className="title_doc d-inline-block ml-4">
        <p>{props.type}</p><span>{props.when}</span>
      </div>
      <h6 className="d-inline-block ml-auto">{props.stage}</h6>
    </Card.Body>
  )
}

function getActivity(content) {
  const activity = content.map(item => {
    return(
      <Stage {...item}/>
    )
  })
  return activity;
}

function getRecentActivity(activity) {
  const recentActivity = activity.map(item => {
    return (
      <Col className="causes mb-4" sm="12" md="4">
        <Card>
          <Header rol={item.rol} cause={item.cause} judge={item.judge}/>
          <Card.Body>
            {getActivity(item.tasks)}
          </Card.Body>
          <Card.Footer><a className="btn btn-primary d-block" href="#">ver causa</a></Card.Footer>
        </Card>
      </Col>
    )
  })
  return recentActivity;
}

export default class RecentActivity extends Component {
  render () {
    return (
      <>
        {getRecentActivity(this.props.activity)}
      </>
    )
  }
}