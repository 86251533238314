import {takeLatest, put, call} from 'redux-saga/effects'
import {GET_ALERTS, GET_ALERTS_BY_CAUSE, SET_ALERTS_BY_CAUSE} from "./types";
import {alerts, alert, setAlert} from "../../network/alerts";
import {FETCH_ALERT_BY_CAUSE, FETCH_ALERTS} from "../../stores/alert/types";
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

function* getAlerts() {
  try {
    const {data} = yield call(alerts);
    yield put({type: FETCH_ALERTS, payload: data.data})

  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* getAlertByCause(action) {
  const {payload} = action;
  try {
    const {data} = yield call(alert, payload);
    yield put({type: FETCH_ALERT_BY_CAUSE, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}

function* setAlertByCause(action) {
  const { payload } = action;
  try {
    yield call(setAlert, payload);
    const {data} = yield call(alert, payload.causeId);
    yield put({type: FETCH_ALERT_BY_CAUSE, payload: data.data});
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}



function* watchGetAlerts() {
  yield takeLatest(GET_ALERTS, getAlerts)
}

function* watchGetAlertByCause() {
  yield takeLatest(GET_ALERTS_BY_CAUSE, getAlertByCause)
}

function* watchSetAlertByCause() {
  yield takeLatest(SET_ALERTS_BY_CAUSE, setAlertByCause)
}

export default [
  watchGetAlerts(),
  watchGetAlertByCause(),
  watchSetAlertByCause(),
]
