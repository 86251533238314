import React, { Component } from 'react'
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, Card, ButtonToolbar, Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import { TitleTaskCard, InfoTitleTaskCard } from '../../../components/causes';
import Tables from '../../../components/tables';
import { USER_TYPES } from '../../../config/constants/user';
import { CREATE_STAGE_BY_NUMBER } from '../../../sagas/stage/types';
import { GET_TASKS_BY_STAGE, GET_TITLE_TASK_BY_ID, SET_TITLE_TASK, CREATE_AND_FILL_TASK, FILL_TITLE_TASK, GET_TASKS_FOR_INFO_BY_ID } from '../../../sagas/tasks/types';
import { TASK } from '../../../config/constants/states';
import Modal from '../../../components/modal';
import ChangeClient from '../../../components/causes/titles/modal';
import TaskModal from '../../../components/causes/titles/showModal';
import dateHelper from '../../../helpers/date.helper';
import Loading from '../../../components/loading';
import Empty from '../../../components/emptyCard';

function InfoCol(props) {
  const value = props.value || '-';
  const { t } = props;
  return (
    <Col className="col-12 mb-2" md={4}>
      <p className="small">{t(props.label)}</p>
      <p>{value}</p>
    </Col>
  )
}

class Titles extends Component {

  constructor(props){
    super(props)
    this.state= {
      task: {},
      modalShow: false,
      toogleTask: false,
      changeClientModalShow: false,
      taskModalShow: false,
    }
    this.modalClose = this.modalClose.bind(this);
    this.clientModalClose = this.clientModalClose.bind(this);
    this.taskModalClose = this.taskModalClose.bind(this);
  }

  modalClose(){
    this.setState({ modalShow: false });
  }

  clientModalClose() {
    this.setState({ changeClientModalShow: false });
  }

  clientModalShow() {
    this.setState({ changeClientModalShow: true });
  }

  taskModalShow() {
    this.setState({ taskModalShow: true });
  }

  taskModalClose() {
    this.setState({ taskModalShow: false });
  }

  componentWillMount() {
    if (this.props.title.id) {
      this.props.getTasksByStage(this.props.title.id);
      const { tasks } = this.props.title;
      if (tasks.length > 0) {
        this.props.getTask(tasks[tasks.length - 1].id);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.props.getTasksByStage(this.props.title.id);
      const { tasks } = this.props.title;
      if (tasks !== prevProps.title.tasks) {
        if (tasks.length > 0) {
        this.props.getTask(tasks[tasks.length - 1].id);
        }
      }
    }
  }

  handleClickTable(id) {
    this.props.getTask(id);
    this.setState({
      toogleTask: true,
    });
  }

  generateColInfo(attributeValues) {
    if (attributeValues) {
      const { t } =  this.props;
      return attributeValues.map(c => <InfoCol t={t} {...c}/>);
    }
  }

  generateHistoricTable() {
    const { tasks } = this.props.title;
    if (tasks) {
      const { t } = this.props;
      return tasks.map(item => {
        const { id } = item;
        return (
          <tr key={id} id={id}>
            <td>
              <p>{t(item.name)}</p>
              <span>{dateHelper.parseDate(new Date(item.started_at)) || item.started_at}</span>
            </td>
            <td className='float-right'>
              <Button
                onClick={() => {
                  this.props.getTasksForInfoById(item.id)
                  this.taskModalShow()}
                }
                className="nav-link text-center actions"
                variant="link">
                {t("translation:general>show")}
              </Button>
            </td>
          </tr>
        )
      });
    }
  }

  handleTitleChange(item) {
    this.props.setTask(item);
    this.setState({
      task: item,
      toogleTask: false,
    });
  }

  enterTitleButton() {
    const { t } = this.props;
    return (
      <Button
        onClick={() => {
            this.props.createTask(this.props.task,
            {
              stage_check_id: this.props.title.id,
              task_id: this.props.task.id,
              state: TASK.COMPLETE_TITLE,
            });
            this.clientModalShow();
          }}
        className="rounded-0">
        <Loading title={`${t("translation:general>update")} ${t("translation:general>title")}`}/>
      </Button>
    )
  }

  updateTitleButton() {
    const { t } = this.props;
    return (
      <Button
        disabled={(!this.props.task.state)}
        onClick={() => this.props.fillTask(this.props.task)}
        className="rounded-0">
        <Loading title={`${t("translation:general>update")} ${t("translation:general>title")}`}/>
      </Button>
    )
  }

  showCreateSelect(user){
    const type = user.type ? user.type.trim() : '';
    if( type === USER_TYPES.SUPER_ADMIN ){
      return (
          <Dropdown as={ButtonGroup}>
            {(this.props.task.state !== 0) ? this.updateTitleButton() : this.enterTitleButton()}

            <Dropdown.Toggle split className="rounded-0" id="dropdown-split-basic" />

            <Dropdown.Menu>
              {this.props.tasks.map((item) =>
                <Dropdown.Item
                  value={item.id}
                  onClick={() => this.handleTitleChange(item)}>
                    {item.description}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
      );
    }
  }

  userTypeShow(user){
    if (user.type && this.props.task.id) {
      const type = user.type.trim();
      if(type === USER_TYPES.CLIENT || type === USER_TYPES.VISUALIZER){
        return <InfoTitleTaskCard isParent={true} {...this.props.task}/>;
      } else {
        if (this.props.task.state === TASK.COMPLETE_TITLE){
          return (
            <React.Fragment>
              <InfoTitleTaskCard isParent={true} {...this.props.task}/>
            </React.Fragment>
          );
        }
        const { t } = this.props;
        return (
          <React.Fragment>
            <TitleTaskCard isParent={true} {...this.props.task}/>
            <Row className="mb-3 mt-4 line">
              <Col className="col-12">
                <ButtonToolbar className="button float-right pt-3">
                  <Button
                    onClick={() => this.props.fillTask({...this.props.task, state: TASK.COMPLETE_TITLE})}
                    variant="primary"
                    className="mr-4">
                    <Loading title={t("translation:general>save")}/>
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </React.Fragment>
        );
      }
    }
  }

  changeButton(user) {
    if (user.type && this.props.task.id) {
      const type = user.type.trim();
      if(type !== USER_TYPES.CLIENT && type !== USER_TYPES.VISUALIZER) {
        const{ t } = this.props;
        return (
          <ButtonToolbar onClick={() => this.clientModalShow()} className="align-left mt-2">
            <Button className="rounded-0">{`${t("translation:general>register")} ${t("translation:general>title")}`}</Button>
          </ButtonToolbar>
        )
      }
    }
  }

  showTitles() {
    const { task, t } = this.props;
    const attributeValueTitle = { label: 'Concesión', value: task.description };
    return (
      <Row className="mtn-5">
        <Col className="col-xl-4 col-lg-5 col-12">
          <Card>
          <Card.Body className="left-content__title info__general p-4">
          <div className='title'>
            <p className="small">{t(attributeValueTitle.label)}</p>
            <h4>{t(attributeValueTitle.value)}</h4>
          </div>

          <div className='pt-4'>
            <p className="small">{t("translation:general>historical")}</p>
          </div>

          <Tables titles={[]}>
            {this.generateHistoricTable()}
          </Tables>
          </Card.Body>
          </Card>
        </Col>
        <Col className="col-12 col-xl-8 col-lg-7">
          <Card className='info__general right-content'>
            <div className="d-flex bd-highlight info__general align-items-center justify-content-between p-4">
            <div className="title"><span>{t("translation:general>task")}</span>
              <h4>{t(task.name)}</h4>
            </div>
              {this.changeButton(this.props.user)}
            </div>
              {this.userTypeShow(this.props.user)}
          </Card>
        </Col>
      </Row>
    );
  }

  emptyTable() {
    const { t } = this.props;
    return (
      <Col className="pl-2" xs="3">
        <h5>
          {t("translation:emptyTable>cause>titles>titleAdmin")}
        </h5>
        <p className="text-muted">
          {t("translation:emptyTable>cause>titles>subtitleAdmin")}
        </p>
        <Button onClick={() => this.clientModalShow()} className="rounded-0 align-left mt-2">
          {t("translation:emptyTable>cause>titles>button")}
        </Button>
      </Col>
    )
  }

  toogleEmptyTable = () => {
    const { user } = this.props;
    if (user.type) {
      const type = user.type.trim();
      if(type === USER_TYPES.CLIENT || type === USER_TYPES.VISUALIZER) {
        const { t } = this.props;
        return (
          <Col className="pl-2" xs="3">
            <h5>
              {t("translation:emptyTable>cause>titles>title")}
            </h5>
            <p className="text-muted">
              {t("translation:emptyTable>cause>titles>subtitle")}
            </p>
          </Col>
        )
      }
    }
    return this.emptyTable();
  }

  render() {
    return (
      <Container className="pb-5">
        <Modal show={this.state.changeClientModalShow} onHide={this.clientModalClose}>
          <ChangeClient handleChange={this.handleTitleChange} onHide={this.clientModalClose}/>
        </Modal>
        <TaskModal show={this.state.taskModalShow} onHide={this.taskModalClose}/>
        {
          (this.props.title.tasks && this.props.title.tasks.length > 0)
            ? this.showTitles()
        : (
        <Row className="mtn-5">
          <Empty type="empty-card">{this.toogleEmptyTable()}</Empty>
        </Row>
      )
        }
      </Container>
    )
  }
}

const actions = dispatch => ({
  createTitle: (causeId) => dispatch({ type: CREATE_STAGE_BY_NUMBER, payload: { causeId } }),
  getTasksByStage: (stageId) => dispatch({ type: GET_TASKS_BY_STAGE, payload: stageId }),
  getTasksForInfoById: (taskId) => dispatch({ type: GET_TASKS_FOR_INFO_BY_ID, payload: taskId }),
  getTask: (id) => dispatch({type: GET_TITLE_TASK_BY_ID, payload: id}),
  setTask: (task) => dispatch({ type: SET_TITLE_TASK, payload: task }),
  createTask: (task, createTask) => dispatch({type: CREATE_AND_FILL_TASK, payload: {task, createTask}}),
  fillTask: (task) => dispatch({type: FILL_TITLE_TASK, payload: task}),
});

const data = state => ({
  user: state.user.user,
  cause: state.cause.cause,
  title: state.stage.title,
  error: state.error,
  tasks: state.stage.tasks,
  task: state.task.taskTitle,
  status: state.error.status,
});

export default connect(data, actions)(withTranslation()(Titles));
