export const CORE = {
  SERVICE: 'CORE',
  PATH: {
    DOCUMENT: '/b/p/document',
  },
  QUERY: {
    CATASTRO: '?limit=6&filter=Type/Name eq Catastro',
  },
};

export const FILE = {
  SERVICE: 'FILE',
}
