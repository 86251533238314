import {takeLatest, call, put} from 'redux-saga/effects'
import {
  SEARCH_CAUSES,
  SEARCH_CLIENTS,
  SEARCH_SOCIETIES,
} from "./types";
import { FETCH_SEARCH } from "../../stores/search/types";
import { causes } from "../../network/causes";
import { clients } from "../../network/clients";
import { societies } from "../../network/societies";
import filterQueryMaker from '../../helpers/filterQueryMaker.helper';
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

function* searchCauses(action) {
  try {
    const { payload } = action;
    const {data} = yield call(causes, filterQueryMaker(payload));
    yield put({type: FETCH_SEARCH, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* searchClients(action) {
  try {
    const { payload } = action;
    const {data} = yield call(clients, filterQueryMaker(payload));
    yield put({type: FETCH_SEARCH, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* searchSocieties(action) {
  try {
    const { payload } = action;
    const {data} = yield call(societies, filterQueryMaker(payload));
    yield put({type: FETCH_SEARCH, payload: data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* watchSearchCauses() {
  yield takeLatest(SEARCH_CAUSES, searchCauses)
}

function* watchSearchClients() {
  yield takeLatest(SEARCH_CLIENTS, searchClients)
}

function* watchSearchSocieties() {
  yield takeLatest(SEARCH_SOCIETIES, searchSocieties)
}

export default [
  watchSearchCauses(),
  watchSearchClients(),
  watchSearchSocieties(),
]