import React, {Component} from 'react';
import {Col, Form} from 'react-bootstrap';
import { withTranslation } from "react-i18next";

class inputBox extends Component {

  render() {
    const isValid = this.props.errors.find(e => e.id === this.props.id)
      ? false : true;
    const { t } = this.props;
    return (
      <Col xs={12} lg={12} xl={12} className="check">
        <Form.Group controlId={this.props.id}>
        <Form.Label>{t(this.props.label)}</Form.Label>
          <Form.Control
            key={`text-area-${this.props.id}`}
            name={this.props.id}
            onChange={this.props.handleChange}
            as="textarea"
            value={this.props.value}
            {...(isValid) ? { isValid: true } : { isInvalid: true }}
            placeholder={t(this.props.placeholder) || ''}/>
          {isValid ? "" : <Form.Control.Feedback type="invalid">{t(this.props.placeholder) || `${t("translation:form>enter")} ${t(this.props.label)}`}</Form.Control.Feedback>}
        </Form.Group>
      </Col>
    )
  }
}

export default (withTranslation()(inputBox));
