import {
  FETCH_CLIENTS,
  FETCH_CLIENT,
  PUSH_CLIENT,
} from "./types";

const initial = {
  clients: [],
  client: {},
};

export default (state = initial, action) => {
  const {type, payload} = action;
  switch (type) {
    case FETCH_CLIENTS:
      return {...state, clients: payload};
    case FETCH_CLIENT:
      return {...state, client: payload};
    case PUSH_CLIENT:
      return {...state, clients: [...state.clients, payload ] };
    default:
      return state;
  }
}
