import {
  FETCH_CLIENTS_SOCIETIES,
  PUT_CLIENT_SOCIETY,
} from "./types";

const initial = {
  clientsSocieties: [],
  clientSociety: {},
};

export default (state = initial, action) => {
  const {type, payload} = action;
  switch (type) {
    case FETCH_CLIENTS_SOCIETIES:
      return {...state, clientsSocieties: payload};
    case PUT_CLIENT_SOCIETY:
      return {...state, clientsSociety: payload};
    default:
      return state;
  }
}
