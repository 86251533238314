import { put, takeLatest } from 'redux-saga/effects';
import { SET_ERROR } from "./types";
import { FETCH_ERROR } from "../../stores/errors/types";

function* setError(action) {
  const { payload } = action;
  yield put({ type: FETCH_ERROR, payload })
}

function* watchSetError() {
  yield takeLatest(SET_ERROR, setError);
}

export default [
  watchSetError(),
]
