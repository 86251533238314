import React, { Component } from 'react';
import { connect } from "react-redux";
import { UPLOAD_INFO, UPDATE_FILE, UPDATE_BY_URL } from "../../../sagas/file/types";
import ToogleUpload from '../../toogleUpload';

class ModalUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadState: 0,
      uploadMode: 1,
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.handleUploadUrl = this.handleUploadUrl.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    this.props.setUploadFile({
      progress: 0,
      responseState: 'success'
    });
  }

  handleUpload(e) {
    this.props.setUploadFile({progress: 0, responseState: 'success'});
    let file = e.target.files[0];
    this.setState({file: file, progress: 0});
    const state = 1;

    const { id, task_check_id } = this.props.file;
    this.props.updateFile(
      file,
      { id, task_check_id, state },
      this.props.cause.id,
    );
  }

  handleDropUpload = (files) => {
    if (files.length > 0) {
      this.props.setUploadFile({progress: 0, responseState: 'success'});
      let file = files[0];
      this.setState({file: file, progress: 0});
      const state = 1;

      const { id, task_check_id } = this.props.file;
      this.props.updateFile(
        file,
        { id, task_check_id, state },
        this.props.cause.id,
      );
    }
  }

  handleUploadUrl(fileState) {
    this.props.setUploadFile({progress: 10, responseState: 'warning'});
    let file = {
      url: fileState.url,
      name: fileState.name,
      resource_id: this.props.cause.id,
    };
    const state = 1;

    const { id } = this.props.file;
    const taskId = this.props.taskPayment.id;
    this.props.updateUrlFile(
      file,
      { id, task_check_id: taskId, state },
    );
  }

  handleChange(e) {
    this.setState({uploadState: e.target.value});
  }

  render() {
    return (
      <React.Fragment>
        <ToogleUpload
          {...this.state}
          {...this.props}
          handleUpload={this.handleUpload}
          handleDropUpload={this.handleDropUpload}
          handleUploadUrl={this.handleUploadUrl}/>
      </React.Fragment>
    );
  }
}


const data = state => ({
  cause: state.cause.cause,
  uploadState: state.file.uploadFile,
  taskPayment: state.task.taskPayment,
});

const actions = dispatch => ({
  setUploadFile: (file) => dispatch({type: UPLOAD_INFO, payload: file}),
  updateFile: (file, fileInfo, causeId) => dispatch({type: UPDATE_FILE, payload: {file, fileInfo, causeId}}),
  updateUrlFile: (file, fileInfo) => dispatch({type: UPDATE_BY_URL, payload: {file, fileInfo}}),
});

export default connect(data, actions)(ModalUpload);
