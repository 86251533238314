import React, { Component } from 'react'
import { Col, Form, ProgressBar } from 'react-bootstrap'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {UPLOAD} from "../sagas/file/types";
import { UPLOAD_INFO } from "../sagas/file/types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GET_CAUSE_CATASTRO_FILES } from "../sagas/cause/types";
import { GET_TASKS_BY_ID } from '../sagas/tasks/types';
import { DOCUMENT } from '../config/constants/states';
import Dropzone from 'react-dropzone';

class inputBox extends Component {

  render() {
    if (this.props.uploadState.responseState === DOCUMENT.SUCCESS
        && this.props.uploadState.progress === DOCUMENT.PROGRESS.OK) {
      this.props.closeModal()
      this.props.getCauseCatastroFiles(this.props.cause.id, { limit: 5, page: 1 });
    }
    const { t } = this.props;
    return(
      <Form>
        <Col className="col-12 check">
          <Dropzone onDrop={this.props.handleDropUpload} noClick>
            {({getRootProps, getInputProps}) => (
              <section className="container">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Form.Row className="mt-2">
                    <Form.Label htmlFor="select1">{t(this.props.label)}</Form.Label>
                    <Form className="uploader" id="file-upload-form">
                      <input id={`file-upload-${this.props.id}`} type="file" name="file" onChange={(e) => this.props.onChange(e)}/>

                      <Form.Label id="file-drag" htmlFor={`file-upload-${this.props.id}`}><img className="hidden" id="file-image" src="#" alt="Preview"/>
                        <div id="start"></div>
                        <FontAwesomeIcon className="fa-2x mb-4 fac" icon={['fas', 'file-upload']}/>
                        <div>
                          <h5>{t("translation:modal>uploadDocument>title")}</h5>
                          <p>{t("translation:modal>uploadDocument>subtitle")}</p>
                          <div className="hidden" id="response"></div>
                          <div id="messages"></div>
                        </div>
                        <ProgressBar
                          key={`progress-${this.props.id}`}
                          className="progress mt-4"
                          id="file-progress"
                          striped
                          animated
                          now={this.props.uploadState.progress}
                          variant={this.props.uploadState.responseState}/>
                        <div>{(this.props.name !== '') ? this.props.name
                          : (this.state.file !== null) ? this.state.file.name : ''}
                        </div>
                      </Form.Label>
                    </Form>
                  </Form.Row>
                </div>
              </section>
            )}


          </Dropzone>
        </Col>
      </Form>
    )
  }
}

const data = state => ({
  task: state.task.task,
  cause: state.cause.cause,
  uploadState: state.file.uploadFile,
  catastroFiles: state.cause.causeCatastroFiles.data,
});

const actions = dispatch => ({
  uploadFile: (file, coreCatastro, causeId) => dispatch({type: UPLOAD, payload: {file, coreCatastro, causeId}}),
  setUploadFile: (file) => dispatch({type: UPLOAD_INFO, payload: file}),
  getCauseCatastroFiles: (id, pagination) => dispatch({type: GET_CAUSE_CATASTRO_FILES, payload: {id, pagination}}),
  getTask: (taskId) => dispatch({ type: GET_TASKS_BY_ID, payload: taskId }),
})

export default connect(data, actions)(withTranslation()(inputBox))
