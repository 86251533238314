import axios from './config'

export const forgot = async (data) => await axios.post(`/b/public/password/forgot`, data, {
  headers: {
    service: "AUTH"
  }
});

export const reset = async (data) => await axios.post(`/b/public/password/reset`, data, {
  headers: {
    service: "AUTH"
  }
});
