import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class Titles extends Component {

  render() {
    return (
      <Col>
        <Card className={this.props.type}>
          <Row>
            <Col xs="4"></Col>
            <Col className="pr-2" xs="1.5">
              <div className="icon">
                <FontAwesomeIcon className="edit-icon" size="2x" icon={['far', 'edit']} />
              </div>
            </Col>
            {this.props.children}
          </Row>
        </Card>
      </Col>
    )
  }
}