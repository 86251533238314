import React, { Component } from 'react'
import { Col, Form, Button, Spinner, Badge } from 'react-bootstrap'
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {UPLOAD} from "../sagas/file/types";
import { UPLOAD_INFO } from "../sagas/file/types";
import { GET_CAUSE_CATASTRO_FILES } from "../sagas/cause/types";
import { GET_TASKS_BY_ID } from '../sagas/tasks/types';
import { DOCUMENT } from '../config/constants/states';

class inputBox extends Component {

  state = {
    name: "",
    url: "",
    errors: [false, false],
  }

  validation() {
    let valid = true;
    this.state.errors.forEach(err => {
      valid = valid & err;
    })
    return valid;
  }

  validate() {
    const errors = this.state.errors;
    if (this.state.name !== "") errors[1] = true;
    else errors[1] = false;
    if (this.state.url !== "") errors[0] = true;
    else errors[0] = false;
    this.setState({ errors });
  }

  render() {
    if (this.props.uploadState.responseState === DOCUMENT.SUCCESS
      && this.props.uploadState.progress === DOCUMENT.PROGRESS.OK) {
      this.props.closeModal()
      this.props.getCauseCatastroFiles(this.props.cause.id, { limit: 5, page: 1 });
    }
    const { t } = this.props;
    return(
      <React.Fragment>
      <Form onSubmit={(e) => {
        e.preventDefault();
        this.validate();
        if (this.validation()) {
          this.props.onChange(this.state);
        }
        }}>
        <Col className="col-12 check mb-4 mt-4">
          <Form.Label htmlFor='upload-url'>{t("translation:form>fileUrl")}</Form.Label>
          <Form.Control
            key='text-upload-url'
            name='upload-url'
            onChange={(e) => {
              this.validate();
              this.setState({url: e.target.value})}
            }
            type="url"
            required
            placeholder={`${t("translation:form>enter")} ${t("translation:form>fileUrl").toLowerCase()}`}/>
        </Col>


        <Col className="col-12 check mb-4 mt-4">
          <Form.Label htmlFor='upload-name'>{t("translation:form>fileName")}</Form.Label>
          <Form.Control
            key='text-upload-name'
            name='upload-name'
            onChange={(e) => {
              this.validate();
              this.setState({name: e.target.value})}
            }
            type="text"
            required
            placeholder={`${t("translation:form>enter")} ${t("translation:form>fileName").toLowerCase()} (${t("translation:form>withExtension")}). ${t("translation:form>fileExample")}`}/>
        </Col>
        {(this.props.uploadState.responseState === DOCUMENT.ERROR)
          ? (
          <Col className='mb-3'>
            <Badge className={`${this.props.uploadState.responseState}-custom rounded-0`} variant={this.props.uploadState.responseState}>
              {t("translation:modal>uploadDocument>error")}
            </Badge>
          </Col>
          )
          : null
        }
        <Col>
          <Button
            variant="primary rounded-0 float-right"
            type="submit"
            // onClick={() => {
            //   this.validate();
            //   if (this.validation()) {
            //     this.props.onChange(this.state);
            //   }
          >
            {(this.props.uploadState.responseState === DOCUMENT.SENDING)
              ? (<><Spinner animation='border' size='sm' variant='light'/>{t("translation:general>loading")}</>)
              : t("translation:general>uploadFile")}
          </Button>
        </Col>
      </Form>
      </React.Fragment>
    )
  }
}

const data = state => ({
  task: state.task.task,
  cause: state.cause.cause,
  uploadState: state.file.uploadFile,
  catastroFiles: state.cause.causeCatastroFiles.data,
});

const actions = dispatch => ({
  uploadFile: (file, fileInfo) => dispatch({type: UPLOAD, payload: {file, fileInfo}}),
  setUploadFile: (file) => dispatch({type: UPLOAD_INFO, payload: file}),
  getCauseCatastroFiles: (id, pagination) => dispatch({type: GET_CAUSE_CATASTRO_FILES, payload: {id, pagination}}),
  getTask: (taskId) => dispatch({ type: GET_TASKS_BY_ID, payload: taskId }),
})


export default connect(data, actions)(withTranslation()(inputBox))
