import { put, takeLatest, call } from 'redux-saga/effects';
import { FORGOTTEN_PASSWORD, RESET_PASSWORD } from "./types";
import { FETCH_ERROR_STATUS } from "../../stores/errors/types";
import { forgot, reset } from "../../network/password";
import { STATES } from '../../config/constants/states';
import { USER } from '../../config/constants/resources';

function* sendForgotenPassword(action) {
  const { payload } = action;
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      hasError: false,
      status: STATES.STATUS.WAITING,
      resource: USER.PASSWORD,
    }});
    yield call(forgot, payload);
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: STATES.STATUS.SUCCESS,
      resource: USER.PASSWORD,
    }});
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: e.response.status,
      resource: USER.PASSWORD,
      message: e.response.body,
      hasError: true,
    }});
  }
}

function* sendResetPassword(action) {
  const { payload } = action;
  try {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      hasError: false,
      status: STATES.STATUS.WAITING,
      resource: USER.PASSWORD,
    }});
    yield call(reset, payload)
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: STATES.STATUS.SUCCESS,
      resource: USER.PASSWORD,
    }});
  } catch(e) {
    yield put({ type: FETCH_ERROR_STATUS, payload: {
      status: e.response.status,
      resource: USER.PASSWORD,
      message: e.response.body,
      hasError: true,
    }});
  }
}

function* watchSendForgotenPassword() {
  yield takeLatest(FORGOTTEN_PASSWORD, sendForgotenPassword);
}

function* watchSendResetPassword() {
  yield takeLatest(RESET_PASSWORD, sendResetPassword);
}

export default [
  watchSendForgotenPassword(),
  watchSendResetPassword(),
]
