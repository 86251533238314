import topNavigator from './topNavigator';
import greatingBar from './greatingbar';
import tables from './tables';
import avatar from './avatar';
import modal from './modal'

export const TopNavigator = topNavigator;
export const GreatingBar = greatingBar;
export const Tables = tables;
export const Avatar = avatar;
export const Modal = modal;

