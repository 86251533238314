import React, {Component} from 'react';
import {Col, Form} from 'react-bootstrap';
import { SIZE_COLUMN } from '../config/constants/various';
import { withTranslation } from "react-i18next";
class inputBox extends Component {

  render() {
    const isValid = this.props.errors.find(e => e.id === this.props.id)
      ? false : true;
    const sizeColumn = this.props.sizeColumn || SIZE_COLUMN;
    const { t } = this.props;
    return (
      <Col className="col-12 check mb-4" sm={sizeColumn}>
        <Form.Label htmlFor={this.props.id}>{t(this.props.label)}</Form.Label>
        <Form.Control
          key={`text-${this.props.id}`}
          name={this.props.id}
          onChange={(e) => this.props.handleChange(e)}
          id={this.props.id}
          type="text"
          value={this.props.value}
          {...(isValid) ? { isValid: true } : { isInvalid: true }}
          placeholder={t(this.props.placeholder) || `${t("translation:form>enter")} ${t(this.props.label)}`}/>
        {isValid ? "" :
          <Form.Control.Feedback type="invalid">
            {this.props.errors.find(e => e.id === this.props.id).message
              || t(this.props.placeholder)
              || `${t("translation:form>enter")} ${t(this.props.label)}`}
          </Form.Control.Feedback>}
      </Col>
    )
  }
}

export default ((withTranslation()(inputBox)))
