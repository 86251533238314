export const SEPARATOR = {
  EQUAL: 'eq',
  AND: 'and',
  SLASH: '/',
};

export const QUERY_BASE = '?filter=';
export const CLIENT_ID = '&clientId=';
export const ORDER = '&order='

export const USERS = {
  TYPE_NAME: 'Type/Name',
}

export const CAUSES = {
  DIAGRAM_NAME: 'Diagram/Name',
  PROJECT_ID: 'Project/Id',
}
