import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {BrowserRouter as Router} from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
  faSearch,
  faCheckCircle,
  faChevronUp,
  faChevronDown,
  faArrowRight,
  faFileUpload,
} from '@fortawesome/free-solid-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import store from './store'
import Routes from "./routes";
import { TopNavigator } from "./components";
import "./css/app.css"
import "./i18next";

library.add(
  faSearch,
  faCheckCircle,
  faChevronUp,
  faFileUpload,
  faChevronDown,
  faArrowRight,
  fas,
  far
);

const Application = () => (
  <Suspense fallback="loading">
    <Provider store={store}>
      <Router>
        <TopNavigator src="http://placehold.it/80x80"/>
        <Routes/>
      </Router>
  </Provider>

  </Suspense>
);

ReactDOM.render(<Application/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
