import {takeLatest, call, put} from 'redux-saga/effects'
import {GET_SOCIETIES, GET_SOCIETY, CREATE_SOCIETY} from "./types";
import {FETCH_SOCIETIES,PUSH_SOCIETY, FETCH_SOCIETY} from "../../stores/society/types";
import {societies, society, create_society} from "../../network/societies";
import { LOGIN_URL } from '../../config/constants/routes';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

function* getSocieties() {
  try {
    const {data} = yield call(societies);
    yield put({type: FETCH_SOCIETIES, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* getSociety(action) {
  try {
    const {payload} = action;
    const {data} = yield call(society,payload);
    yield put({type: FETCH_SOCIETY, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e)
  }
}

function* createSociety(action) {
  const {payload} = action;
  try {
    const {data} = yield call(create_society, payload);
    yield put({type: PUSH_SOCIETY, payload: data.data})
  } catch (e) {
    if (e.request.status === 401) {
      cookies.remove('uuid_session');
      window.location.replace(LOGIN_URL);
    }
    console.log(e);
  }
}


function* watchGetSociety() {
  yield takeLatest(GET_SOCIETIES, getSocieties)
}

function* watchGetSocieties() {
  yield takeLatest(GET_SOCIETY, getSociety)
}

function* watchCreateSociety() {
  yield takeLatest(CREATE_SOCIETY, createSociety)
}

export default [
  watchGetSocieties(),
  watchGetSociety(),
  watchCreateSociety()
]


/**
 * {
  "id": "4d29f30f-c6e3-4e32-96d9-785f1af221a4",
  "diagram_id": "2c64b38b-d239-4255-95b2-ead4d5fb12de",
  "society_client_id": "d6156ef2-ee37-4cc9-acc0-70a6aa95d09b",
  "court_id": "166f996a-d178-4732-a8ca-8a8b337168fc",
  "updatedAt": "2019-06-18T19:30:07.704Z",
  "createdAt": "2019-06-18T19:30:07.704Z",
  "deleted_at": null
}
 **/
