import { FETCH_SEARCH } from "./types";

const initial = {
  search: {},
};

export default (state = initial, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_SEARCH:
      return {...state, search: payload};
    default:
      return state;
  }
}
