import React, {Component} from 'react';
import {connect} from "react-redux";
import { withTranslation } from "react-i18next";
import {Col, Row, Form, Button} from "react-bootstrap";
import { GET_PROJECT_DIAGRAMS } from "../../sagas/diagram/types";
import { GET_CLIENTS, GET_CLIENT } from "../../sagas/client/types";
import { GET_SOCIETIES } from "../../sagas/society/types";
import { CREATE_PROJECT } from "../../sagas/project/types";
import { NAME } from "../../config/constants/formAttributes"
import {
  NO_CLIENT,
  NO_SOCIETY,
} from "../../config/constants/user"

const attributes = [
  NAME,
]


class CreateProject extends Component {
  constructor(props){
    super(props);
    this.state = {
      clients: [],
      societies: [],
    };
  }
  componentWillMount(){
    this.props.getClients();
    this.props.getProjectDiagrams();
  }

  componentDidUpdate(prevProps){
    if(prevProps.client !== this.props.client && this.props.client ){
      this.setState({client: this.props.client, societies: this.props.client.societies});
    }
  }

  handleChangeClient(e) {
    this.handleChange(e);
    this.setState({client: {}, societies: this.props.societies});
    if(e.target.value !== NO_CLIENT) this.props.getClient(e.target.value);
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    const obj = Object.fromEntries(data);
    if(obj.society_id === NO_SOCIETY) delete obj.society_id;
    this.props.createProject(obj);
    this.props.closeModal();
  }

  render() {
    const clients = this.props.clients || [];
    const projectDiagrams = this.props.projectDiagrams || [];
    const societies = this.state.societies || [];
    const { t } = this.props;
    return (
      <React.Fragment>
        <form onSubmit={e => this.handleSubmit(e)}>
        <Row className="pt-3 pb-4">
        <Col className="col-12" xl={5}>
            <div className="title p-2">
              <h4>{t("translation:modal>projects>title")}</h4>
              <p>{t("translation:modal>projects>subtitle")}</p>
            </div>
        </Col>
        <Col className="col-12" xl={7}>
          <Form.Group>
            <Form.Label>{t("translation:form>selectProjectType")}</Form.Label>
            <Form.Control
              as="select"
              className="text-capitalize"
              name="project_diagram_id"
              required
              onChange={e => this.handleChange(e)}>
              <option value='' >{t("translation:form>selectProjectType")}</option>
                {projectDiagrams.map((el, i) =>
                  <option key={i} value={el.id}>{t(el.name)}</option>
                )}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t("translation:form>selectClient")}</Form.Label>
            <Form.Control
              as="select"
              className="text-capitalize"
              name="client_id"
              required
              onChange={e => this.handleChangeClient(e)}>
              <option value='' >{t("translation:form>selectClient")}</option>
                {clients.map((el, i) =>
                  <option key={i} value={el.id}>{el.name}</option>
                )}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>{t("translation:form>selectSociety")}</Form.Label>
            <Form.Control
              as="select"
              className="text-capitalize"
              name="society_id"
              required
              onChange={e => this.handleChange(e)}>
              <option key={0} value={NO_SOCIETY}>{t("translation:form>selectSociety")}</option>
                {societies.map((el, i) =>
                  <option key={i} value={el.id}>{el.name}</option>
                )}
            </Form.Control>
          </Form.Group>
          {attributes.map(item => {
            return <Form.Group key={item.id}>
                <Form.Label htmlFor={item.id}>{t(`translation:form>${item.label}`)}</Form.Label>
                <Form.Control onChange={e => this.handleChange(e)} className="rounded-0" {...item} placeholder={`${t("translation:form>enter")} ${t(`translation:form>${item.label}`).toLowerCase()}`}  required/>
              </Form.Group>
          })}
          <Button variant="btn btn-primary rounded-0 float-right" type="submit">{t("translation:general>create")}</Button>
        </Col>
      </Row>
        </form>
      </React.Fragment>
    );
  }
}
const data = state => ({
  projectDiagrams: state.diagram.projectDiagrams,
  types: state.user.types,
  clients: state.client.clients,
  societies: state.society.societies,
  clientsSocieties: state.clientSociety.clientsSocieties,
  client: state.client.client,
});

const actions = dispatch => ({
  getClients: () => dispatch({type: GET_CLIENTS}),
  getClient: (id) => dispatch({type: GET_CLIENT, payload: id}),
  getSocieties: () => dispatch({type: GET_SOCIETIES}),
  getProjectDiagrams: () => dispatch({type: GET_PROJECT_DIAGRAMS}),
  createProject: (obj) => dispatch({type: CREATE_PROJECT, payload: obj}),
});

export default connect(data, actions)(withTranslation()(CreateProject));
