import React, {Component} from 'react'
import {Col, Form, FormCheck} from 'react-bootstrap'
import { SIZE_COLUMN } from '../../config/constants/various';
import { withTranslation } from "react-i18next";

class inputBox extends Component {
  constructor(props) {
    super(props);
  }

  generateOptions() {
    const { t } = this.props;
    const element = this.props.nextTask.map(item => {
      if (this.props.state === 1) {
        return (
          <option
            key={item.id}
            value={item.id}
            disabled
            >{t(item.name)}</option>
        )
      }
      return (
        <option
          key={item.id}
          value={item.id}
          >{t(item.name)}</option>
      )
    })
    return element;
  }

  render() {
    const isValid = this.props.errors.find(e => e.id === this.props.id)
      ? false : true;
    const sizeColumn = this.props.sizeColumn || SIZE_COLUMN;
    const { t } = this.props;
    return (
      <Col className="col-12 check mb-4" sm={sizeColumn}>
        <Form.Group>
          <Form.Label htmlFor="select1">{t(this.props.label)}</Form.Label>
          <Form.Control as="select"
                        id={this.props.id}
                        name={this.props.id}
                        onChange={this.props.handleChange}
                        {...(isValid) ? { isValid: true } : { isInvalid: true }}
                        value={this.props.value}>
            <option disabled={(this.props.value !== "") ? true : false} selected>{t(this.props.placeholder)}</option>
            {this.generateOptions()}
          </Form.Control>
          {isValid ? "" : <Form.Control.Feedback type="invalid">{t(this.props.placeholder)}</Form.Control.Feedback>}
        </Form.Group>
      </Col>
    )
  }
}

export default (withTranslation()(inputBox));
