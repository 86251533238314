export const TITLES = {
  SOCIETY: 'Sociedad',
  SOCIETIES: 'Sociedades',
  CLIENT: 'Cliente',
  CLIENTS: 'Clientes',
  PROJECTS: 'Proyectos',
  PROJECT: 'Proyecto',
  CAUSE: 'Caso',
  CAUSES: 'Casos',
  PROFILE: 'Perfil',
  ASSOCIATED_TO: 'asociado a',
  REPRESENTATIVE: 'representante',
  REPRESENTATIVES: 'Representantes',
  CONCESSION: 'Concesión',
  CONCESSIONS: 'Concesiones',
  USERS: 'Usuarios',
  USER: 'Usuario',
  CREATE: 'Crear',
  ADD: 'Añadir',
  TASK: 'Tarea',
  INFORMATION: 'Información',
  DOCUMENTS: 'Documentos',
  HISTORIC: 'Historial',
  CHANGE: 'Cambiar',
  CONCESSION: 'Concesión',
}

export const BUTTONS = {
  VIEW: 'Ver',
  DELETE: 'Borrar',
  ADD: 'Añadir',
  EDIT: 'Editar',
  UPDATE: 'Cargar',
}
