import React from 'react';
import { Badge } from 'react-bootstrap';
import { DOCUMENT } from '../../../config/constants/states';
import { withTranslation } from "react-i18next";

const { CATASTRO } = DOCUMENT;

function BadgeState(props) {
  const { state, t } = props;
  const mb = props.mb || "0";
  switch (state) {
    case CATASTRO.ABARCAMIENTO.NUMBER:
      return (
      <Badge className={`danger-custom rounded-0 mb-${mb}`}>
        {t("translation:states>surveillance>outreach").toUpperCase()}
      </Badge>
      )
    case CATASTRO.OPOSICION.NUMBER:
      return (
      <Badge className={`danger-custom rounded-0 mb-${mb}`}>
        {t("translation:states>surveillance>opposition").toUpperCase()}
      </Badge>
      )
    case CATASTRO.SUPERPOSICION.NUMBER:
      return (
      <Badge className={`danger-custom rounded-0 mb-${mb}`}>
        {t("translation:states>surveillance>overlap").toUpperCase()}
      </Badge>
      )
    case CATASTRO.ENLARGMENT.NUMBER:
      const enlargmentName = t("translation:states>surveillance>enlargment").split(' ').shift();
      return (
      <Badge className={`danger-custom rounded-0 mb-${mb}`}>
        {t(enlargmentName).toUpperCase()}
      </Badge>
      )
    case CATASTRO.REDUCTION.NUMBER:
      const reductionName = t("translation:states>surveillance>reduction").split(' ').shift();
      return (
      <Badge className={`danger-custom rounded-0 mb-${mb}`}>
        {reductionName.toUpperCase()}
      </Badge>
      )
    case CATASTRO.UPLOADED.NUMBER:
      return (
      <Badge className={`success-custom rounded-0 mb-${mb}`}>
        {t("translation:states>surveillance>uploaded").toUpperCase()}
      </Badge>
      )
    case CATASTRO.UPDATE.NUMBER:
      return (
      <Badge className={`success-custom rounded-0 mb-${mb}`}>
        {t("translation:states>surveillance>update").toUpperCase()}
      </Badge>
      )
    default:
      return (
      <Badge className={`warning-custom rounded-0 mb-${mb}`}>
        {t("translation:states>surveillance>empty").toUpperCase()}
      </Badge>
      )
  }
}


export default (withTranslation()(BadgeState));
